import { sortByName } from "./../../../shared/utility";
import { combineReducers } from "redux";
import overviewReducer from "./../overview/ducks/reducer";
import scenesReducer from "./../scenes/ducks/reducer";
import mapPictures from "./../../../shared/image-mapper";
import { CHANGE_CHARACTER_TO_BACKGROUND_CHARACTER, FETCH_CHARACTER_RATE } from "./actions";

interface CharacterState {
  characters: any[];
  loading: boolean;
  character: any;
  readOnly: boolean;
  loadingRate: boolean;
  rate: number | null;
}

const defaultState: CharacterState = {
  characters: [],
  loading: true,
  readOnly: false,
  character: null,
  loadingRate: true,
  rate: null
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_CHARACTERS_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case "FETCH_CHARACTERS_FULFILLED": {
      return {
        ...state,
        characters: action.payload.data.characters,
        readOnly: action.payload.data.readOnly,
        loading: false
      };
    }

    case "FETCH_CHARACTERS_REJECTED": {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }

    case "ALIAS_CHARCTER": {
      const { aliasCharacter } = action.meta;
      const characters = Array.from(state.characters);
      const aliasIndex = characters.findIndex(s => s.id === aliasCharacter.id);
      characters.splice(aliasIndex, 1);

      return {
        ...state,
        characters: characters
      };
    }

    case "UNALIAS_CHARCTER": {
      const { aliasCharacter } = action.meta;
      const characters = Array.from(state.characters);
      characters.push(aliasCharacter);
      sortByName(characters);

      return {
        ...state,
        characters: characters
      };
    }

    case FETCH_CHARACTER_RATE + "_PENDING": {
      return {
        ...state,
        loadingRate: true
      };
    }

    case FETCH_CHARACTER_RATE + "_FULFILLED": {
      const rate = action.payload.data.rate;
      //const character = { ...state.character, dailyBudget: rate };
      return {
        ...state,
        rate,
        loadingRate: false
      };
    }

    case FETCH_CHARACTER_RATE + "_REJECTED": {
      return {
        ...state,
        loadingRate: false
      };
    }

    case "INITIALISE_CHARACTER": {
      return {
        ...state,
        character: { ageRange: { min: 0, max: 100 } },
        loading: false
      };
    }

    case "CHARACTER_SET_NAME": {
      const character = { ...state.character, name: action.meta.name, nameInScript: action.meta.name }
      return {
        ...state,
        character: character,
        errors: null
      };
    }

    case "CHARACTER_GET_GENDER_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "CHARACTER_GET_GENDER_FULFILLED": {
      return {
        ...state,
        loading: false,
        character: { ...state.character, gender: action.payload?.data?.gender }
      };
    }

    case "CHARACTER_GET_GENDER_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "CLEAR_CHARACTER": {
      return {
        ...state,
        character: null,
        errors: null,
        loadingCharacter: false
      };
    }

    case "FETCH_SINGLE_CHARACTER_PENDING": {
      return {
        ...state,
        character: null,
        loadingCharacter: true
      };
    }

    case "FETCH_SINGLE_CHARACTER_FULFILLED": {
      let character = action.payload.data;
      character.originalActorId = character.actorId;

      mapPictures(character);

      return {
        ...state,
        character,
        loadingCharacter: false,
        errors: null
      };
    }

    case "FETCH_SINGLE_CHARACTER_REJECTED": {
      return {
        ...state,
        character: action.payload.data,
        loadingCharacter: false
      };
    }

    case "UPDATE_SINGLE_CHARACTER_PENDING": {
      return {
        ...state,
        loadingCharacter: true,
        errors: null
      };
    }

    case "UPDATE_SINGLE_CHARACTER_FULFILLED": {
      const character = {
        ...action.meta.character,
        ...action.payload.data.character
      };
      const characters: any = state.characters.map((c: any, index: number) => {
        if (c.id === character.id) {
          return { ...c, ...character };
        } else {
          return c;
        }
      });

      sortByName(characters);

      let redirect;
      let loadingCharacter;
      if (action.meta.redirect) {
        loadingCharacter = false;
        redirect = { to: "list" };
      } else {
        loadingCharacter = true;
        redirect = null;
      }
      return {
        ...state,
        character,
        characters,
        loadingCharacter: loadingCharacter,
        errors: null,
        redirect: redirect
      };
    }

    case "SINGLE_CHARACTER_REDIRECT_TO_LIST": {
      return {
        ...state,
        redirect: { to: "list" }
      };
    }

    case "UPDATE_SINGLE_CHARACTER_ASSIGN_ACTOR_REJECTED":
    case "UPDATE_SINGLE_CHARACTER_UNASSIGN_ACTOR_REJECTED":
    case "UPDATE_SINGLE_CHARACTER_REJECTED": {
      return {
        ...state,
        character: action.meta.character,
        errors: action.payload.response.data.errors,
        loadingCharacter: false
      };
    }

    case "ADD_SINGLE_CHARACTER_PENDING": {
      return {
        ...state,
        errors: null,
        loadingCharacter: true
      };
    }

    case "ADD_SINGLE_CHARACTER_FULFILLED": {
      const character: any = action.payload.data.character;

      let characters = [character, ...state.characters];

      sortByName(characters);

      return {
        ...state,
        character,
        characters,
        loadingCharacter: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "ADD_SINGLE_CHARACTER_REJECTED": {
      return {
        ...state,
        character: action.meta.character,
        errors: action.payload.response.data.errors,
        loadingCharacter: false
      };
    }

    case "DELETE_SINGLE_CHARACTER_PENDING": {
      return {
        ...state,
        character: action.payload,
        loadingCharacter: true,
        errors: null
      };
    }

    case "DELETE_SINGLE_CHARACTER_FULFILLED": {
      const character = action.meta.character;
      const characters: any = state.characters.filter(
        (c: any, index: number) => {
          return c.id !== character.id;
        }
      );

      return {
        ...state,
        character: null,
        characters,
        loadingCharacter: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "DELETE_SINGLE_CHARACTER_REJECTED": {
      return {
        ...state,
        character: action.meta.character,
        errors: action.payload.response.data.errors,
        loadingCharacter: false
      };
    }

    case CHANGE_CHARACTER_TO_BACKGROUND_CHARACTER + "_PENDING": {
      return {
        ...state,
        loadingProp: true,
        errors: null
      };
    }

    case CHANGE_CHARACTER_TO_BACKGROUND_CHARACTER + "_FULFILLED": {
      const characters = Array.from(state.characters);
      var index = characters.findIndex(s => s.id === action.meta.character.id);
      if (index >= 0) {
        characters.splice(index);
      }

      return {
        ...state,
        characters,
        errors: null,
        redirect: { to: "background-characters", id: action.payload.data.backgroundCharacterId }
      };
    }

    case CHANGE_CHARACTER_TO_BACKGROUND_CHARACTER + "_REJECTED": {
      return {
        ...state,
        character: action.meta.character,
        errors: action.payload.response.data.errors,
        loadingProp: false
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        character: null,
        redirect: null
      };
    }

    case "UPLOAD_IMAGES_PENDING": {
      return {
        ...state,
        loadingCharacter: true
      };
    }

    case "UPLOAD_IMAGES_REJECTED": {
      return {
        ...state,
        loadingCharacter: false,
        errors: action.payload.response.data.errors,
      };
    }

    case "UPLOAD_IMAGES_FULFILLED": {
      return {
        ...state,
        loadingCharacter: false
      };
    }

    default:
      return state;
  }
};

export default combineReducers({
  rootState: reducer,
  overviewState: overviewReducer,
  scenesState: scenesReducer
});
