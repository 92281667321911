import OnboardingType from "../../../../shared/onboarding/onboarding-type";

interface ActionState {
  actors: any[];
  loading: boolean;
  readOnly: boolean;
  onboardingSteps: OnboardingType[];
  errors: any;
  pristine: boolean;
}

export const OnboardingStep1ClassName = 'actor-order-onboarding-step-1';
export const OnboardingStep2ClassName = 'actor-order-onboarding-step-2';

const defaultState: ActionState = {
  actors: [],
  loading: true,
  readOnly: false,
  errors: null,
  pristine: true,
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Order',
      content: `        
        <p>Drag and drop the actors to change their number.</p>
        <p>This is the relative importance of the Actor and, usually, the principle Actor will have a number of one.</p>
        <p>It is also the number and order they will appear on the Call Sheet.</p>
      `
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_ACTOR_ORDER_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "FETCH_ACTOR_ORDER_FULFILLED": {
      const sortedActors = action.payload.data.actors;
      sortByNumber(sortedActors);
      return {
        ...state,
        loading: false,
        errors: null,
        actors: sortedActors,
        readOnly: action.payload.data.readOnly
      };
    }

    case "FETCH_ACTOR_ORDER_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.errors
      };
    }

    case "UPDATE_ACTOR_ORDER": {
      const actors = reorderActors(state.actors, action.meta.sourceIndex, action.meta.destinationIndex);
      return {
        ...state,
        actors,
        pristine: false
      };
    }

    case "SAVE_ACTOR_ORDER_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }
    
    case "SAVE_ACTOR_ORDER_FULFILLED": {      
      return {
        ...state,
        loading: false,
        errors: null,
        pristine: true
      };
    }

    case "SAVE_ACTOR_ORDER_REJECTED": {      
      return {
        ...state,
        loading: false,
        errors: action.payload.response.errors
      };
    }

    default:
      return state;
  }
};

const reorderActors = (list, sourceIndex, destinationIndex) => {
  const result: any[] = Array.from(list);
  result[sourceIndex].number = destinationIndex + 1;

  if (sourceIndex > destinationIndex) {
    for (let i = destinationIndex; i < sourceIndex; i++) {
      result[i].number++;
    }
  }

  if (sourceIndex < destinationIndex) {
    for (let i = sourceIndex + 1; i <= destinationIndex; i++) {
      result[i].number--;
    }
  }

  const [removed] = result.splice(sourceIndex, 1);
  result.splice(destinationIndex, 0, removed);

  return result;
};

export function sortByNumber(list) {
  list.sort((a, b) => {
    return a.number - b.number;
  });
}

export default reducer;
