import React from "react";
import { Prompt } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import Errors from "./../../../shared/errors";
import {
  renderSingleReactSelect,
  renderTextField
} from "./../../../shared/form-helpers";
import required, { maxDecimalValue } from "./../../../shared/validation";

function CharacterForm(props) {
  const {
    handleSubmit,
    handleDelete,
    pristine,
    reset,
    submitting,
    errors,
    mode,
    backgroundCharacters
  } = props;
  const thisCharacter = props.initialValues || {};
  const readOnly = thisCharacter.readOnly;

  const backgroundCharacterOptions = backgroundCharacters?.map((e) => {
    return { label: e.name, value: e.id };
  });

  return (
    <>
      <Prompt when={!pristine} message="" />
      {mode === "add" && <h2 className="menu-label">Add background character to scene</h2>}
      {mode === "edit" && (
        <h2 className="menu-label">Edit background character in scene</h2>
      )}
      {errors && <Errors errors={errors} />}
      <form autoComplete="off" onSubmit={handleSubmit}>
        {mode === "add" && (
          <Field
            name="characterId"
            label="Background Character"
            component={renderSingleReactSelect}
            validate={[required]}
            options={backgroundCharacterOptions}
            required
            readOnly={readOnly}>
          </Field>
        )}
        {mode === "edit" && (
          <div className="field">
            <label className="label">
              Background Character
            </label>
            <div className="control">
              {thisCharacter.backgroundCharacterName}
            </div>
          </div>
        )}

        <div className="field">
          <Field
            name="quantity"
            label="Quantity"
            type="number"
            parse={(value) => Number(value)}
            component={renderTextField}
            validate={[required, maxDecimalValue]}
            required
            readOnly={readOnly}
          />
        </div>

        {!readOnly && <div className="buttons">
          <button
            type="submit"
            className="button is-primary"
            disabled={pristine || submitting}>
            Save
          </button>

          {mode === "edit" && (
            <button
              type="button"
              className="button is-text"
              disabled={submitting || !props.initialValues}
              onClick={() => handleDelete(thisCharacter)}>
              Remove from scene
            </button>
          )}
        </div>}
      </form>
    </>
  );
}

export default reduxForm({
  form: "CharacterForm",
  enableReinitialize: true
})(CharacterForm);
