import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import confirm from "../../../shared/modal/confirm";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import Loader from "./../../../shared/loader";
import {
  clearRedirect,
  deleteProp,
  fetchProp,
  updateProp,
  uploadImagesAndSaveProp,
  changePropToSetDressing,
  changePropToCostume
} from "./ducks/actions";
import Form from "./form";

function EditProp(props) {
  const filmId = props.filmId || props.match.params.filmId;
  const propId = props.propId || props.match.params.propId;

  const {
    fetchProp,
    updateProp,
    changePropToSetDressing,
    uploadImagesAndSaveProp,
    changePropToCostume,
    deleteProp,
    clearRedirect,
    loadingProp,
    prop,
    errors,
    embedded
  } = props;

  const invalidIds = ["add", "overview"];

  useEffect(() => {
    if (invalidIds.indexOf(propId) === -1) {
      fetchProp(filmId, propId);
    }
  }, [filmId, propId]);

  if (!embedded && props.redirect) {
    if (props.redirect.to === "list") {
      clearRedirect();
      const url = `/films/${filmId}/props/`;
      return <Redirect to={url} />;
    } else if (props.redirect.to === "set-dressing") {
      props.clearRedirect();
      const url = `/films/${filmId}/set-dressings/${props.redirect.id}/edit`;
      return <Redirect to={url} />;
    } else if (props.redirect.to === "costume") {
      props.clearRedirect();
      const url = `/films/${filmId}/costumes/${props.redirect.id}/edit`;
      return <Redirect to={url} />;
    }
  }

  if (invalidIds.indexOf(propId) > -1) {
    return null;
  }

  function renderLoading() {
    return <Loader />;
  }

  function renderProp(prop) {
    return (
      <ScrollIntoView
        className="blade-content widest"
        path="/films/:filmId/props/:propId/edit"
        loading={loadingProp}>
        <Form
          errors={errors}
          mode="edit"
          filmId={filmId}
          propId={prop.id}
          initialValues={prop}
          loading={loadingProp}
          onSubmit={onUpdateProp}
          handleDelete={onDeleteProp}
          onChangePropToSetDressing={onChangePropToSetDressing}
          onChangePropToCostume={onChangePropToCostume}
          reduxFormValues={props.reduxFormValues}
          acquisitionMethodEnum={props.acquisitionMethodEnum}
          {...props}
        />
      </ScrollIntoView>
    );
  }

  function onUpdateProp(prop) {
    const files = prop.pictures || [];

    if ((files && files.length) > 0 || (prop.images && prop.images.length > 0)) {
      uploadImagesAndSaveProp(prop, files, false, filmId);
    } else {
      updateProp(filmId, prop);
    }
  }

  function onDeleteProp(prop) {
    confirm(() => deleteProp(filmId, prop), prop?.name);
  }

  const onChangePropToSetDressing = (prop) => {
    confirm(() => changePropToSetDressing(filmId, prop), null, `Are you sure you want to change ${prop.name} into a Set Dressing?`, "Change Prop");
  };

  const onChangePropToCostume = (prop) => {
    confirm(() => changePropToCostume(filmId, prop), null, `Are you sure you want to change ${prop.name} into a Costume?`, "Change Prop");
  };

  return prop && !loadingProp ? renderProp(prop) : renderLoading();
}

function mapStateToProps(state) {
  const { propsState, commonState } = state;
  return {
    prop: propsState.prop,
    errors: propsState.errors,
    redirect: propsState.redirect,
    acquisitionMethodEnum: commonState.acquisitionMethod
  };
}

export default connect(mapStateToProps, {
  fetchProp,
  updateProp,
  deleteProp,
  clearRedirect,
  uploadImagesAndSaveProp,
  changePropToSetDressing,
  changePropToCostume
})(EditProp);
