import { Link } from "react-router-dom";
import CkEditorContent from "../../../shared/controls/ckeditor-content";

function ListFeatureRequestsRow(props) {
  const {
    featureRequest
  } = props;

  const description = featureRequest.description + (featureRequest.description?.length === 50 ? "..." : "");

  return (

    <tr>
      <td className="feature-request-list-table-title">
        <Link className="" to={`/feature-request/${featureRequest.id}`}>{featureRequest.title}</Link>
        <div className="feature-request-list-table-description">
          <CkEditorContent content={description} />
        </div>
      </td>
      <td className="feature-request-line-votes">
        <i className="material-icons" title="Votes">thumb_up</i>&nbsp;{featureRequest.totalVotes}&nbsp;&nbsp;&nbsp;
      </td>
      <td className="feature-request-line-votes">
        <i className="material-icons feature-request-comment-icon" title="Comments">comment</i>&nbsp;{featureRequest.totalComments}
      </td>
    </tr>
  );
}

export default ListFeatureRequestsRow;