import { sortByName } from "./../../../shared/utility";
import mapPictures from "./../../../shared/image-mapper";
import OnboardingType from "../../../shared/onboarding/onboarding-type";
import { combineReducers } from "redux";
import orderReducer from "../order/ducks/reducer";

interface ActionState {
  actors: any[];
  loading: boolean;
  actor: any;
  imageFiles: any[];
  readOnly: boolean;
  onboardingSteps: OnboardingType[];
}

export const OnboardingStep1ClassName = 'actor-onboarding-step-1';
export const OnboardingStep2ClassName = 'actor-onboarding-step-2';

const defaultState: ActionState = {
  actors: [],
  loading: true,
  actor: {},
  imageFiles: [],
  readOnly: false,
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Number',
      content: `        
      <p>This is the relative importance of the Actor and, usually, the principle Actor will have a number of one. </p>
      <p>It is also the number and order they will appear on the Call Sheet.</p>
      <p>They must be unique.</p>
      <p>It is eaiser to change using the Order option on the left.</p>
      `
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_ACTORS_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "CLEAR_ACTOR": {
      return {
        ...state,
        loading: false,
        actor: { dateOfBirth: '' },
        errors: null,
        warnings: null
      };
    }

    case "FETCH_NEXT_ACTOR_NUMBER": {
      let nextNumber = 1;
      if (state.actors && state.actors.length > 0) {
        const maxNumber = Math.max.apply(
          null,
          state.actors.map((actor) => {
            return actor.number;
          })
        );
        nextNumber = maxNumber + 1;
      }

      return {
        ...state,
        actor: { number: nextNumber, dateOfBirth: "" }
      };
    }

    case "FETCH_ACTORS_FULFILLED": {
      return {
        ...state,
        actors: action.payload.data.actors,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null,
        warnings: null
      };
    }

    case "FETCH_SINGLE_ACTOR_PENDING": {
      return {
        ...state,
        actor: null,
        loadingActor: true,
        warnings: null
      };
    }

    case "FETCH_SINGLE_ACTOR_FULFILLED": {
      let actor = action.payload.data;
      mapPictures(actor);

      return {
        ...state,
        actor,
        loadingActor: false,
        errors: action.payload.data.hasErrors ? action.payload.data.errors : null,
        warnings: action.payload.data.hasWarnings ? action.payload.data.warnings : null,
      };
    }

    case "UPDATE_SINGLE_ACTOR_PENDING": {
      return {
        ...state,
        actor: action.payload,
        loadingActor: true,
        errors: null,
        warnings: null
      };
    }

    case "UPLOAD_IMAGES_PENDING": {
      return {
        ...state,
        loadingActor: true
      };
    }

    case "UPLOAD_IMAGES_REJECTED": {
      return {
        ...state,
        loadingActor: false,
        errors: action.payload.response.data.errors,
      };
    }

    case "UPLOAD_IMAGES_FULFILLED": {
      return {
        ...state,
        loadingActor: false
      };
    }

    case "UPDATE_SINGLE_ACTOR_FULFILLED": {
      const actor = { ...action.meta.actor, ...action.payload.data.actor };
      const actors: any = state.actors.map((c: any, index: number) => {
        if (c.id === actor.id) {
          return { ...c, ...actor };
        } else {
          return c;
        }
      });
      sortByName(actors);
      return {
        ...state,
        actor,
        actors,
        loadingActor: false,
        errors: null,
        warnings: action.payload.data.hasWarnings ? action.payload.data.warnings : null,
        redirect: action.payload.data.hasWarnings ? null : { to: "list" }
      };
    }

    case "SAVE_ACTOR_ORDER_FULFILLED": {
      const savedActors = Array.from(action.meta.actors);
      sortByName(savedActors);
      return {
        ...state,
        actors: savedActors
      };
    }

    case "UPDATE_SINGLE_ACTOR_REJECTED": {
      return {
        ...state,
        actor: action.meta.actor,
        errors: action.payload.response.data.errors,
        warnings: null,
        loadingActor: false
      };
    }

    case "ADD_SINGLE_ACTOR_PENDING": {
      return {
        ...state,
        errors: null,
        warnings: null,
        loadingActor: true
      };
    }

    case "ADD_SINGLE_ACTOR_FULFILLED": {
      const actor: any = action.payload.data.actor;

      let actors = [actor, ...state.actors];
      sortByName(actors);
      return {
        ...state,
        actor,
        actors,
        loadingActor: false,
        errors: null,
        warnings: null,
        redirect: { to: "list" }
      };
    }

    case "ADD_SINGLE_ACTOR_REJECTED": {
      return {
        ...state,
        actor: action.meta.actor,
        errors: action.payload.response.data.errors,
        loadingActor: false
      };
    }

    case "DELETE_SINGLE_ACTOR_PENDING": {
      return {
        ...state,
        actor: action.payload,
        loadingActor: true,
        errors: null,
        warnings: null
      };
    }

    case "DELETE_SINGLE_ACTOR_FULFILLED": {
      const actor = action.meta.actor;
      const actors: any = state.actors.filter((c: any, index: number) => {
        return c.id !== actor.id;
      });

      return {
        ...state,
        actor: null,
        actors,
        loadingActor: false,
        errors: null,
        warnings: null,
        redirect: { to: "list" }
      };
    }

    case "ACTOR_PARSE_MANDY_URL_PENDING": {
      return {
        ...state,
        actor: { ...state.actor, parsingUrl: true },
        parsingUrl: true
      };
    }

    case "ACTOR_PARSE_MANDY_URL_FULFILLED": {
      const response = action.payload.data;
      if (!response.success) {
        return state;
      }

      const actor: any = {
        mandyResponse: response,
        mandyUrl: action.meta.url
      };

      return {
        ...state,
        actor: { ...state.actor, ...actor, parsingUrl: false },
        errors: null,
        warnings: null
      };
    }

    case "ACTOR_PARSE_MANDY_URL_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        actor: { ...state.actor, parsingUrl: false },
        parsingUrl: false
      };
    }

    case "ACTOR_PARSE_STARNOW_URL_PENDING": {
      return {
        ...state,
        actor: { ...state.actor, parsingUrl: true },
        parsingUrl: true
      };
    }

    case "ACTOR_PARSE_STARNOW_URL_FULFILLED": {
      const response = action.payload.data;
      if (!response.success) {
        return state;
      }

      const actor: any = {
        starNowResponse: response,
        starNowUrl: action.meta.url
      };

      return {
        ...state,
        actor: { ...state.actor, ...actor, parsingUrl: false }
      };
    }

    case "ACTOR_PARSE_STARNOW_URL_REJECTED": {
      return {
        ...state,
        actor: { ...state.actor, parsingUrl: false },
        errors: action.payload.response.data.errors
      };
    }

    case "DELETE_SINGLE_ACTOR_REJECTED": {
      return {
        ...state,
        actor: action.meta.actor,
        errors: action.payload.response.data.errors,
        loadingActor: false
      };
    }

    case "ADD_ACTOR_IMAGE_FILES": {
      let mainImageUrl = state.actor.mainImageUrl;
      if (!mainImageUrl) {
        mainImageUrl = action.meta.imageFiles[0].name;
      }

      return {
        ...state,
        imageFiles: [...state.imageFiles, ...action.meta.imageFiles],
        actor: { ...state.actor, mainImageUrl: mainImageUrl }
      };
    }

    case "ADD_ACTOR_IMAGE_URL": {
      let actor: any = { ...state.actor };
      let mainImageUrl = state.actor.mainImageUrl;
      if (!mainImageUrl) {
        mainImageUrl = action.meta.url;
      }

      if (actor?.imageUrls) {
        actor = {
          ...actor,
          imageUrls: [...actor?.imageUrls, action.meta.url],
          mainImageUrl: mainImageUrl
        };
      } else {
        actor = {
          ...actor,
          imageUrls: [action.meta.url],
          mainImageUrl: mainImageUrl
        };
      }
      return {
        ...state,
        actor: actor
      };
    }

    case "DELETE_ACTOR_IMAGE_URL": {
      let imageUrls: any = state.actor.imageUrls;
      imageUrls = imageUrls.filter((item) => {
        return item !== action.meta.url;
      });
      return {
        ...state,
        actor: { ...state.actor, imageUrls: imageUrls }
      };
    }

    case "DELETE_ACTOR_IMAGE_FILE": {
      let imageFiles = state.imageFiles.filter((file) => {
        return file.name !== action.meta.fileName;
      });
      return {
        ...state,
        imageFiles: imageFiles
      };
    }

    case "ACTOR_MAIN_IMAGE_CHANGED": {
      return {
        ...state,
        actor: { ...state.actor, mainImageUrl: action.meta.url }
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        actor: null,
        redirect: null
      };
    }

    default:
      return state;
  }
};
export default combineReducers({
  rootState: reducer,
  orderState: orderReducer
});

