import React from "react";
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import Errors from "../../../shared/errors";
import {
    OnboardingStep1ClassName,
    OnboardingStep2ClassName
} from "./ducks/reducer";
import OrderItem from './order-item';

function Overview(props) {
    const {
        actors,
        filmId,
        errors,
        warnings,
        onboardingSteps,
        readOnly,
        updateActorOrder,
        saveActorOrder,
        pristine
    } = props;

    const onDragEnd = (result: DropResult) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        updateActorOrder(source.index, destination.index);
    };

    const onSave = () => {
        saveActorOrder();
    };

    return (
        <div>
            {errors && <Errors errors={errors} />}
            {warnings && <Errors errors={warnings} isWarning={true} />}

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={"trackorder"}>
                    {(provided: any) => (
                        <div ref={provided.innerRef}
                            {...provided.droppableProps}>
                            <table className={`table ${OnboardingStep1ClassName}`}>
                                <thead>
                                    <tr>
                                        <th>Actor</th>
                                        <th>Characters</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(actors || []).map((actor: any, index: number) => (
                                        <OrderItem key={actor.id} actor={actor} index={index} readOnly={readOnly} />
                                    ))}

                                    {provided.placeholder}
                                </tbody>
                            </table>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {!readOnly && <div className="buttons mt-1">
                <button className="button" onClick={onSave} disabled={pristine}>Save</button>
            </div>}
        </div>
    );
}

export default Overview;
