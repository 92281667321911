import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchBreakdown,
  downloadBreakdown,
  clearPdf,
  orderCharactersByScenes,
  orderCharactersByName
} from "./ducks/actions";
import Errors from "../../shared/errors";
import Loader from "../../shared/loader";
import UploadResult from "../../film-scripts/upload-result";
import Download from "../../shared/download";
import Scenes from "./scenes";
import OffScreen from "../icons/off-screen";
import NoDialog from "../icons/no-dialog";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";

const EditContainer = (props) => {
  const filmId = props.match.params.filmId;
  const {
    fetchBreakdown,
    downloadBreakdown,
    clearPdf,
    breakdown,
    download,
    loading,
    errors,
    progress,
    orderCharactersByScenes,
    orderCharactersByName,
    characterOrderBy
  } = props;

  useEffect(() => {
    fetchBreakdown(filmId);
  }, []);

  const onDownloadFile = () => {
    downloadBreakdown(filmId, characterOrderBy);
  };

  function renderFilm(breakdown) {
    return (
      <ScrollIntoView
        className="blade-content wider"
        path="/films/:filmId/breakdown/overview"
        loading={loading}>
        {errors && <Errors errors={errors} />}

        <Scenes scenes={breakdown.scenes}
          filmId={filmId}
          canAccessScenes={breakdown.canAccessScenes}
          canAccessSceneSettings={breakdown.canAccessSceneSettings}
          canAccessCharacters={breakdown.canAccessCharacters} />
        <div>
          <OffScreen className="small-icon" /> = Off Screen
        </div>
        <div>
          <NoDialog className="small-icon" /> = No Dialog
        </div>
        <div className="buttons mt-2">
          <Download
            progress={progress}
            clearPdf={clearPdf}
            download={download}
            downloadFile={onDownloadFile}></Download>
        </div>
      </ScrollIntoView>
    );
  }

  return (
    <>
      {(loading || !breakdown) && <Loader />}
      {!loading && breakdown && renderFilm(breakdown)}
    </>
  );
};

function mapStateToProps(state) {
  return {
    errors: state.filmBreakdownState.errors,
    loading: state.filmBreakdownState.loading,
    breakdown: state.filmBreakdownState.breakdown,
    download: state.filmBreakdownState.download,
    progress: state.filmBreakdownState.progress,
    characterOrderBy: state.filmBreakdownState.characterOrderBy
  };
}

export default connect(mapStateToProps, {
  fetchBreakdown,
  downloadBreakdown,
  clearPdf,
  orderCharactersByScenes,
  orderCharactersByName
})(EditContainer);
