import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { NavLink } from "react-router-dom";
import { ItemTypes } from "./constants";
import CanAccessNavLink from "../../../shared/can-access-nav-link";

function Character(props) {
  const {
    character,
    index,
    filmId,
    assignActor,
    unassignActor,
    aliasCharacter,
    unaliasCharacter,
    readOnly,
    totalScenes,
    canAccessActors
  } = props;

  const hasAliases = (character.aliases || []).length > 0;

  const dropActor = (item) => {
    assignActor(filmId, item, character);
  };

  const characterDropped = (alias) => {
    aliasCharacter(filmId, alias, character);
  };

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.ACTOR,
    drop: (item: any, monitor) => dropActor(item.actor),
    collect: (monitor) => ({
      isOver: !!monitor.isOver()
    })
  });

  const canDropCharacter = (item, monitor) => {
    return !readOnly && item.character.id != character.id;
  };

  const canDragCharacter = (monitor) => {
    return !readOnly && !hasAliases;
  };

  const [{ isOverCharacter }, dropCharacter] = useDrop({
    accept: ItemTypes.CHARACTER,
    drop: (item: any, monitor) => characterDropped(item.character),
    canDrop: (item, monitor) => canDropCharacter(item, monitor),
    collect: (monitor) => ({
      isOverCharacter: !!monitor.isOver()
    })
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.CHARACTER, character },
    canDrag: (monitor) => canDragCharacter(monitor),
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  });

  const getItemStyle = (isDragging) => ({
    background: isDragging ? "#947eed" : "#040f21"
  });

  return (
    <>
      <tr
        ref={drag}
        style={{
          cursor: !hasAliases && !readOnly ? "grab" : ""
        }}>
        <td ref={dropCharacter} style={getItemStyle(isOverCharacter)}>
          {!readOnly && !hasAliases && (
            <i className="material-icons dark">drag_indicator</i>
          )}
          {character.incomplete && (
            <span
              className="material-icons has-text-on-right"
              title="Character has incomplete information">
              timelapse
            </span>
          )}
          <NavLink to={`/films/${filmId}/characters/${character.id}/edit`}>
            {character.name}
          </NavLink>
        </td>
        <td>
          <figcaption className="fig-grid">
            <label className="numberOfScenes">{character.numberOfScenes}</label>
            <progress
              className="progress character-progress"
              value={character.numberOfScenes}
              max={totalScenes}
            />
          </figcaption>
        </td>
        <td ref={drop} style={getItemStyle(isOver)}>
          {character.actorName && (
            <>
              <CanAccessNavLink to={`/films/${filmId}/actors/${character.actorId}/edit`} canAccess={canAccessActors}>
                {character.actorName}
              </CanAccessNavLink>
              &nbsp;
              {!readOnly && (
                <a
                  onClick={() =>
                    unassignActor(filmId, character, character.actorName)
                  }>
                  <i className="material-icons">cancel</i>
                </a>
              )}
            </>
          )}
        </td>
        <td>
          {!character.actorName && (
            <a
              href={character.mandySearchUrl}
              target="_blank"
              rel="noopener noreferrer">
              Search Mandy
            </a>
          )}
          {character.actorMandyUrl && (
            <a
              href={character.actorMandyUrl}
              target="_blank"
              rel="noopener noreferrer">
              Mandy Profile
            </a>
          )}
        </td>
        <td>
          {!character.actorName && (
            <a
              href={character.starNowSearchUrl}
              target="_blank"
              rel="noopener noreferrer">
              Search StarNow
            </a>
          )}
          {character.actorStarNowUrl && (
            <a
              href={character.actorStarNowUrl}
              target="_blank"
              rel="noopener noreferrer">
              StarNow Profile
            </a>
          )}
        </td>
      </tr>
      {(character.aliases || []).map((alias) => (
        <tr key={alias.id}>
          <td colSpan={4}>
            <div style={{ marginLeft: 30 }}>
              {alias.name}
              &nbsp;
              {!readOnly && (
                <a onClick={() => unaliasCharacter(filmId, alias, character)}>
                  <i className="material-icons">cancel</i>
                </a>
              )}
            </div>
          </td>
        </tr>
      ))}
    </>
  );
}

export default Character;
