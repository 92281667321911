import React from "react";
import { Route } from "react-router-dom";
import ListActorsContainer from "./list-container";
import AddActor from "./add";
import EditActor from "./edit";
import ActorOrderContainer from "./order/container";
import { PrivateRoute } from "../../shared/private-route";

const ActorRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/actors"
        component={ListActorsContainer}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/actors/order"
        component={ActorOrderContainer}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/actors/add"
        component={AddActor}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/actors/add-from-mandy"
        component={(props) => <AddActor source="mandy" {...props} />}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/actors/add-from-starnow"
        component={(props) => <AddActor source="starnow" {...props} />}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/actors/:actorId"
        component={EditActor}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/actors/:actorId/edit"
        component={EditActor}
      />
    </>
  );
};

export default ActorRoutes;
