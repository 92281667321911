import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import BoxWraper from "../../../account/box-wrapper";
import Errors from "../../../shared/errors";
import FilmaizeSelect from "../../../shared/filmanize-select";
import Loader from "../../../shared/loader";
import {  
  searchFeatureRequests
  } from "../ducks/actions";
import List from "./list";

const FeedbackContainer = (props) => {
 const { 
  searchFeatureRequests, 
        featureRequests, 
        featureRequestsLoading, 
        pageSections,
        errors } = props;

  const [selectedSection, setSelectedSection] = useState<string | null>(null);
  const [searchString, setSearchString] = useState<any>();
  const [hasSearched, setHasSearched] = useState<boolean>(false);

//  useEffect(() => {  
//   fetchFeatureRequests(selectedSection);
//  }, [selectedSection]);
 
  const onChangeSection = (sectionOption: any) => {
    setSelectedSection(sectionOption.value);
  };

  const pageSectionOptions = pageSections.map((pageSection) => {
    return { value: pageSection.name, label: pageSection.name };
  });

  pageSectionOptions.unshift({ value: null, label: "All" });

  const selectedPageSectionOption = pageSectionOptions.find((section: any) => section.value === selectedSection);
  
  const onSearch = () => {
    searchFeatureRequests(selectedSection, searchString);
    setHasSearched(true);
  };

  const viewAll = () => {
    searchFeatureRequests(null, null);
    setHasSearched(true);
  };

  const onSearchChange = (e: any) => {    
    setSearchString(e.target.value);
  };

 return (    
  <BoxWraper width={5}>
        {featureRequestsLoading && <Loader/>}
        <h2 className="menu-label">Feature Requests</h2>
        <Errors errors={errors} />
        <div className="mb-1">
          Section
          <FilmaizeSelect
            value={selectedPageSectionOption}
            placeholder="Section"
            isMulti={false}
            options={pageSectionOptions}
            className="react-select-control"
            classNamePrefix="react-select"
            onChange={onChangeSection}
          />
        </div>
        <div className="mb-1">
          <div className={`field`}>
            <label className={"label"}>
              Text
            </label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Search text"
                value={searchString}
                onChange={onSearchChange}
              />
            </div>
          </div>
        </div>
        <div className="mb-1">
          <button className="button" onClick={onSearch}>Search</button>
          <button className="button ml-1" onClick={viewAll}>View All</button>
          <Link className="button ml-1" to={`/feature-request/add`}>Add</Link>
        </div>
        {hasSearched && 
          <List featureRequests={featureRequests} loading={featureRequestsLoading} />
        }        
    </BoxWraper>
  );

}


function mapStateToChangeLogs(state) {
    const { featureRequestState } = state;
    return {
        featureRequests : featureRequestState.featureRequests,
        featureRequestsLoading: featureRequestState.featureRequestsLoading,
        featureRequestSaving: featureRequestState.featureRequestSaving,    
        featureRequestSent: featureRequestState.featureRequestSent,
        featureRequestErrors: featureRequestState.featureRequestErrors,
        errors: featureRequestState.errors,
        pageSections: state.commonState.pageSections,
        activeSection: state.commonState.activeSection
    };
  }
  
  export default connect(mapStateToChangeLogs, {
    searchFeatureRequests
  })(FeedbackContainer);
  