import React from "react";
import { Route } from "react-router-dom";
import { PrivateRoute } from "../../../shared/private-route";
import ListPropsContainer from "./list-container";

const PropRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/scenes/:sceneId/props"
        component={ListPropsContainer}
      />
    </>
  );
};

export default PropRoutes;
