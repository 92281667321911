import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Prompt } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import FormErrors from "../../shared/form-errors";
import Errors from "./../../shared/errors";
import {
  renderCurrencyField,
  renderInputRangeField,
  renderReactSelect,
  renderSingleReactSelect,
  renderTextAreaField,
  renderTextField
} from "./../../shared/form-helpers";
import Images from "./../../shared/images";
import required, { maxDecimalValue } from "./../../shared/validation";
import { createMandyUrl, createStarNowUrl, createBackStageUrl } from './search-url-generator';

let CharacterForm = (props) => {
  const {
    handleSubmit,
    handleDelete,
    pristine,
    submitting,
    genderEnum,
    actors,
    mode,
    errors,
    ethnicityTypes,
    submitFailed,
    invalid,
    filmId,
    ageRangeValue,
    genderValue,
    ethnicitiesValue,
    loadingRate,
    rate,
    handleChangeCharacterToBackgroundCharacter
  } = props;

  const thisCharacter = props.initialValues || {};
  const readOnly = thisCharacter?.readOnly;
  const [mandySearchUrl, setMandySearchUrl] = useState<string>("");
  const [starNowSearchUrl, setStarNowSearchUrl] = useState<string>("");
  const [backStageSearchUrl, setBackStageSearchUrl] = useState<string>("");

  useEffect(() => {
    if (!loadingRate) {
      props.change("dailyBudget", rate);
    }
  }, [rate, loadingRate]);

  const ethnicityOptions = ethnicityTypes?.map((e) => {
    return { label: e.name, value: e.id };
  });

  const ethnicitySelectedOptions = thisCharacter.ethnicityIds?.map((e) => {
    return ethnicityOptions?.find((x) => x.value === e);
  });

  const genderOptions = (genderEnum || []).map((item) => { return { value: item.value, label: item.name } });

  const actorOptions = (actors || []).map((item) => { return { value: item.id, label: item.name } });
  actorOptions.splice(0, 0, { value: null, label: "None" });

  if (!pristine) {

  }

  const onSetAgeRange = (e, min, max) => {
    e.preventDefault();
    props.change("ageRange", { min, max });
  }

  useEffect(() => {
    setMandySearchUrl(createMandyUrl(ethnicitiesValue, ethnicityTypes, ageRangeValue, genderValue, thisCharacter.countryMandyId));
    setStarNowSearchUrl(createStarNowUrl(ethnicitiesValue, ethnicityTypes, ageRangeValue, genderValue, thisCharacter.countryMandyId));
    setBackStageSearchUrl(createBackStageUrl(ethnicitiesValue, ethnicityTypes, ageRangeValue, genderValue, thisCharacter.countryMandyId));
  }, [ageRangeValue,
    genderValue,
    ethnicitiesValue]);


  return (
    <>
      <Prompt when={!pristine} message="" />
      {mode === "add" && <h2 className="menu-label">Add Character</h2>}
      {mode === "edit" && <h2 className="menu-label">Edit Character</h2>}
      <div className="columns">
        <div className="column">
          {errors && <Errors errors={errors} />}
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Field
              name="name"
              label="Name"
              component={renderTextField}
              validate={[required]}
              required
              readOnly={readOnly}
            />

            <Field
              name="description"
              label="Description"
              component={renderTextAreaField}
              readOnly={readOnly}
            />

            <div className="field-body">
              <Field
                name="gender"
                label="Gender"
                component={renderSingleReactSelect}
                isMulti={false}
                options={genderOptions}
                validate={[required]}
                required
                readOnly={readOnly}>
              </Field>
            </div>

            <Field
              name="ageRange"
              label="Age Range"
              component={renderInputRangeField}
              readOnly={readOnly}
              quickSelection={true}
              setAgeRange={onSetAgeRange}
            />

            <Field
              label="Ethnicity"
              name="ethnicities"
              component={renderReactSelect}
              options={ethnicityOptions}
              selectedOptions={ethnicitySelectedOptions}
              readOnly={readOnly}
            />

            <Field
              name="dailyBudget"
              label="Daily Budget"
              component={renderCurrencyField}
              validate={[maxDecimalValue]}
              loading={loadingRate}
              readOnly={readOnly}
            />

            {(!thisCharacter.actorId) &&
              <div>

                <div>
                  <a href={mandySearchUrl} target="_blank">
                    Search Mandy
                  </a>
                  <br />
                  <a href={starNowSearchUrl} target="_blank">
                    Search StarNow
                  </a>
                  <br />
                  <a href={backStageSearchUrl} target="_blank">
                    Search Backstage
                  </a>
                </div>

                <br />
              </div>
            }

            <Field
              name="actorId"
              label="Actor"
              component={renderSingleReactSelect}
              isMulti={false}
              options={actorOptions}
              readOnly={readOnly}>
            </Field>

            {!readOnly && <div className="buttons mt-2">
              <button
                type="submit"
                className={
                  submitting ? "button is-primary is-loading" : "button is-primary"
                }
                disabled={pristine || submitting}>
                Save
              </button>
              {mode === "edit" && (
                <>
                  <button
                    type="button"
                    className="button is-text"
                    disabled={submitting || !props.initialValues}
                    onClick={() => handleDelete(thisCharacter)}>
                    Delete Character
                  </button>
                  <button
                    type="button"
                    className="button is-text"
                    disabled={submitting || !props.initialValues}
                    onClick={() => handleChangeCharacterToBackgroundCharacter(thisCharacter)}>
                    Change to Background Character
                  </button>
                </>
              )}
            </div>}
            <FormErrors submitFailed={submitFailed} invalid={invalid} />
          </form>
        </div>
        <div className="column">
          <Images change={props.change} initialValues={props.initialValues} readOnly={readOnly} filmId={filmId} />
        </div>
      </div>
    </>
  );
}

CharacterForm = reduxForm({
  form: "editCharacterForm",
  enableReinitialize: true
})(CharacterForm);

// Decorate with connect to read form values
const selector = formValueSelector('editCharacterForm'); // <-- same as form name
const CharacterFormConnect = connect(
  state => {
    // can select values individually
    const ageRangeValue = selector(state, 'ageRange');
    const genderValue = selector(state, 'gender');
    const ethnicitiesValue = selector(state, 'ethnicities');

    return {
      ageRangeValue,
      genderValue,
      ethnicitiesValue
    }
  }
)(CharacterForm)

export default CharacterFormConnect
