import React from "react";
import { Route } from "react-router-dom";
import { PrivateRoute } from "../../../shared/private-route";
import ListCostumesContainer from "./list-container";

const CostumeRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/scenes/:sceneId/costumes"
        component={ListCostumesContainer}
      />
    </>
  );
};

export default CostumeRoutes;
