import React from "react";
import { NavLink } from "react-router-dom";

const ActorsList = ({ actors, filmId }) => {
  return (
    <aside className="menu">
      <p className="menu-label">Actors</p>
      <ul className="menu-list">
        <li>
          <NavLink
            to={`/films/${filmId}/actors/order`}
            activeClassName="is-active">
            Order
          </NavLink>
        </li>
        <hr />

        {(actors || []).map((actor, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/films/${filmId}/actors/${actor.id}/edit`}
                title={actor.number + ". " + actor.name}
                activeClassName="is-active">
                {actor.number}. {actor.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default ActorsList;
