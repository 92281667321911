import React from "react";
import { useDrag } from "react-dnd";
import { NavLink } from "react-router-dom";
import { ItemTypes } from "./constants";

function Actor(props) {
  const { actor, index, filmId, readOnly } = props;

  const canDragActor = (monitor) => {
    return !readOnly;
  };

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.ACTOR, actor },
    canDrag: (monitor) => canDragActor(monitor),
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  });

  return (
    <tr
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: readOnly ? "" : "grab"
      }}>
      <td>
        {!readOnly && <i className="material-icons dark">drag_indicator</i>}
        <NavLink to={`/films/${filmId}/actors/${actor.id}/edit`}>
          {actor.name}
        </NavLink>
      </td>
    </tr>
  );
}

export default Actor;
