import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchSceneBreakdown,
  downloadBreakdown,
  clearPdf,
  changeItem,
  changeCharacterToBackgroundCharacter,
  changeBackgroundCharacterToCharacter,
  changePropToSetDressing,
  changePropToCostume,
  changeSetDressingToProp,
  changeCostumeToProp
} from "./ducks/actions";
import Errors from "../../shared/errors";
import Loader from "../../shared/loader";
import Scene from "./scene";

const EditContainer = (props) => {
  const filmId = props.match.params.filmId;
  const sceneId = props.match.params.sceneId;
  const {
    fetchSceneBreakdown,
    scene,
    loading,
    errors,
    readOnly,
    changeItem,
    changeCharacterToBackgroundCharacter,
    changeBackgroundCharacterToCharacter,
    changePropToSetDressing,
    changePropToCostume,
    changeSetDressingToProp,
    changeCostumeToProp
  } = props;

  useEffect(() => {
    fetchSceneBreakdown(filmId, sceneId);
  }, [filmId, sceneId]);


  function renderFilm(scene) {
    return (
      <div
        className="blade-content wider"
      >
        {errors && <Errors errors={errors} />}
        {loading && <Loader />}
        <Scene scene={scene}
          filmId={filmId}
          readOnly={readOnly}
          onDropItem={changeItem}
          changeCharacterToBackgroundCharacter={changeCharacterToBackgroundCharacter}
          changeBackgroundCharacterToCharacter={changeBackgroundCharacterToCharacter}
          changePropToSetDressing={changePropToSetDressing}
          changePropToCostume={changePropToCostume}
          changeSetDressingToProp={changeSetDressingToProp}
          changeCostumeToProp={changeCostumeToProp} />
      </div>
    );
  }

  return (
    <>

      {scene && renderFilm(scene)}
    </>
  );
};

function mapStateToProps(state) {
  return {
    errors: state.filmBreakdownState.errors,
    loading: state.filmBreakdownState.loading,
    scene: state.filmBreakdownState.scene,
    download: state.filmBreakdownState.download,
    progress: state.filmBreakdownState.progress,
    readOnly: state.filmBreakdownState.readOnly
  };
}

export default connect(mapStateToProps, {
  fetchSceneBreakdown,
  downloadBreakdown,
  clearPdf,
  changeItem,
  changeCharacterToBackgroundCharacter,
  changeBackgroundCharacterToCharacter,
  changePropToSetDressing,
  changePropToCostume,
  changeSetDressingToProp,
  changeCostumeToProp
})(EditContainer);
