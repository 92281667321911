import { combineReducers } from "redux";
import downloadScriptReducer from '../download-script/ducks/reducer';
import uploadScriptReducer from '../upload/ducks/reducer';
import smartBreakdownReducer from '../smart-breakdown/ducks/reducer';

import { DOWNLOAD_SCRIPT } from "../../../middleware/signalRMiddleware";
import { CLEAR_DOWNLOAD_SCRIPT, FINISH_MOVING_SPLIT_SCENE, QUEUE_DOWNLOAD_SCRIPT, RESET_DOWNLOAD_SCRIPT, SCRIPT_EDIT_MODE, SCRIPT_SPLIT_MODE, SHOW_MOVE_SPLIT_SCENE, SHOW_SPLIT_SCENE, SPLIT_SCRIPT, UNSPLIT_SCRIPT, UPDATE_SCRIPT } from "./actions";
import { ADD_SINGLE_VISUAL_EFFECT, ASSIGN_SCENES_TO_VISUAL_EFFECT, DELETE_SINGLE_VISUAL_EFFECT, UPDATE_SINGLE_VISUAL_EFFECT } from "../../visual-effects/ducks/actions";
import { CHANGE_PROP_TO_COSTUME, CHANGE_PROP_TO_SET_DRESSING } from "../../accessories/props/ducks/actions";
import { CHANGE_COSTUME_TO_PROP } from "../../accessories/costumes/ducks/actions";
import { CHANGE_SET_DRESSING_TO_PROP } from "../../accessories/setDressings/ducks/actions";
import { CHANGE_CHARACTER_TO_BACKGROUND_CHARACTER } from "../../characters/ducks/actions";
import { CHANGE_BACKGROUND_CHARACTER_TO_CHARACTER } from "../../background-characters/ducks/actions";

interface ScenesState {
  scenes: any[];
  script: any[];
  editedScript: any[];
  scene: any;
  loading: boolean;
  readOnly: boolean;
  selectedItem: any;
  selectScenes: any;
  characterNames: any[];
  propNames: any[];
  backgroundCharacterNames: any[];
  costumeNames: any[];
  makeupNames: any[];
  setDressingNames: any[];
  propId: any;
  setDressingId: any
  makeupId: any;
  costumeId: any;
  visualEffectId: any;
  itemId: any;
  mouseDown: boolean;
  reloadScript: boolean;
  scriptOnboardingSteps: any[];
  editMode: boolean;
  splitMode: boolean;
  movingScene: any;
  download: any;
  progress: any;
  canUploadNewVersion: boolean;
}

export const OnboardingStep1ClassName = 'script-onboarding-step-1';
export const OnboardingStep2ClassName = 'script-onboarding-step-2';
export const OnboardingStep3ClassName = 'script-onboarding-step-3';
export const OnboardingStep4ClassName = 'script-onboarding-step-4';
export const OnboardingStep5ClassName = 'script-onboarding-step-5';
export const OnboardingStep6ClassName = 'script-onboarding-step-6';
export const OnboardingStep7ClassName = 'script-onboarding-step-7';

const defaultState: ScenesState = {
  scenes: [],
  script: [],
  editedScript: [],
  scene: {},
  loading: true,
  readOnly: false,
  selectedItem: null,
  selectScenes: null,
  characterNames: [],
  propNames: [],
  backgroundCharacterNames: [],
  costumeNames: [],
  makeupNames: [],
  setDressingNames: [],
  propId: null,
  setDressingId: null,
  makeupId: null,
  costumeId: null,
  visualEffectId: null,
  itemId: null,
  mouseDown: false,
  reloadScript: false,
  editMode: false,
  splitMode: false,
  movingScene: null,
  download: null,
  progress: null,
  canUploadNewVersion: false,
  scriptOnboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Scenes',
      content: `Click on a scene to jump to it within the script.`,
      placement: 'right-start',
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Script Breakdown',
      content: `<p>Highlight text in the script that you want to breakdown. A pop-up menu will appear allowing you to flag the highlighted text as costume, make up, prop, set dressing, character or background character.</p>
                <p>You can also click on an existing item to edit it's details.</p>
                <p>The height of this panel is about the same as a page when printed in the standard format.</p>`
    },
    {
      target: '.' + OnboardingStep3ClassName,
      title: 'Download',
      content: `<p>Download the script in standard format. There are options to select which parts of the script you want to include.</p>`
    },
    {
      target: '.' + OnboardingStep4ClassName,
      title: 'Upload',
      content: `<p>Upload a new revision of the script.</p>`
    },
    {
      target: '.' + OnboardingStep5ClassName,
      title: 'Smart Breakdown ',
      content: `<p>If you didn't run this at the point of upload, or want to rerun it for certain scenes.</p>`
    },
    {
      target: '.' + OnboardingStep6ClassName,
      title: 'Split Scene',
      content: `<p>Select this option to split long scenes into sub scenes. This is usually to make a scene fit within a shooting day.</p> <p>In this mode you will no longer be able to highlight words to breakdown.</p>`
    },
    {
      target: '.' + OnboardingStep7ClassName,
      title: 'Edit Script',
      content: `<p>Select this option to make minor amends to the script. In this mode you will no longer be able to highlight words to breakdown.</p>
                <p>Use with caution as it is very experimental.</p>`
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case "FETCH_SCRIPT_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null,
        movingScene: null,
        splitMode: false,
        editMode: false,
        selectedItem: action.meta.clear ? null : state.selectedItem,
        selectScenes: action.meta.clear ? null : state.selectScenes
      };
    }

    case "FETCH_SCRIPT_FULFILLED": {
      return {
        ...state,
        script: action.payload.data.script,
        editedScript: action.payload.data.script,
        scenes: action.payload.data.scenes,
        readOnly: action.payload.data.readOnly,
        canUploadNewVersion: action.payload.data.canUploadNewVersion,
        loading: false,
        errors: null,
        selectedItem: action.meta.clear ? null : state.selectedItem,
        selectScenes: action.meta.clear ? null : state.selectScenes
      };
    }

    case "FETCH_SCRIPT_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case UPDATE_SCRIPT + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case UPDATE_SCRIPT + "_FULFILLED": {
      return {
        ...state,
        loading: false,
        errors: null,
        editMode: false
      };
    }

    case UPDATE_SCRIPT + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case SPLIT_SCRIPT + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case SPLIT_SCRIPT + "_FULFILLED": {
      return {
        ...state,
        loading: false,
        errors: null,
        splitMode: false,
        reloadScript: true
      };
    }

    case SPLIT_SCRIPT + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case UNSPLIT_SCRIPT + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case UNSPLIT_SCRIPT + "_FULFILLED": {
      return {
        ...state,
        loading: false,
        errors: null,
        splitMode: false,
        reloadScript: true
      };
    }

    case UNSPLIT_SCRIPT + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case FINISH_MOVING_SPLIT_SCENE + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case FINISH_MOVING_SPLIT_SCENE + "_FULFILLED": {
      return {
        ...state,
        loading: false,
        errors: null,
        splitMode: false,
        movingScene: null,
        reloadScript: true
      };
    }

    case FINISH_MOVING_SPLIT_SCENE + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "SCRIPT_SET_ITEM": {
      return {
        ...state,
        selectedItem: action.meta.item,
        selectScenes: null
      };
    }

    case "SCRIPT_EDIT_ITEM": {
      return {
        ...state,
        selectedItem: action.meta.item,
        itemId: action.meta.id,
        selectScenes: null
      };
    }

    case "ADD_SINGLE_PROP_FULFILLED": {
      const propId = action.payload.data.prop.id;
      return {
        ...state,
        reloadScript: true,
        selectScenes: "Prop",
        propId: propId
      };
    }

    case "DELETE_SINGLE_BACKGROUND_CHARACTER_FULFILLED":
    case "DELETE_SINGLE_CHARACTER_FULFILLED":
    case "DELETE_SINGLE_COSTUME_FULFILLED":
    case "DELETE_SINGLE_MAKEUP_FULFILLED":
    case "DELETE_SINGLE_SET_DRESSING_FULFILLED":
    case "DELETE_SINGLE_PROP_FULFILLED":
    case DELETE_SINGLE_VISUAL_EFFECT + "_FULFILLED":

    case "UPDATE_SINGLE_BACKGROUND_CHARACTER_FULFILLED":
    case "UPDATE_SINGLE_CHARACTER_FULFILLED":
    case "UPDATE_SINGLE_COSTUME_FULFILLED":
    case "UPDATE_SINGLE_MAKEUP_FULFILLED":
    case "UPDATE_SINGLE_SET_DRESSING_FULFILLED":
    case "UPDATE_SINGLE_PROP_FULFILLED":
    case "UPDATE_SINGLE_SCENE_FULFILLED":
    case UPDATE_SINGLE_VISUAL_EFFECT + "_FULFILLED":

    case "ASSIGN_SCENES_TO_BACKGROUND_CHARACTER_FULFILLED":
    case "ASSIGN_SCENES_TO_CHARACTER_FULFILLED":
    case "ASSIGN_SCENES_TO_COSTUME_FULFILLED":
    case "ASSIGN_SCENES_TO_MAKEUP_FULFILLED":
    case "ASSIGN_SCENES_TO_SET_DRESSING_FULFILLED":
    case "ASSIGN_SCENES_TO_PROP_FULFILLED":
    case ASSIGN_SCENES_TO_VISUAL_EFFECT + "_FULFILLED": {
      return {
        ...state,
        selectScenes: null,
        selectedItem: null
      };
    }

    case "ADD_SINGLE_SET_DRESSING_FULFILLED": {
      const setDressingId = action.payload.data.setDressing.id;
      return {
        ...state,
        reloadScript: true,
        selectScenes: "Set Dressing",
        setDressingId
      };
    }

    case "ADD_SINGLE_MAKEUP_FULFILLED": {
      const makeupId = action.payload.data.makeup.id;
      return {
        ...state,
        reloadScript: true,
        selectScenes: "Makeup",
        makeupId
      };
    }

    case "ADD_SINGLE_COSTUME_FULFILLED": {
      const costumeId = action.payload.data.costume.id;
      return {
        ...state,
        reloadScript: true,
        selectScenes: "Costume",
        costumeId
      };
    }

    case ADD_SINGLE_VISUAL_EFFECT + "_FULFILLED": {
      const visualEffectId = action.payload.data.visualEffect.id;
      return {
        ...state,
        reloadScript: true,
        selectScenes: "Visual Effect",
        visualEffectId
      };
    }

    case "ADD_SINGLE_CHARACTER_FULFILLED": {
      const characterId = action.payload.data.character.id;
      return {
        ...state,
        reloadScript: true,
        selectScenes: "Character",
        characterId
      };
    }

    case "ADD_SINGLE_BACKGROUND_CHARACTER_FULFILLED": {
      const backgroundCharacterId = action.payload.data.backgroundCharacter.id;
      return {
        ...state,
        reloadScript: true,
        selectScenes: "Background Character",
        backgroundCharacterId
      };
    }

    case "SCRIPT_RESET_CLEAR_FORM": {
      return {
        ...state,
        clearForm: false,
        errors: null,
        selectedItem: null,
        selectScenes: null
      };
    }

    case "SCRIPT_CLEAR_RELOAD": {
      return {
        ...state,
        reloadScript: false
      }
    }

    case "SCRIPT_EDIT_ELEMENT": {
      const { text, sceneId, elementIndex } = action.meta;
      const newScript = Array.from(state.editedScript);
      const scene = newScript.find(s => s.id === sceneId);
      scene.elements[elementIndex].text = text;
      scene.elements[elementIndex].updated = true;
      return {
        ...state,
        editedScript: newScript
      }
    }

    case SCRIPT_EDIT_MODE: {
      return {
        ...state,
        editMode: action.meta.editMode,
        splitMode: false
      }
    }

    case SCRIPT_SPLIT_MODE: {
      return {
        ...state,
        editMode: false,
        splitMode: action.meta.splitMode
      }
    }

    case QUEUE_DOWNLOAD_SCRIPT + "_PENDING": {
      return {
        ...state,
        loading: false,
        progress: { progress: null, message: 'Queued for generating PDF' }
      };
    }

    case QUEUE_DOWNLOAD_SCRIPT + "_FULFILLED": {
      return state;
    }

    case QUEUE_DOWNLOAD_SCRIPT + "_COMPLETE": {
      return {
        ...state,
        loading: false
      };
    }

    case DOWNLOAD_SCRIPT + "_PROGRESS": {
      return {
        ...state,
        progress: action.meta.progress
      };
    }

    case DOWNLOAD_SCRIPT + "_COMPLETE": {
      return {
        ...state,
        loading: false,
        progress: null,
        download: { url: action.meta.url, fileName: action.meta.fileName, downloaded: false }
      };
    }

    case DOWNLOAD_SCRIPT + "_ERRORED": {
      return {
        ...state,
        progress: null,
        errors: action.meta.errors
      };
    }

    case CLEAR_DOWNLOAD_SCRIPT: {
      var download = { ...state.download, downloaded: true }
      return {
        ...state,
        download: download
      };
    }

    case RESET_DOWNLOAD_SCRIPT: {
      return {
        ...state,
        download: null
      };
    }

    case SHOW_SPLIT_SCENE: {
      const { sceneId, elementIndex, show } = action.meta;
      const newScript = Array.from(state.editedScript);
      const scene = newScript.find(s => s.id === sceneId);
      scene.elements[elementIndex].showSplitScene = show;
      return {
        ...state,
        editedScript: newScript
      }
    }

    case SHOW_MOVE_SPLIT_SCENE: {
      const { scriptSceneId, sceneNumber } = action.meta;
      const newScript = Array.from(state.editedScript);
      const sceneIndex = newScript.findIndex(s => s.id === scriptSceneId);
      const scene = newScript[sceneIndex];
      const previousScriptScene = sceneIndex > 0 ? newScript[sceneIndex - 1] : null;
      scene.moving = true;
      //scene.elements[elementIndex].showSplitScene = show;
      return {
        ...state,
        editedScript: newScript,
        movingScene: { scriptSceneId, sceneNumber, previousScriptSceneId: previousScriptScene?.id }
      }
    }

    case CHANGE_PROP_TO_COSTUME + "_FULFILLED":
    case CHANGE_COSTUME_TO_PROP + "_FULFILLED":
    case CHANGE_PROP_TO_SET_DRESSING + "_FULFILLED":
    case CHANGE_SET_DRESSING_TO_PROP + "_FULFILLED":
    case CHANGE_CHARACTER_TO_BACKGROUND_CHARACTER + "_FULFILLED":
    case CHANGE_BACKGROUND_CHARACTER_TO_CHARACTER + "_FULFILLED": {
      return {
        ...state,
        selectedItem: null
      }
    }

    default:
      return state;
  }
};


export default combineReducers({
  rootState: reducer,
  downloadScriptState: downloadScriptReducer,
  uploadScriptState: uploadScriptReducer,
  smartBreakdownState: smartBreakdownReducer
});
