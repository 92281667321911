import ScriptItem from "./script-item";
import { useDrop } from "react-dnd";

const ScriptItems = ({ label, items, url, icon, readOnly, allowedItemTypes, itemType, dropItem, canDrag }) => {

  const onDropItem = (item) => {
    dropItem(item, itemType);
  };

  const canDropItem = (item, monitor) => {
    if (readOnly) {
      return false;
    }

    return allowedItemTypes.includes(item.type);
  };

  const [{ isOver }, drop] = useDrop({
    accept: allowedItemTypes,
    drop: (item: any, monitor) => onDropItem(item),
    canDrop: (item, monitor) => canDropItem(item, monitor),
    collect: (monitor) => ({
      isOver: !!monitor.isOver()
    })
  });

  const getItemStyle = (isDragging) => ({
    background: isDragging ? "#947eed" : "#040f21"
  });

  return (
    <>
      {items && (
        <div className="card card-script-items" ref={drop} style={getItemStyle(isOver)}>
          <header className="card-header">
            <div className="card-header-title card-script-items-title">
              <label>{icon({ className: "menu-icon" })} {label}</label>
            </div>
          </header>
          <div className="card-content pt-0">
            {items?.length < 1 && <ul>None</ul>}
            <ul>
              {(items || []).map((item, index) => (
                <ScriptItem item={item}
                  index={index} label={label} readOnly={readOnly} url={url}
                  itemType={itemType}
                  canDrag={canDrag}
                />
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default ScriptItems;
