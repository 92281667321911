import { Link } from "react-router-dom";
import IconWithBadge from "../shared/icon-with-badge";

const AdminMenu = (props) => {
  const { mobileMenu, menuItemClick } = props;
  return (
    <div className="navbar-item has-dropdown is-hoverable">
      <a className="navbar-link is-arrowless">
        <IconWithBadge count={0} countClass="warning">
          <i className="material-icons">supervisor_account</i>
        </IconWithBadge>
      </a>
      <div className="navbar-dropdown is-boxed">
        <Link
          to="/admin/change-logs"
          className={`navbar-item ${mobileMenu ? "mobile-menu" : ""}`}
          onClick={menuItemClick}>
          Change Log
        </Link>
        <Link
          to="/admin/unapproved-users"
          className={`navbar-item ${mobileMenu ? "mobile-menu" : ""}`}
          onClick={menuItemClick}>
          Unapproved Users
        </Link>
        <Link
          to="/admin/users"
          className={`navbar-item ${mobileMenu ? "mobile-menu" : ""}`}
          onClick={menuItemClick}>
          Users
        </Link>
        <Link
          to="/admin/users/countries"
          className={`navbar-item ${mobileMenu ? "mobile-menu" : ""}`}
          onClick={menuItemClick}>
          Users by Country
        </Link>
        <Link
          to="/admin/users/dates"
          className={`navbar-item ${mobileMenu ? "mobile-menu" : ""}`}
          onClick={menuItemClick}>
          Users by Date
        </Link>
        <Link
          to="/admin/faqs"
          className={`navbar-item ${mobileMenu ? "mobile-menu" : ""}`}
          onClick={menuItemClick}>
          FAQs
        </Link>
      </div>
    </div>
  );
}

export default AdminMenu;