import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

function CharacterMenu(props) {
  const { filmId, characterId } = props.match.params;
  const { character } = props;
  const invalidIds = ["add", "overview"];

  if (invalidIds.indexOf(characterId) > -1) {
    return null;
  }

  return (
    <div className="blade-menu menu">
      <p className="menu-label">Character</p>
      <ul className="menu-list">
        <li>
          <NavLink
            to={`/films/${filmId}/characters/${characterId}/edit`}
            activeClassName="is-active">
            Edit Character
          </NavLink>
        </li>
        {character?.canAccessScenes &&
          <li>
            <NavLink
              to={`/films/${filmId}/characters/${characterId}/scenes`}
              activeClassName="is-active">
              Scenes
            </NavLink>
          </li>}
      </ul>
    </div>
  );
}

function mapStateToProps(state) {
  const rootState = state.charactersState.rootState;
  return {
    character: rootState.character
  };
}

export default connect(mapStateToProps, {
})(CharacterMenu);
