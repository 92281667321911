import {
  clearNotifications,
  showError,
  showInfo
} from "./../../../../notifications/ducks/actions";
import getApiClient from "./../../../../shared/api";
import { getActionToUploadImagesAndSaveEntity } from "./../../../../shared/image-action-creator";

export const CHANGE_PROP_TO_SET_DRESSING = "CHANGE_PROP_TO_SET_DRESSING";
export const CHANGE_PROP_TO_COSTUME = "CHANGE_PROP_TO_COSTUME";

export function fetchProps(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_PROPS",
      payload: getApiClient().get(`/film/${filmId}/prop`)
    });
  };
}

export function clearProp() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_PROP"
    });
  };
}

export function setName(name) {
  return (dispatch) => {
    dispatch({
      type: "PROP_SET_NAME",
      meta: { name }
    });
  };
}

export function fetchNextPropNumber(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_NEXT_PROP_NUMBER"
    });
  };
}

export function fetchProp(filmId, propId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SINGLE_PROP",
      payload: getApiClient().get(`/film/${filmId}/prop/${propId}`)
    });
  };
}

export function updateProp(filmId, prop) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SINGLE_PROP",
      payload: getApiClient().put(`/film/${filmId}/prop/${prop.id}`, prop),
      meta: { prop }
    })
      .then((response) => {
        dispatch(showInfo(`Updated prop ${prop.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating prop ${prop.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function changePropToSetDressing(filmId, prop) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_PROP_TO_SET_DRESSING,
      payload: getApiClient().put(`/film/${filmId}/prop/${prop.id}/ChangeToSetDressing`, prop),
      meta: { prop }
    })
      .then((response) => {
        dispatch(showInfo(`Changed Prop ${prop.name} to Set Dressing`));
      })
      .catch((response) => {
        dispatch(showError(`Error changing Prop ${prop.name} to Set Dressing`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function changePropToCostume(filmId, prop) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_PROP_TO_COSTUME,
      payload: getApiClient().put(`/film/${filmId}/prop/${prop.id}/ChangeToCostume`, prop),
      meta: { prop }
    })
      .then((response) => {
        dispatch(showInfo(`Changed Prop ${prop.name} to Costume`));
      })
      .catch((response) => {
        dispatch(showError(`Error changing Prop ${prop.name} to Costume`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

function addPropWithImageUrls(dispatch, prop, filmId) {
  dispatch({
    type: "ADD_SINGLE_PROP",
    payload: getApiClient().post(`/film/${filmId}/prop/`, prop),
    meta: { prop }
  })
    .then((response) => {
      dispatch(showInfo(`Added prop ${prop.name}`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

function updatePropWithImageUrls(dispatch, prop, filmId) {
  dispatch({
    type: "UPDATE_SINGLE_PROP",
    payload: getApiClient().put(`/film/${filmId}/prop/${prop.id}`, prop),
    meta: { prop }
  })
    .then((response) => {
      dispatch(showInfo(`Updated prop ${prop.name}`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

export const uploadImagesAndSaveProp = getActionToUploadImagesAndSaveEntity(
  addPropWithImageUrls,
  updatePropWithImageUrls
);

export function addProp(filmId, prop) {
  return (dispatch) => {
    dispatch({
      type: "ADD_SINGLE_PROP",
      payload: getApiClient().post(`/film/${filmId}/prop/`, prop),
      meta: { prop }
    })
      .then((response) => {
        dispatch(showInfo(`Added prop ${prop.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding prop`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function deleteProp(filmId, prop) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SINGLE_PROP",
      payload: getApiClient().delete(`/film/${filmId}/prop/${prop.id}`),
      meta: { prop }
    })
      .then((response) => {
        dispatch(showInfo(`Deleted prop ${prop.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting prop ${prop.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addImageFiles(imageFiles) {
  return (dispatch) => {
    dispatch({
      type: "ADD_PROP_IMAGE_FILES",
      meta: { imageFiles }
    });
  };
}

export function addImageUrl(url) {
  return (dispatch) => {
    dispatch({
      type: "ADD_PROP_IMAGE_URL",
      meta: { url }
    });
  };
}

export function deleteImageUrl(url) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_PROP_IMAGE_URL",
      meta: { url }
    });
  };
}

export function deleteImageFile(fileName) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_PROP_IMAGE_FILE",
      meta: { fileName }
    });
  };
}

export function mainImageUrlChanged(url) {
  return (dispatch) => {
    dispatch({
      type: "PROP_MAIN_IMAGE_CHANGED",
      meta: { url }
    });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT"
    });
  };
}

export function fetchAllScenes(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_ALL_SCENES_FOR_PROPS",
      payload: getApiClient().get(`/film/${filmId}/scene`)
    });
  };
}

export function fetchScenesAssignedToProp(filmId, propId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SCENES_ASSIGNED_TO_PROPS",
      payload: getApiClient().get(
        `/film/${filmId}/Prop/${propId}/AssignedToScenes`
      )
    });
  };
}

export function setSceneAssignedToProp(sceneId) {
  return (dispatch) => {
    dispatch({
      type: "SET_SCENE_ASSIGNED_TO_PROPS",
      meta: { sceneId }
    });
  };
}

export function assignScenesToProp(filmId, propId, sceneIds) {
  return (dispatch) => {
    dispatch({
      type: "ASSIGN_SCENES_TO_PROP",
      payload: getApiClient().put(
        `/film/${filmId}/prop/${propId}/AssignToScenes`,
        { sceneIds }
      ),
      meta: { propId }
    })
      .then((response) => {
        dispatch(showInfo(`Assigned Prop to Scenes`));
      })
      .catch((response) => {
        dispatch(showError(`Error assigning Prop to Scenes`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function fetchAllCharacters(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_ALL_CHARACTERS_FOR_PROPS",
      payload: getApiClient().get(`/film/${filmId}/character`)
    });
  };
}

export function fetchAllSceneSettings(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_ALL_SCENE_SETTINGS_FOR_PROPS",
      payload: getApiClient().get(`/film/${filmId}/scenesetting`)
    });
  };
}

export function searchScenes(
  filmId,
  characterIds,
  sceneSettingIds,
  sceneNumberFrom,
  sceneNumberTo,
  selectSearchedScenes
) {
  return (dispatch) => {
    let filters: string[] = [];

    if (characterIds?.length) {
      filters.push(`characterIds=${characterIds.join(",")}`);
    }

    if (sceneSettingIds?.length) {
      filters.push(`sceneSettingIds=${sceneSettingIds.join(",")}`);
    }

    if (sceneNumberFrom) {
      filters.push(`sceneFrom=${sceneNumberFrom}`);
    }

    if (sceneNumberTo) {
      filters.push(`sceneTo=${sceneNumberTo}`);
    }

    dispatch({
      type: "SEARCH_SCENES_FOR_PROPS",
      payload: getApiClient().get(
        `/film/${filmId}/scene/search?${filters.join("&")}`
      ),
      meta: { selectSearchedScenes }
    });
  };
}

export function findInScript(
  filmId,
  propName
) {
  return (dispatch) => {
    dispatch({
      type: "SEARCH_SCRIPT_FOR_PROPS",
      payload: getApiClient().get(
        `/film/${filmId}/script/search/${propName}`
      ),
      meta: { selectSearchedScenes: true }
    });
  };
}

export function clearSceneSearchResults() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_SEARCH_SCENES_FOR_PROPS"
    });
  };
}
