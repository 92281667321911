import getApiClient from "./../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "./../../../notifications/ducks/actions";

export const SPLIT_SCENE = "SPLIT_SCENE";
export const SCENE_CLEAR_REDIRECT = "SCENE_CLEAR_REDIRECT";

export function fetchScenes(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SCENES",
      payload: getApiClient().get(`/film/${filmId}/scene`)
    });
  };
}

export function clearScene() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_SCENE"
    });
  };
}

export function fetchScene(filmId, sceneId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SINGLE_SCENE",
      payload: getApiClient().get(`/film/${filmId}/scene/${sceneId}`)
    });
  };
}

export function fetchNextSceneNumber(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_NEXT_SCENE_NUMBER"
    });
  };
}

export function updateScene(filmId, scene) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SINGLE_SCENE",
      payload: getApiClient().put(`/film/${filmId}/scene/${scene.id}`, scene),
      meta: { scene }
    })
      .then((response) => {
        dispatch(showInfo(`Updated scene ${scene.number}`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating scene ${scene.number}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addScene(filmId, scene) {
  scene.mainImageUrl = null;
  scene.imageUrls = [];

  return (dispatch) => {
    dispatch({
      type: "ADD_SINGLE_SCENE",
      payload: getApiClient().post(`/film/${filmId}/scene/`, scene),
      meta: { scene }
    })
      .then((response) => {
        dispatch(showInfo(`Added scene ${scene.number}`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding scene`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function deleteScene(filmId, scene) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SINGLE_SCENE",
      payload: getApiClient().delete(`/film/${filmId}/scene/${scene.id}`),
      meta: { scene }
    })
      .then((response) => {
        dispatch(showInfo(`Deleted scene ${scene.number}`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting scene ${scene.number}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function splitScene(filmId, scene) {
  return (dispatch) => {
    dispatch({
      type: SPLIT_SCENE,
      payload: getApiClient().post(`/film/${filmId}/scene/split`, { sceneId: scene.id }),
      meta: { sceneId: scene.id }
    })
      .then((response) => {
        dispatch(showInfo(`Deleted scene ${scene.number}`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting scene ${scene.number}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: SCENE_CLEAR_REDIRECT
    });
  };
}
