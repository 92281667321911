import React from "react";
import About from "./about";
import GetStarted from "./get-started";
import Prices from "./prices";
import Socials from "./socials";

const BottomSection = ({prices, currencySymbol}: any) => {
  
  return (
      <div className="marketing-bottom-section">
          <Prices currencySymbol={currencySymbol} prices={prices} /> 
          <About />
          <GetStarted />
          <Socials />
          <div className="marketing-copyright">
            Copyright © Film Tools Ltd 2020. All Rights Reserved. Company Registration 12507793. VAT GB 345 1054 29.
          </div>              
      </div>
  );
};

export default BottomSection;
