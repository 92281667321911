import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../../shared/loader";
import {
  fetchUsersByDate
} from "./ducks/actions";
import Form from "./form";
//import { dates } from "./dummy-data";

function Register(props) {
  const {
    fetchUsersByDate,
    loading,
    errors,
    dates
  } = props;

  useEffect(() => {
    fetchUsersByDate();
  }, []);

  if (loading) {
    return <Loader />;
  }

  const refresh = () => {
    fetchUsersByDate();
  };

  return (
    <div className="blade-content wideaf">
      <Form
        errors={errors}
        loading={false}
        initialValues={{ dates }}
        dates={dates}
        refresh={refresh}
        {...props}
      />
    </div>
  );
}

function mapStateToProps(state) {
  const { adminState } = state;
  return {
    dates: adminState.adminUsersByDateState.dates,
    errors: adminState.adminUsersByDateState.errors,
    loading: adminState.adminUsersByDateState.loading
  };
}

export default connect(mapStateToProps, {
  fetchUsersByDate
})(Register);
