import Moment from "react-moment";
import BoxWraper from "../account/box-wrapper";
import SpeechBubbleIcon from "../films/icons/speech-bubble";
import WhatsNewIcon from "../films/icons/whats-new";
import EnchancementIcon from "../films/icons/enchancement";
import NewFeatureIcon from "../films/icons/new-feature";
import './change-log.scss';

function ViewChangeLog(props) {  
  const { 
    changeLog,
    noNavbarPadding
     } = props;

  return (
    <BoxWraper width={5} noNavbarPadding={noNavbarPadding}>
      <h2 className="menu-label">What's New in Version {changeLog.versionName}</h2>
        {(changeLog?.changes || []).map((change, index) => (
          <div key={index} className="change-log-change">
            {change.changeType === 1 && <div className="change-log-change-header-tag" style={{backgroundColor: "#00ffce"}}><NewFeatureIcon className="change-log-icon" /></div>}
              {change.changeType === 2 && <div className="change-log-change-header-tag" style={{backgroundColor: "#F75A65"}}><EnchancementIcon className="change-log-icon" /></div>}
              {change.changeType === 3 && <div className="change-log-change-header-tag" style={{backgroundColor: "#947eed"}}><EnchancementIcon className="change-log-icon" /></div>}              
              {change.changeType === 4 && <div className="change-log-change-header-tag" style={{backgroundColor: "#F9E26E"}}><WhatsNewIcon className="change-log-icon" /></div>} 
            
            <div className="change-log-change-text">
              <div className="change-log-headline">{change.headline}</div>
              <div dangerouslySetInnerHTML={{ __html: change.details }}></div>              
            </div>
            </div>
        ))}      
        <div className="change-log-date-container">
          <Moment className="change-log-date" fromNow withTitle={true} titleFormat="Do MMMM YYYY [at] HH:mm">{changeLog.releaseDate}</Moment>        
        </div>
    </BoxWraper>
  );
}

export default ViewChangeLog;
