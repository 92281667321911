import React from "react";
import { Link } from "react-router-dom";
import './get-started.scss';

const GetStarted = () => {
   
    return (
        <div className="marketing-get-started">
            <div className="marketing-get-started-box">
                <div className="marketing-get-started-content">
                    <div className="get-started-title">Get started for free</div>                    
                    <div className="get-started-register">Register today to try Filmanize for free</div>
                    <div className="get-started-register">Check out the <a href="https://www.youtube.com/watch?v=eOror_QjPZM&list=PLEGzR_hAtu4Jxatzc52ewuIuzgUHZIAKU&index=1" target="_blank">video tutorials</a></div>
                    <Link to={'/register'} className="button try-beta-button">Try the beta now</Link>                    
                </div>
            </div>
        </div>
    )
}

export default GetStarted;