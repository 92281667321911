import { FETCH_ADMIN_CHANGE_LOG,
         FETCH_ADMIN_CHANGE_LOGS } from './actions';

interface ActionState {
  changeLogs: any[];
  loading: boolean;
  changeLog: any;
  readOnly: boolean;
  redirect: any
}

const defaultState: ActionState = {
  changeLogs: [],
  loading: true,  
  changeLog: {},
  readOnly: false,
  redirect: null
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case FETCH_ADMIN_CHANGE_LOGS + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }
    
    case FETCH_ADMIN_CHANGE_LOGS + "_FULFILLED": {      
      return {
        ...state,
        changeLogs: action.payload.data.changeLogs,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null
      };
    }

    case FETCH_ADMIN_CHANGE_LOG + "_PENDING" : {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case FETCH_ADMIN_CHANGE_LOG + "_FULFILLED": {      
      return {
        ...state,
        changeLog: action.payload.data,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null
      };
    }
    
    case "ADD_CHANGE_LOG_PENDING": {
      return {
        ...state,
        errors: null,
        loading: true
      };
    }

    case "ADD_CHANGE_LOG_FULFILLED": {
      let changeLog: any = action.payload.data.changeLog;
      let changeLogs = [changeLog, ...state.changeLogs];
      sortByReleaseDate(changeLogs);

      return {
        ...state,
        changeLog,
        changeLogs,
        loading: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "ADD_CHANGE_LOG_REJECTED": {
      return {
        ...state,
        changeLog: action.meta.changeLog,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "UPDATE_CHANGE_LOG_PENDING" : {
      return {
        ...state,
        errors: null,
        loading: true
      };
    }

    case "UPDATE_CHANGE_LOG_FULFILLED": {

      //sortByReleaseDate(changeLogs);
      return {
        ...state,      
        loading: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "UPDATE_CHANGE_LOG_REJECTED": {
      return {
        ...state,
        changeLog: action.meta.changeLog,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "SEND_CHANGE_LOG_PENDING" : {
      return {
        ...state,
        errors: null,
        loading: true
      };
    }

    case "SEND_CHANGE_LOG_FULFILLED": {
      return {
        ...state,      
        loading: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "SEND_CHANGE_LOG_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false,
        redirect: null
      };
    }

    case "CLEAR_CHANGE_LOG" :{      
      return {
        ...state,
        loading: false,
        changeLog: {releaseDate: (new Date()).toISOString(), versionName: action.meta.apiVersion, changes: [ {headline:null, details: null }] },
        errors: null
      };
    }

    case "CLEAR_REDIRECT_CHANGE_LOG": {
      return {
        ...state,
        redirect: null
      };
    }

    default:
      return state;
  }
};

export function sortByReleaseDate(list) {
  list.sort((a, b) => {
    return a.releaseDate - b.releaseDate;
  });
}


export default reducer;
