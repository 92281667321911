import { invalid } from "moment";
import React from "react";
import Dropzone from "react-dropzone";
import { connect } from 'react-redux';
import { Prompt } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import FormErrors from "../../shared/form-errors";
import {
  renderSelectField,
  renderTextField,
  renderCheckbox,
  renderCurrencyField,
  renderSingleReactSelect
} from "./../../shared/form-helpers";
import required, { maxDecimalValue } from "./../../shared/validation";
import { OnboardingStep1ClassName, OnboardingStep2ClassName, OnboardingStep3ClassName } from './ducks/reducer';
import UploadIcon from "../icons/upload-large";
import Images from "../../shared/images";
import SingleImageUpload from "../../shared/single-image-upload";

function FilmForm(props) {
  const {
    filmId,
    filmTypes,
    filmStatuses,
    aspectRatios,
    genreTypes,
    frameRates,
    resolutions,
    mode,
    submitFailed,
    invalid,
    change,
    showHoursBeforeToSendCallSheet,
    currencies,
    userCurrencyId,
    languages,
    countries,
    actorUnions,
    crewUnions,
    setImage,
    imageFileName,
    canAddProductionCompanyLogo,
    canChangeFilmType,
    initialValues,
    showExport
  } = props;
  const { handleSubmit, handleDelete, handleArchive, handleCopy, pristine, reset, submitting, handleExport } = props;
  const thisFilm = initialValues || {};
  const readOnly = thisFilm.readOnly;

  if (!thisFilm.hoursBeforeToSendCallSheet) {
    thisFilm.hoursBeforeToSendCallSheet = 24;
  }

  const filmTypeOptions = (filmTypes || []).map((item) => { return { value: item.value, label: item.name } });
  const filmStatusOptions = (filmStatuses || []).map((item) => { return { value: item.value, label: item.name } });
  const aspectRatioOptions = (aspectRatios || []).map((item) => { return { value: item.id, label: item.ratio } });
  const genreOptions = (genreTypes || []).map((item) => { return { value: item.name, label: item.name } });
  const resolutionOptions = (resolutions || []).map((item) => { return { value: item.name, label: item.name } });
  const frameRateOptions = (frameRates || []).map((item) => { return { value: item.framesPerSecond, label: item.framesPerSecond } });
  const currencyOptions = (currencies || []).map((item) => { return { value: item.id, label: item.name } });
  const languageOptions = (languages || []).map((item) => { return { value: item.id, label: item.name } });
  const countryOptions = (countries || []).map((item) => { return { value: item.id, label: item.name } });
  const actorUnionOptions = (actorUnions || []).map((item) => { return { value: item.value, label: item.name } });
  const crewUnionOptions = (crewUnions || []).map((item) => { return { value: item.value, label: item.name } });

  const onSetImage = (image) => {
    setImage(image);
    props.change("productionCompanyLogoFileName", image?.name ?? null);
  };

  return (
    <>
      <Prompt when={!pristine} message="" />
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Field
          name="name"
          label="Film Name"
          component={renderTextField}
          validate={[required]}
          required
          readOnly={readOnly}
        />

        <Field
          name="productionCompany"
          label="Production Company"
          component={renderTextField}
          readOnly={readOnly}
        />
        {canAddProductionCompanyLogo &&
          <div className="mb-1">
            <SingleImageUpload setImage={onSetImage} imageFileName={imageFileName} label="Production Company Logo" maximumImageHeight={100} />
          </div>}
        <div className="columns">
          <div className="column">
            <Field
              name="filmType"
              component={renderSingleReactSelect}
              options={filmTypeOptions}
              label="Film Type"
              validate={[required]}
              required
              readOnly={readOnly || !canChangeFilmType}>
            </Field>
          </div>
          <div className="column">
            <Field
              name="status"
              component={renderSingleReactSelect}
              options={filmStatusOptions}
              label="Film Status"
              validate={[required]}
              required
              readOnly={readOnly}>
            </Field>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Field
              name="length"
              type="number"
              label="Length (minutes)"
              component={renderTextField}
              validate={[maxDecimalValue]}
              readOnly={readOnly}
            />

          </div>
          <div className="column">
            <Field
              name="genre"
              label="Genre"
              component={renderSingleReactSelect}
              options={genreOptions}
              includeEmpty={true}
              readOnly={readOnly}>
            </Field>
          </div>
        </div>

        <div className="columns">
          <div className={`column`}>
            <Field
              name="budget"
              label="Budget"
              component={renderCurrencyField}
              readOnly={readOnly}
              validate={[maxDecimalValue]}
            />
          </div>
          <div className={`column ${OnboardingStep1ClassName}`}>
            <Field
              name="aspectRatioId"
              label="Aspect Ratio"
              component={renderSingleReactSelect}
              options={aspectRatioOptions}
              includeEmpty={true}
              readOnly={readOnly}>
            </Field>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <Field
              name="resolution"
              label="Resolution"
              component={renderSingleReactSelect}
              options={resolutionOptions}
              includeEmpty={true}
              readOnly={readOnly}>
            </Field>
          </div>
          <div className="column">
            <Field
              name="frameRate"
              label="Frame Rate"
              component={renderSingleReactSelect}
              options={frameRateOptions}
              includeEmpty={true}
              readOnly={readOnly}>
            </Field>
          </div>
        </div>

        {actorUnionOptions?.length > 0 && crewUnionOptions?.length > 0 && <div className="columns">
          <div className="column">
            {actorUnionOptions?.length > 0 && <Field
              name="actorUnion"
              label="Actor Union"
              component={renderSingleReactSelect}
              options={actorUnionOptions}
              includeEmpty={true}
              emptyName="None"
              readOnly={readOnly}>
            </Field>}
          </div>
          <div className="column">
            {crewUnionOptions?.length > 0 && <Field
              name="crewUnion"
              label="Crew Union"
              component={renderSingleReactSelect}
              options={crewUnionOptions}
              includeEmpty={true}
              emptyName="None"
              readOnly={readOnly}>
            </Field>}
          </div>
        </div>}


        <div className="columns">
          <div className="column">
            <Field
              name="currencyId"
              label="Currency"
              component={renderSingleReactSelect}
              options={currencyOptions}
              validate={[required]}
              required
              readOnly={readOnly}>
            </Field>
          </div>
          <div className={`column ${OnboardingStep2ClassName}`}>
            <Field
              name="languageId"
              label="Language"
              component={renderSingleReactSelect}
              options={languageOptions}
              validate={[required]}
              required
              readOnly={readOnly} />
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <Field
              name="countryId"
              label="Country"
              component={renderSingleReactSelect}
              options={countryOptions}
              validate={[required]}
              required
              readOnly={readOnly}>
            </Field>
          </div>
          <div className={`column`}>
          </div>
        </div>

        <div className="columns">
          <div className={`column ${OnboardingStep3ClassName}`}>
            <Field
              className={`mr-1`}
              name="automaticallySendCallSheets"
              label="Automatically Send Call Sheets"
              component={renderCheckbox}
              value={thisFilm.automaticallySendCallSheets}
              readOnly={readOnly}>
            </Field>
          </div>
          <div className="column">
            {showHoursBeforeToSendCallSheet && <Field
              name="hoursBeforeToSendCallSheet"
              label="Hours before call time"
              type="number"
              component={renderTextField}
              validate={[maxDecimalValue]}
              value={thisFilm.hoursBeforeToSendCallSheet}
              readOnly={readOnly}>
            </Field>}
          </div>

        </div>
        <div className="buttons mt-2">
          {!readOnly &&
            <>
              <button
                type="submit"
                className={
                  submitting ? "button is-primary is-loading" : "button is-primary"
                }
                disabled={pristine || submitting}>
                Save
              </button>
              <button
                type="button"
                className="button is-secondary"
                disabled={pristine || submitting}
                onClick={reset}>
                Reset Values
              </button>
            </>
          }
          {mode === "edit" && (
            <>
              {thisFilm.canDelete && <button
                type="button"
                className="button is-text"
                disabled={submitting || !props.initialValues}
                onClick={() => handleDelete(thisFilm)}>
                Delete Film
              </button>}

              {!readOnly &&
                <>
                  {thisFilm.canCopy && <button
                    type="button"
                    className="button is-text"
                    disabled={submitting || !props.initialValues}
                    onClick={() => handleCopy()}>
                    Copy Film
                  </button>}
                  {thisFilm.canArchive && <button
                    type="button"
                    className="button is-text"
                    disabled={submitting || !props.initialValues}
                    onClick={() => handleArchive()}>
                    Archive Film
                  </button>}
                </>
              }
              {showExport && <button
                type="button"
                className="button is-text"
                disabled={submitting || !props.initialValues}
                onClick={handleExport}>
                Export Film
              </button>}
            </>
          )}
        </div>
        <FormErrors submitFailed={submitFailed} invalid={invalid} />
      </form>
    </>
  );
}

const form = "editFilmForm";

const EditFilmForm = reduxForm({
  form
})(FilmForm);

const selector = formValueSelector(form);

export default connect(state => {
  const showHoursBeforeToSendCallSheet = selector(state, 'automaticallySendCallSheets')

  return {
    showHoursBeforeToSendCallSheet
  }
})(EditFilmForm);
