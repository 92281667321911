import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";
import {
  addChangeLog,
  clearChangeLog,
  clearRedirect
} from "./ducks/actions";
import Form from "./form";

function AddChangeLog(props) {  
  const { addChangeLog,    
    errors,
    changeLog,
    clearChangeLog,
    loading,
    redirect,
    clearRedirect,
    apiVersion
     } = props;

  useEffect(() => {
    clearChangeLog(apiVersion);    
  }, []);

  if (redirect) {
    if (redirect.to === "list") {
      clearRedirect();
      const url = `/admin/change-logs`;
      return <Redirect to={url} />;
    } 
  }

  function onAddChangeLog(changeLog) {
    addChangeLog(changeLog);    
  }

  return (
    <ScrollIntoView
      className="blade-content widest"
      path="/admin/change-logs/add"
      loading={false}>
      <Form
        errors={errors}
        costumeId={null}
        initialValues={changeLog}
        loading={loading}
        onSubmit={onAddChangeLog}
        mode="add"
        changeLogChangeTypeEnum={props.changeLogChangeTypeEnum}
        reduxFormValues={props.reduxFormValues}        
      />
    </ScrollIntoView>
  );
}

function mapStateToChangeLogs(state) {
  const { adminChangeLogState, commonState } = state;
  return {
    changeLog: adminChangeLogState.changeLog,
    errors: adminChangeLogState.errors,
    redirect: adminChangeLogState.redirect,
    loading: adminChangeLogState.loading,
    changeLogChangeTypeEnum: commonState.changeLogChangeTypeEnum,
    apiVersion: commonState.apiVersion?.version,
  };
}

export default connect(mapStateToChangeLogs, {
  addChangeLog,
  clearChangeLog,
  clearRedirect
})(AddChangeLog);
