import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import {
  addProp,
  clearProp,
  clearRedirect,
  uploadImagesAndSaveProp,
  setName
} from "./ducks/actions";
import Form from "./form";

function AddProp(props) {
  const filmId = props.filmId || props.match.params.filmId;
  const { addProp, prop, errors, uploadImagesAndSaveProp, setName, name, embedded, clearProp, sceneId } = props;

  useEffect(() => {
    clearProp();
  }, []);

  useEffect(() => {
    if (name) {
      setName(name);
    }
  }, [name]);

  if (!embedded && props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/props/`;
      return <Redirect to={url} />;
    } else if (props.redirect.to === "scenes") {
      props.clearRedirect();
      const url = `/films/${filmId}/props/${props.redirect.id}/scenes/`;
      return <Redirect to={url} />;
    }
  }

  function onAddProp(prop) {
    prop.sceneId = sceneId;
    const files = prop.pictures || [];
    if (files && files.length > 0) {
      uploadImagesAndSaveProp(prop, files, true, filmId);
    } else {
      if (!prop.imageUrls) {
        prop.imageUrls = [];
      }
      addProp(filmId, prop);
    }
  }

  return (
    <ScrollIntoView
      className="blade-content widest"
      path="/films/:filmId/props/add"
      loading={false}>
      <Form
        errors={errors}
        propId={null}
        initialValues={prop}
        loading={false}
        onSubmit={onAddProp}
        {...props}
        mode="add"
        acquisitionMethodEnum={props.acquisitionMethodEnum}
        reduxFormValues={props.reduxFormValues}
      />
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  const { propsState, commonState } = state;

  return {
    prop: propsState.prop,
    errors: propsState.errors,
    redirect: propsState.redirect,
    acquisitionMethodEnum: commonState.acquisitionMethod
  };
}

export default connect(mapStateToProps, {
  addProp,
  clearProp,
  clearRedirect,
  uploadImagesAndSaveProp,
  setName
})(AddProp);
