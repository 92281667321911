const defaultState = {
  character: {},
  characters: [],
  loading: true
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_SET_DRESSINGS_IN_SCENE_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "FETCH_SET_DRESSINGS_IN_SCENE_FULFILLED": {
      return {
        ...state,
        setDressings: action.payload.data.setDressings,
        loading: false,
        errors: null
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        character: null,
        redirect: null
      };
    }

    default:
      return state;
  }
};

export default reducer;
