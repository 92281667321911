import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Moment from "react-moment";
import { Link, NavLink } from "react-router-dom";
import PageToEighth from "../../../shared/eighth";
import Pages from "../../../shared/pages";
import Download from "../../../shared/download";
import {
  OnboardingStep1ClassName,
  OnboardingStep2ClassName,
  OnboardingStep3ClassName,
  OnboardingStep4ClassName,
  OnboardingStep5ClassName,
  OnboardingStep6ClassName,
  OnboardingStep7ClassName,
  OnboardingStep8ClassName
} from "./ducks/reducer";
import { formatTime } from "../../../shared/format-time";
import ScenesWithoutDays from "./scenes-without-days";
import Dropzone from "react-dropzone";

const DaysList = ({
  hasDayOrder,
  days,
  filmId,
  updateSceneOrder,
  addShootingDay,
  deleteShootingDay,
  updateDayOrder,
  onAddScenesShootingOrderDays,
  smartScenesShootingOrderByDay,
  progress,
  download,
  clearPdf,
  onDownloadFile,
  onDeleteAllShootingDays,
  onResetSceneShootDays,
  readOnly,
  scenesWithoutDays,
  hasDates
}) => {

  const onDragEnd = (result) => {

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.type == "scene") {
      updateSceneOrder(
        result.source.index,
        result.destination.index,
        result.source.droppableId,
        result.destination.droppableId
      );
    } else if (result.type == "day") {
      updateDayOrder(result.source.index, result.destination.index);
    }
  };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    //padding: grid * 2,
    borderRadius: "5px",
    //margin: `0 0 ${grid}px 0`,
    // change background colour if dragging
    background: isDragging ? "#222E44" : "#040F21",
    color: isDragging ? "white" : "white",
    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getDayStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    //padding: grid * 2,
    //margin: `0 0 ${grid}px 0`,
    borderRadius: "5px",
    // change background colour if dragging
    background: isDragging ? "#222E44" : "#947eed",
    color: isDragging ? "white" : "#182231",
    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "grey" : "#272b30",
    padding: grid,
    width: 250
  });

  const acceptedFileTypes = [".pdf"];

  return (
    <>
      {!readOnly && <div className="buttons mt-2">
        <button
          type="submit"
          className={`button ${OnboardingStep1ClassName}`}
          onClick={() => smartScenesShootingOrderByDay()}
        >
          Smart Days
        </button>
        <button
          type="submit"
          className={`button ${OnboardingStep6ClassName}`}
          onClick={() => onDeleteAllShootingDays()}
        >
          Delete All
        </button>

        <button
          type="submit"
          className={`button ${OnboardingStep7ClassName}`}
          onClick={() => onResetSceneShootDays()}
        >
          Reset Scene Shoot Days
        </button>

      </div>}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" type="day">
          {(provided, snapshot) => (
            <div className="columns">
              <div className="column">
                <table
                  className={`table no-last-border ${OnboardingStep2ClassName}`}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <thead>
                    <tr>
                      <th>Number</th>
                      <th>Scene Setting</th>
                      <th>Location</th>
                      <th>Int/Ext</th>
                      <th>Day/Night</th>
                      <th>Summary</th>
                      <th>Pages</th>
                      <th>Shoot Days</th>
                      <th>Shoot Duration</th>
                      <th>Characters</th>
                      <th>Script Day</th>
                    </tr>
                  </thead>

                  {(days || []).map((day, index) => (
                    <Draggable
                      key={day.number}
                      draggableId={day.number + ""}
                      index={index}
                      isDragDisabled={readOnly || day.fixed || hasDates}
                    >
                      {(draggableProvided, draggableSapshot) => (
                        <Droppable droppableId={day.number + ""} type="scene">
                          {(provided, snapshot) => (
                            <tbody
                              key={index}
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              <tr
                                ref={draggableProvided.innerRef}
                                className={index === 0 ? OnboardingStep5ClassName : ""}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                                style={getDayStyle(
                                  draggableSapshot.isDragging,
                                  draggableProvided.draggableProps.style
                                )}
                              >
                                <td colSpan={3}>
                                  {!readOnly && !day.fixed && !hasDates && <i className="material-icons">drag_indicator</i>}
                                  <Link to={`${day.id}/edit`}>Day {day.number} &nbsp; &nbsp;</Link>
                                  {day.date && (
                                    <Moment format="Do MMMM YYYY">
                                      {day.date}
                                    </Moment>
                                  )}
                                </td>
                                <td colSpan={3}></td>
                                <td>
                                  <PageToEighth
                                    page={day.scenes?.reduce(function (a, b) {
                                      return a + b.pageLength;
                                    }, 0)}
                                  ></PageToEighth>
                                </td>
                                <td>
                                  {day.scenes ? Math.round(
                                    day.scenes?.reduce(function (a, b) {
                                      return a + b.shootDays;
                                    }, 0) * 10
                                  ) / 10 : ""}
                                </td>
                                <td></td>
                                <td></td>
                                <td>
                                  {(!day.scenes || day.scenes?.length === 0) && (
                                    <a onClick={() => deleteShootingDay(day.number)}>
                                      {!readOnly && <i className="material-icons">delete</i>}
                                    </a>
                                  )}
                                </td>
                              </tr>
                              {(day.scenes || []).map((scene, index) => (
                                <Draggable
                                  key={scene.sceneId}
                                  draggableId={scene.sceneId + day.number + index + scene.locationId + scene.subLocationId}
                                  index={index}
                                  isDragDisabled={readOnly}
                                >
                                  {(provided, snapshot) => (
                                    <>
                                      <tr
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={index === 0 ? OnboardingStep3ClassName : ""}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <td>
                                          {!readOnly && <i className="material-icons">
                                            drag_indicator
                                          </i>}
                                          <NavLink
                                            to={`/films/${filmId}/scenes/${scene.sceneId}/edit`}
                                          >
                                            {scene.number}
                                          </NavLink>
                                        </td>
                                        <td>
                                          <NavLink
                                            to={`/films/${filmId}/scene-settings/${scene.settingId}/edit`}
                                          >
                                            {scene.settingName}
                                          </NavLink>
                                        </td>
                                        <td>
                                          <NavLink
                                            to={`/films/${filmId}/locations/${scene.locationId}/edit`}
                                          >
                                            {scene.locationName}{scene.subLocationName && <span> - {scene.subLocationName}</span>}
                                          </NavLink>
                                        </td>
                                        <td>{scene.interiorExterior}</td>
                                        <td>{scene.dayNight}</td>
                                        <td>{scene.summary}</td>
                                        <td>
                                          <PageToEighth
                                            page={scene.pageLength}
                                          ></PageToEighth>
                                        </td>
                                        <td>
                                          {Math.round(scene.shootDays * 10) / 10}
                                        </td>
                                        <td>
                                          {formatTime(scene.estimatedShootingDuration)}
                                        </td>
                                        <td>
                                          {(scene.characters || []).join(", ")}
                                        </td>
                                        <td>{scene.scriptDay}</td>
                                      </tr>
                                    </>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                              <tr style={{ backgroundColor: "#162135" }}>
                                <td style={{ borderBottomWidth: 0 }} colSpan={11}></td>
                              </tr>
                            </tbody>
                          )}
                        </Droppable>
                      )}
                    </Draggable>
                  ))}
                </table>
              </div>
              <div className="column is-narrow">{!readOnly && <ScenesWithoutDays scenes={scenesWithoutDays} />}</div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="sticky-buttons">
        <div className="buttons ">
          {!readOnly && <> <button
            type="submit"
            className={`button ${OnboardingStep4ClassName}`}
            onClick={() => addShootingDay()}
          >
            Add Day
          </button>
            <button
              type="submit"
              className={`button ${OnboardingStep8ClassName}`}
              onClick={() => onAddScenesShootingOrderDays(days)}
            >
              Save
            </button>
          </>}

          <Download
            progress={progress}
            clearPdf={clearPdf}
            download={download}
            downloadFile={onDownloadFile}
          ></Download>

        </div>
      </div>
    </>
  );
};

export default DaysList;
