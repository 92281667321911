import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import useWindowSize from '../shared/window-size';
import BulletPoints from "./bullet-points";
import Timeline from "./timeline";
import Nav from './nav';
import CallToAction from "./call-to-action";
import Features from "./features";
import BottomSection from "./bottom-section";
import "./index.scss";
import CookieConsent from "../shared/cookie-consent";
import { connect } from "react-redux";
import { fetchPrices, logError } from "./ducks/actions";
import TrustPilot from "./trust-pilot";
import invitations from "../shared/invitations";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../shared/error-fallback";
import { useLocation } from "react-router-dom";

const Marketing = ({ fetchPrices, logError, prices, currencySymbol, match }: any) => {
  const { referralCode } = match.params;

  const windowSize: any = useWindowSize();
  const windowWidth = windowSize.width;
  const options = { threshold: 1, rootMargin: `${windowWidth > 936 ? "50" : "20"}px 0px 0px 0px`, initialInView: true };
  const [titleRef, titleInView] = useInView(options);
  const location = useLocation();
  const ref = new URLSearchParams(location.search).get('ref');

  useEffect(() => {
    fetchPrices();
  }, []);

  useEffect(() => {
    if (referralCode) {
      invitations.addReferralCode(referralCode);
    }
  }, [referralCode]);

  useEffect(() => {
    if (ref) {
      localStorage.setItem("ref", ref);
    }
  }, [ref]);

  const onLogError = (error: Error, info) => {
    logError("marketing", error.message, error.stack, info.componentStack);
  };

  return (
    <div className="marketing-background">
      <ErrorBoundary fallback={ErrorFallback}
        onError={onLogError}>
        <Nav tranparent={titleInView} />
        <div className="marketing-container">
          <div className="marketing">
            <CallToAction titleRef={titleRef} windowWidth={windowWidth} />
            <Features windowWidth={windowWidth} />
            <BulletPoints />
            <TrustPilot />
            <Timeline />

            <BottomSection currencySymbol={currencySymbol} prices={prices} />
          </div>

          <CookieConsent />
        </div>
      </ErrorBoundary>
    </div>
  );
};

function mapStateToMarketing(state) {
  const { marketingState } = state;
  return {
    prices: marketingState.prices,
    currencySymbol: marketingState.currencySymbol
  };
}

export default
  connect(mapStateToMarketing, {
    fetchPrices,
    logError
  })(Marketing);