import React from "react";
import { Prompt } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import Errors from "./../../shared/errors";
import {
  renderDateField,
  renderTextAreaField,
  renderTextField,
  renderTimeField
} from "./../../shared/form-helpers";
import required from "./../../shared/validation";
import { connect } from "react-redux";
import { timeDiffMinutes, formatTimeFromMinutes } from "../../shared/format-time";

let CrewShootingDayForm = (props) => {
  const {
    handleSubmit,
    handleDelete,
    pristine,
    reset,
    submitting,
    errors,
    mode,
    showNumberField,
    crewCallValue,
    estimatedWrapValue
  } = props;
  const thisShootingDay = props.initialValues || {};
  const readOnly = thisShootingDay.readOnly;

  const dayLengthMinutes = timeDiffMinutes(crewCallValue, estimatedWrapValue);

  return (
    <>
      <Prompt when={!pristine} message="" />
      {mode === "add" && <h2 className="menu-label">Add Shooting Day</h2>}
      {mode === "edit" && <h2 className="menu-label">Edit Shooting Day</h2>}
      {errors && <Errors errors={errors} />}
      <form autoComplete="off" onSubmit={handleSubmit}>

        <div className="columns">
          <div className="column">

            <Field
              name="date"
              label="Date"
              component={renderDateField}
              readOnly={readOnly}
            />

          </div>
          <div className="column">
            {showNumberField && <Field
              name="number"
              label="Number"
              component={renderTextField}
              type="number"
              readOnly={readOnly}
            />}
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Field
              name="crewCall"
              label="Crew Call"
              component={renderTimeField}
              canAddDay={false}
              type="time"
              step="300"
              validate={[required]}
              required
              readOnly={readOnly}
            />
          </div>
          <div className="column">
            <Field
              name="actorCall"
              label="Actor Call"
              component={renderTimeField}
              type="time"
              step="300"
              validate={[required]}
              required
              readOnly={readOnly}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Field
              name="shootingCall"
              label="Shooting Call"
              component={renderTimeField}
              type="time"
              step="300"
              validate={[required]}
              required
              readOnly={readOnly}
            />
          </div>
          <div className="column">
            <Field
              name="estimatedWrap"
              label="Estimated Wrap"
              component={renderTimeField}
              type="time"
              step="300"
              validate={[required]}
              required
              readOnly={readOnly}
            />
          </div>
        </div>

        <div className="mb-1">
          Day Length: {formatTimeFromMinutes(dayLengthMinutes)}
        </div>
        <Field name="notes" label="Notes" component={renderTextAreaField} readOnly={readOnly} />

        {!readOnly && <div className="buttons">
          <button
            type="submit"
            className="button is-primary"
            disabled={pristine || submitting}>
            Save
          </button>

          {mode === "edit" && (
            <button
              type="button"
              className="button is-text"
              disabled={submitting || !props.initialValues}
              onClick={() => handleDelete(thisShootingDay)}>
              Delete
            </button>
          )}
        </div>}
      </form>
    </>
  );
}

const form = "crewShootingDayForm";

CrewShootingDayForm = reduxForm({
  form: form,
  enableReinitialize: true
})(CrewShootingDayForm);

const selector = formValueSelector(form);

const ShootingDaySettingsFormConnect = connect(state => {
  const crewCallValue = selector(state, 'crewCall');
  const estimatedWrapValue = selector(state, 'estimatedWrap');

  return {
    crewCallValue,
    estimatedWrapValue
  }
})(CrewShootingDayForm);


export default ShootingDaySettingsFormConnect;