import React, { useState } from "react";
import { Prompt } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from 'react-redux'
import Images from "../../../shared/images";
import Errors from "./../../../shared/errors";
import {
  renderCurrencyField,
  renderSelectField,
  renderSingleReactSelect,
  renderTextAreaField,
  renderTextField,
  renderUrlField
} from "./../../../shared/form-helpers";
import required, { maxDecimalValue } from "../../../shared/validation";
import FormErrors from "../../../shared/form-errors";

let SetDressingForm = (setDressings) => {
  const {
    handleSubmit,
    handleDelete,
    onChangeSetDressingToProp,
    pristine,
    reset,
    submitting,
    errors,
    acquisitionMethodEnum,
    mode,
    acquisitionMethodValue,
    submitFailed,
    invalid,
    allSceneSettings,
    filmId
  } = setDressings;

  const thisSetDressing = setDressings.initialValues || {};
  const readOnly = thisSetDressing.readOnly;

  const [editUrl, setEditUrl] = useState(false);

  const acquisitionMethodOptions = (acquisitionMethodEnum || []).map((item) => { return { value: item.value, label: item.name } });

  const sceneSettingOptions = (allSceneSettings || []).map((item) => { return { value: item.id, label: item.name } });

  return (
    <>
      <Prompt when={!pristine} message="" />
      {mode === "add" && <h2 className="menu-label">Add Set Dressing</h2>}
      {mode === "edit" && <h2 className="menu-label">Edit Set Dressing</h2>}
      <div className="columns">
        <div className="column">
          {errors && <Errors errors={errors} />}
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Field
              name="name"
              label="Name"
              component={renderTextField}
              validate={[required]}
              required
              readOnly={readOnly}
            />

            <Field
              name="sceneSettingId"
              label="Scene Setting"
              component={renderSingleReactSelect}
              options={sceneSettingOptions}
              validate={[required]}
              required
              readOnly={readOnly}>
            </Field>

            <Field
              name="link"
              label="Link"
              type="url"
              component={renderUrlField}
              readOnly={readOnly}
              setEditUrl={setEditUrl}
              editUrl={editUrl}
            />

            <Field
              name="description"
              label="Description"
              component={renderTextAreaField}
              readOnly={readOnly}></Field>

            <Field
              name="acquisitionMethod"
              label="Acquisition Method"
              component={renderSingleReactSelect}
              options={acquisitionMethodOptions}
              validate={[required]}
              required
              readOnly={readOnly}>
            </Field>

            {acquisitionMethodValue != 3 && acquisitionMethodValue != 4 &&
              <Field
                name="buyBudget"
                label="Budget"
                component={renderCurrencyField}
                validate={[maxDecimalValue]}
                readOnly={readOnly}></Field>
            }
            {acquisitionMethodValue == 3 &&
              <Field
                name="dailyHireBudget"
                label="Daily Budget"
                component={renderCurrencyField}
                validate={[maxDecimalValue]}
                readOnly={readOnly}></Field>
            }
            <div className="field-body">
              <Field
                name="quantity"
                type="number"
                label="Quantity Required"
                component={renderTextField}
                parse={(value) => Number(value)}
                validate={[required, maxDecimalValue]}
                required
                readOnly={readOnly}
              />
              {acquisitionMethodValue != 4 &&
                <Field
                  name="quantityAcquired"
                  type="number"
                  label="Quantity Acquired"
                  parse={(value) => Number(value)}
                  component={renderTextField}
                  validate={[maxDecimalValue]}
                  readOnly={readOnly}
                />
              }
            </div>

            {!readOnly && <div className="buttons mt-2">
              <button
                type="submit"
                className="button is-primary"
                disabled={(pristine || submitting) && !thisSetDressing.enableSave}>
                Save
              </button>
              {mode === "edit" && (
                <>
                  <button
                    type="button"
                    className="button is-text"
                    disabled={submitting || !setDressings.initialValues}
                    onClick={() => handleDelete(thisSetDressing)}>
                    Delete Set Dressing
                  </button>

                  <button type="button"
                    className="button is-text"
                    disabled={submitting || !setDressings.initialValues}
                    onClick={() => onChangeSetDressingToProp(thisSetDressing)}>Change to Prop</button>
                </>
              )}
            </div>}
            <FormErrors submitFailed={submitFailed} invalid={invalid} />
          </form>
        </div>
        <div className="column">
          <Images change={setDressings.change} initialValues={setDressings.initialValues} readOnly={readOnly} filmId={filmId} />
        </div>
      </div>
    </>
  );
}

SetDressingForm = reduxForm({
  form: "editSetDressingForm",
  enableReinitialize: true
})(SetDressingForm);

// Decorate with connect to read form values
const selector = formValueSelector('editSetDressingForm'); // <-- same as form name
const SetDressingFormConnect = connect(
  state => {
    // can select values individually
    const acquisitionMethodValue = selector(state, 'acquisitionMethod')

    return {
      acquisitionMethodValue
    }
  }
)(SetDressingForm)

export default SetDressingFormConnect
