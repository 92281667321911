import { useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";

import DaysOfWeek from "../shooting-days/calendar/days-of-week";
import Radio from "../../shared/filmanize-radio";
import confirm from "../../shared/modal/confirm";
import confirmPromise from "../../shared/modal/confirm-promise";
import { Prompt, useHistory } from "react-router-dom";
import { OnboardingStep1ClassName, OnboardingStep2ClassName, OnboardingStep3ClassName, OnboardingStep4ClassName } from "./ducks/reducer";
import './potential-dates.scss';
import Onboarding from "../../shared/onboarding/onboarding";

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // A function that increment 👆🏻 the previous state like here 
  // is better than directly setting `setValue(value + 1)`
}

const Scheduling = ({ daysOfWeek,
  changeDayOfWeek,
  days,
  readOnly,
  updateEvent,
  addDays,
  deleteDay,
  deleteDays,
  saveDays,
  pristine,
  onboardingSteps,
  filmId,
  setPotentialDaysWhereLocationsAvailable }) => {

  const cal = useRef<any>();
  const [mode, setMode] = useState("add");
  const addMode = "add";
  const deleteMode = "delete";
  const forceUpdate = useForceUpdate();
  const history = useHistory();

  const workingDays = daysOfWeek.filter(d => d.selected).map(d => +d.dayOfWeek);

  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const dayRender = ({ date, el }) => {
    if (date < today) { //|| !daysOfWeek[date.getDay()].selected
      el.style.backgroundColor = '#040f21'
    }
  };

  const handleEventChange = (changeInfo) => {
    updateEvent(changeInfo.event.toPlainObject());
  };

  const dateSelect = (dateInfo) => {
    // if all day event, then end date is +1
    const endDate = dateInfo.end.setDate(dateInfo.end.getDate() - 1)
    if (mode === addMode) {
      addDays(dateInfo.start, endDate);
      cal.current.getApi().unselect();
    } else if (mode === deleteMode) {
      const momentStartDay = moment(dateInfo.start);
      const momentEndtDay = moment(endDate);
      // check to see if there are any dates in the range

      confirmPromise(() => deleteDays(dateInfo.start, endDate), null, `Are you sure you want to delete days between ${momentStartDay.format("Do MMMM YYYY")} and ${momentEndtDay.format("Do MMMM YYYY")}?`)
        .then((value) => {
          if (value) {
            deleteDays(dateInfo.start, endDate)
          }

          cal.current.getApi().unselect();
        });
    }
  };

  const onChangeDayOfWeek = (dayOfWeek, checked) => {
    changeDayOfWeek(dayOfWeek, checked);
    forceUpdate();
  };

  const selectAllow = (selectInfo) => {
    if (readOnly) {
      return false;
    }

    if (mode === addMode && selectInfo.start < today) {
      return false;
    } else if (selectInfo.start < today) {
      return days.find((d) => d.start.toISOString() === selectInfo.start.toISOString());
    } else {
      return workingDays.includes(selectInfo.start.getDay());
    }
  };

  const eventAllow = (dropInfo, draggedEvent) => {
    if (readOnly) {
      return false;
    }

    if (dropInfo.start < today) {
      return false;
    }

    // if dropInfo.start is the same as a day that is not editable, return false
    // and not the day we are dragging
    //dropInfo.start.toISOString() == days[1].start.toISOString() 
    var dayOnThisDay = days.find((d) => d.start.toISOString() === dropInfo.start.toISOString() && d.id != draggedEvent.id);

    if (dayOnThisDay) {
      //if (dayOnThisDay.hasSent) {
      return false;
      //  }
    }

    const dayIndex = days.findIndex((d) => d.id === draggedEvent.id);
    if (days[dayIndex].hasSent) {
      return false;
    }

    return true;
  };

  const eventMouseClick = (info) => {
    const { event, jsEvent } = info;
    const day = days.find((d) => d.id === event.id);
    if (!day) {
      return;
    }

    if (day.isLocation) {
      history.push(`/films/${filmId}/locations/${event.id}/edit#availability`);
      return;
    }

    if (!day.editable) {
      return;
    }

    //const { clientX, clientY } = jsEvent;
    const momentDay = moment(day.start);

    // check not sent

    confirm(() => deleteDay(event.id), null, `Are you sure you want to delete day ${momentDay.format("Do MMMM YYYY")}?`)

    //deleteDay(event.id);

  };

  const onSetMode = (mode) => {
    setMode(mode);
  }

  const hasLocations = days.findIndex(d => d.isLocation) > -1;

  function renderEventContent(eventInfo) {
    const allDay = eventInfo.event.allDay;

    const startDate = moment(eventInfo.event.start);
    const endDate = moment(eventInfo.event.end);
    const formattedStartDate = startDate.format('HH:mm');
    const formattedEndDate = endDate.format('HH:mm');

    const title = eventInfo.event.title;
    let timeAndTitle = formattedStartDate + " - " + formattedEndDate + " " + title;

    const day = days.find(d => d.id == eventInfo.event.id);
    let userEvent = true;
    let available = false;
    if (day) {
      userEvent = day.userEvent;
      available = day.available;
    }

    const title1 = title;

    return (
      <>
        {allDay && <div className={`fc-event-title fc-sticky`} title={title1}>{title1}</div>}
        {!allDay && <>
          <div className="fc-daygrid-event-dot" title={timeAndTitle} ></div>
          <div className="fc-event-time" title={timeAndTitle}>{formattedStartDate}</div>
          <div className="fc-event-title" title={timeAndTitle}>{title}</div>
        </>}
      </>
    )
  }

  return (
    <div className="potential-dates">
      <Prompt when={!pristine} message="" />
      <div className={`calendar-working-days ${OnboardingStep1ClassName}`}>
        <p className="mr-1">Working Days:</p>
        <DaysOfWeek daysOfWeek={daysOfWeek} readOnly={readOnly} onChange={onChangeDayOfWeek} />
      </div>
      <div className={`mode-selection-container ${OnboardingStep2ClassName}`}>
        <p className="mr-1 mode-selection">Selection:</p>
        <div style={{ verticalAlign: "center" }}>
          <Radio
            value={addMode}
            checked={mode === addMode}
            text={"Add"}
            onChange={onSetMode}
          />
        </div>
        <Radio
          value={deleteMode}
          checked={mode === deleteMode}
          text={"Delete"}
          onChange={onSetMode}
        />
      </div>
      <div>
        {hasLocations &&
          <button className="button" onClick={setPotentialDaysWhereLocationsAvailable}>Set potential days where locations available</button>
        }
      </div>
      <div className={OnboardingStep3ClassName}>
        <FullCalendar
          ref={cal}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          eventColor="#00ffce"
          eventTextColor="#040f21"
          events={days}
          editable={true}
          selectable={true}
          eventOverlap={true}
          firstDay={1}
          selectMirror={true}
          eventConstraint={{
            daysOfWeek: daysOfWeek
              .filter((d) => d.selected)
              .map((d) => d.dayOfWeek)
          }}
          contentHeight={"auto"}
          eventChange={handleEventChange}
          select={dateSelect}
          eventAllow={eventAllow}
          selectAllow={selectAllow}
          eventClick={eventMouseClick}
          eventStartEditable={!readOnly}
          showNonCurrentDates={true}
          dayCellDidMount={dayRender}
          // eventContent={renderEventContent}
          businessHours={{
            daysOfWeek: workingDays,
            startTime: '08:00',
            endTime: '18:00'
          }}
          dayHeaderClassNames="calendar-header"
          eventOrder="-isLocation,title"
          timeZone="UTC"
        />
      </div>
      <div className='mt-1'>
        Key:
        <span className="tag tag-key tag-available">Filming</span>
        {hasLocations && <span className="tag tag-key tag-location">Location</span>}
      </div>
      <Onboarding onboardingSteps={onboardingSteps} section="Potential Dates" />
      <div className={`buttons ${OnboardingStep4ClassName}`}>
        <button className="button mt-1" onClick={saveDays} disabled={pristine}>Save</button>
      </div>
    </div>
  );
};


export default Scheduling;
