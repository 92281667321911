import React, { useEffect, useState } from "react";
import { NavLink, Prompt } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import FormErrors from "../../shared/form-errors";
import Errors from "./../../shared/errors";
import {
  renderCheckboxGroup,
  renderFileInputField,
  renderHiddenInput,
  renderImage,
  renderMilkTypesCheckboxGroup,
  renderSelectField,
  renderSingleReactSelect,
  renderTextAreaField,
  renderTextField,
  renderUrlField
} from "./../../shared/form-helpers";
import Images from "./../../shared/images";
import required from "./../../shared/validation";
import { HeightUnitName, WeightUnitName } from '../../shared/unit-names';

let BackgroundActorForm = (props) => {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    dietaryRequirementEnum,
    genderEnum,
    ethnicityTypes,
    errors,
    warnings,
    imageFiles,
    onAddImageFiles,
    onAddImageUrl,
    onDeleteImageUrl,
    onDeleteImageFile,
    mainImageUrl,
    onMainImageUrlChanged,
    onMandyUrlChanged,
    onStarNowUrlChanged,
    handleDelete,
    mode,
    addFromSource,
    submitFailed,
    invalid,
    filmId,
    drinks,
    milkTypes,
    selectedDrinks,
    selectedDietaryRequirements
  } = props;

  let thisActor = props.initialValues || {};
  const readOnly = thisActor.readOnly;
  const addFromMandy = addFromSource == "mandy";
  const addFromStarNow = addFromSource == "starnow";
  let externalSourceUrlParsed = !addFromMandy && !addFromStarNow;

  if (
    !externalSourceUrlParsed &&
    (thisActor.mandyResponse || thisActor.starNowResponse)
  ) {
    externalSourceUrlParsed = true;
  }

  if (addFromMandy && externalSourceUrlParsed) {
    thisActor.importedFromMandy = true;
  }

  if (addFromStarNow && externalSourceUrlParsed) {
    thisActor.importedFromStarNow = true;
  }

  const [mainImageIndex, setMainImageIndex] = useState(0);
  const [mandyUrl, setMandyUrl] = useState();
  const [starNowUrl, setStarNowUrl] = useState();

  const [editMandyUrl, setEditMandyUrl] = useState(false);
  const [editStarNowUrl, setEditStarNowUrl] = useState(false);

  const genderOptions = (genderEnum || []).map((item) => { return { value: item.value, label: item.name } });
  const ethnicityOptions = ethnicityTypes?.map((e) => {
    return { label: e.name, value: e.id };
  });

  const drinkOptions = (drinks || []).map((item) => { return { value: item.id, name: item.name } });
  const milkTypeOptions = (milkTypes || []).map((item) => { return { value: item.id, name: item.name, vegan: item.vegan } });

  let showMilk = false;
  if (selectedDrinks) {
    selectedDrinks.forEach(drinkId => {
      const drink = drinks.find(d => d.id === drinkId);
      if (drink) {
        if (drink.canHaveMilk) {
          showMilk = true;
        }
      }
    });
  }

  const showDairyMilk = !selectedDietaryRequirements?.some(r => r === 2 || r === 8);

  function handleMandyUrlChange(e) {
    setMandyUrl(e.target.value);
  }

  function onParseMandyUrlClick(e) {
    onMandyUrlChanged(mandyUrl);
    e.preventDefault();
  }

  function handleStarNowUrlChange(e) {
    setStarNowUrl(e.target.value);
  }

  function onParseStarNowUrlClick(e) {
    onStarNowUrlChanged(starNowUrl);
    e.preventDefault();
  }

  useEffect(() => {
    const mandyResponse = thisActor.mandyResponse;
    if (mandyResponse) {
      updateBackgroundActorExternalDataResponse(mandyResponse);
    }

    const starNowResponse = thisActor.starNowResponse;
    if (starNowResponse) {
      updateBackgroundActorExternalDataResponse(starNowResponse);
    }

    function updateBackgroundActorExternalDataResponse(response) {
      props.change("firstName", response.firstName);
      props.change("lastName", response.lastName);
      props.change("gender", response.gender);
      props.change("heightCentimeters", response.height);
      props.change("weightKilograms", response.weight);
      props.change("ethnicityId", response.ethnicity);

      if (response.mainImageUrl) {
        props.change("mainImageUrl", response.mainImageUrl);
        props.change("imageUrls", [response.mainImageUrl]);

        thisActor.mainImageUrl = response.mainImageUrl;
        thisActor.imageUrls = [response.mainImageUrl];
      }

      if (response.imageUrls) {
        props.change("imageUrls", [
          response.mainImageUrl,
          ...response.imageUrls
        ]);
        thisActor.imageUrls = [response.mainImageUrl, ...response.imageUrls];
      }

      addPictures();

      function addPictures() {
        let pictures = (response.imageUrls || []).map((img) => {
          return {
            url: img,
            isPrimary: false,
            isUploaded: true
          };
        });

        pictures.unshift({
          url: response.mainImageUrl,
          isPrimary: true,
          isUploaded: true
        });

        props.change("pictures", pictures);
      }
    }
  }, [thisActor.mandyUrl, thisActor.starNowUrl]);

  const parseUrlButtonClassName = thisActor.parsingUrl
    ? "button is-loading"
    : "button";

  const dateFormatter = (dateString) => {
    return dateString?.split("T")[0];
  };

  const emailRequired = thisActor.backgroundCharacters && thisActor.backgroundCharacters.length > 0;

  return (
    <>
      <Prompt when={!pristine} message="" />
      {mode === "add" && <h2 className="menu-label">Add Actor</h2>}
      {mode === "edit" && <h2 className="menu-label">Edit Actor</h2>}
      <div className="columns">
        <div className="column">
          {errors && <Errors errors={errors} />}
          {warnings && <Errors errors={warnings} isWarning={true} />}

          <form autoComplete="off" onSubmit={handleSubmit}>
            {!externalSourceUrlParsed && addFromMandy && (
              <>
                <Field
                  name="sourceMandyUrl"
                  label="Mandy.com profile"
                  component={renderTextField}
                  onChange={handleMandyUrlChange}
                />
                <button
                  className={parseUrlButtonClassName}
                  onClick={onParseMandyUrlClick}>
                  Go
                </button>
              </>
            )}

            {!externalSourceUrlParsed && addFromStarNow && (
              <>
                <Field
                  name="sourceStarNowUrl"
                  label="Starnow profile"
                  component={renderTextField}
                  onChange={handleStarNowUrlChange}
                />
                <button
                  className={parseUrlButtonClassName}
                  onClick={onParseStarNowUrlClick}>
                  Go
                </button>
              </>
            )}

            {externalSourceUrlParsed && (
              <>
                <div className="columns">
                  <div className="column">
                    <Field
                      name="firstName"
                      label="First Name"
                      component={renderTextField}
                      validate={[required]}
                      required
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="column">
                    <Field
                      name="lastName"
                      label="Last Name"
                      component={renderTextField}
                      validate={[required]}
                      required
                      readOnly={readOnly}
                    />
                  </div>
                </div>

                <div className="columns">
                  <div className="column">
                    <Field
                      name="gender"
                      label="Gender"
                      component={renderSingleReactSelect}
                      options={genderOptions}
                      validate={[required]}
                      required
                      readOnly={readOnly}>
                    </Field>
                  </div>
                  <div className="column">
                    <Field
                      name="dateOfBirth"
                      label="Date of birth"
                      type="date"
                      format={dateFormatter}
                      component={renderTextField}
                      readOnly={readOnly}
                    />
                  </div>
                </div>

                <div className="columns">
                  <div className="column">
                    <Field
                      name="height"
                      label={`Height (${HeightUnitName(thisActor.heightUnit)})`}
                      component={renderTextField}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="column">
                    <Field
                      name="weight"
                      label={`Weight (${WeightUnitName(thisActor.weightUnit)})`}
                      component={renderTextField}
                      readOnly={readOnly}
                    />
                  </div>
                </div>

                <div className="columns">
                  <div className="column">
                    <Field
                      name="ethnicityId"
                      label="Ethnicity"
                      component={renderSingleReactSelect}
                      includeEmpty={true}
                      options={ethnicityOptions}
                      readOnly={readOnly}>
                    </Field>
                  </div>
                  <div className="column">
                  </div>
                </div>

                <div className="columns">
                  <div className="column">
                    <Field
                      name="emailAddress"
                      label="Email"
                      component={renderTextField}
                      readOnly={readOnly}
                      required={emailRequired}
                      validate={emailRequired && [required]}
                    />
                  </div>
                  <div className="column">
                    <Field
                      name="phoneNumber"
                      label="Phone"
                      component={renderTextField}
                      readOnly={readOnly}
                    />
                  </div>
                </div>

                <Field
                  name="description"
                  label="Description"
                  component={renderTextAreaField}
                  readOnly={readOnly}
                />
                <Field
                  name="mandyUrl"
                  label="Mandy profile"
                  placeholder="https://www.mandy.com/"
                  component={renderUrlField}
                  editUrl={editMandyUrl}
                  setEditUrl={setEditMandyUrl}
                  readOnly={readOnly}
                  disabled={
                    (addFromMandy && externalSourceUrlParsed) ||
                    thisActor.importedFromMandy
                  }
                />

                <Field name="importedFromMandy" component={renderHiddenInput} readOnly={readOnly} />

                <Field
                  name="starNowUrl"
                  label="Starnow profile"
                  placeholder="https://www.starnow.com/"
                  component={renderUrlField}
                  editUrl={editStarNowUrl}
                  setEditUrl={setEditStarNowUrl}
                  readOnly={readOnly}
                  disabled={
                    (addFromStarNow && externalSourceUrlParsed) ||
                    thisActor.importedFromStarNow
                  }
                />

                <Field name="importedFromStarNow" component={renderHiddenInput} />

                <Field
                  label="Dietary requirements"
                  name="dietaryRequirementsArray"
                  component={renderCheckboxGroup}
                  options={dietaryRequirementEnum}
                  value={thisActor.dietaryRequirement}
                  readOnly={readOnly}
                />

                <Field
                  name="dietaryRequirementDetails"
                  label="Dietary Requirement Details"
                  component={renderTextAreaField}
                  readOnly={readOnly}
                />

                <Field
                  label="Drinks"
                  name="drinkIds"
                  component={renderCheckboxGroup}
                  options={drinkOptions}
                  readOnly={readOnly}
                />
                {showMilk &&
                  <Field
                    label="Milk Types"
                    name="milkTypeIds"
                    component={renderMilkTypesCheckboxGroup}
                    showDairy={showDairyMilk}
                    options={milkTypeOptions}
                    readOnly={readOnly}
                  />
                }

                {(thisActor.backgroundCharacters && thisActor.backgroundCharacters.length > 0) &&
                  <div className="field">
                    <label className="label">
                      Background Characters
                    </label>
                    {(thisActor.backgroundCharacters || []).map((character, index) => (
                      <div key={index}>
                        <NavLink
                          to={`/films/${filmId}/background-characters/${character.id}/edit`}
                        >
                          {character.name}
                        </NavLink>
                      </div>
                    ))}
                  </div>
                }
                {!readOnly && <div className="buttons">
                  <button
                    type="submit"
                    className={
                      submitting
                        ? "button is-primary is-loading"
                        : "button is-primary"
                    }
                    disabled={pristine || submitting}>
                    Save
                  </button>
                  <button
                    type="button"
                    className="button is-secondary"
                    disabled={pristine || submitting}
                    onClick={reset}>
                    Reset Values
                  </button>
                  {mode === "edit" && (
                    <button
                      type="button"
                      className="button is-text"
                      disabled={submitting || !props.initialValues}
                      onClick={() => handleDelete(thisActor)}>
                      Delete
                    </button>
                  )}
                </div>}
                <FormErrors submitFailed={submitFailed} invalid={invalid} />
              </>
            )}
          </form>
        </div>
        <div className="column">
          <Images change={props.change} initialValues={props.initialValues} readOnly={readOnly} filmId={filmId} />
        </div>
      </div>
    </>
  );
}

BackgroundActorForm = reduxForm({
  form: "backgroundActorForm",
  enableReinitialize: true
})(BackgroundActorForm);

// Decorate with connect to read form values
const selector = formValueSelector('backgroundActorForm'); // <-- same as form name
const BackgroundActorFormConnect = connect(
  state => {
    // can select values individually
    const selectedDrinks = selector(state, 'drinkIds')
    const selectedDietaryRequirements = selector(state, 'dietaryRequirementsArray')

    return {
      selectedDrinks,
      selectedDietaryRequirements
    }
  }
)(BackgroundActorForm)

export default BackgroundActorFormConnect;