import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";
import {
  updateChangeLog,
  fetchChangeLog,
  clearRedirect,
  sendChangeLog
} from "./ducks/actions";
import Form from "./form";

function EditChangeLog(props) {  
  const { updateChangeLog,    
    errors,
    changeLog,
    fetchChangeLog,
    loading,
    redirect,
    clearRedirect,
    sendChangeLog
     } = props;

  const changeLogId = props.match.params.changeLogId;

  useEffect(() => {
    fetchChangeLog(changeLogId);    
  }, []);

  if (redirect) {
    if (redirect.to === "list") {
      clearRedirect();
      const url = `/admin/change-logs`;
      return <Redirect to={url} />;
    } 
  }

  function onUpdateChangeLog(changeLog) {
    updateChangeLog(changeLogId, changeLog);    
  }

  function onSendChangeLog(changeLog) {
    sendChangeLog(changeLogId, changeLog, true);
  }

  function onNotWorthSendingChangeLog(chnageLog) {
    sendChangeLog(changeLogId, changeLog, false);
  }

  return (
    <ScrollIntoView
      className="blade-content widest"
      path="/admin/change-logs/add"
      loading={false}>
      <Form
        errors={errors}
        costumeId={null}
        initialValues={changeLog}
        loading={loading}
        onSubmit={onUpdateChangeLog}
        sendChangeLog={onSendChangeLog}
        notWorthSendingChangeLog={onNotWorthSendingChangeLog}
        mode="edit"
        changeLogId={changeLogId}
        changeLogChangeTypeEnum={props.changeLogChangeTypeEnum}
        reduxFormValues={props.reduxFormValues}        
      />
    </ScrollIntoView>
  );
}

function mapStateToChangeLogs(state) {
  const { adminChangeLogState, commonState } = state;
  return {
    changeLog: adminChangeLogState.changeLog,
    errors: adminChangeLogState.errors,
    redirect: adminChangeLogState.redirect,
    loading: adminChangeLogState.loading,
    changeLogChangeTypeEnum: commonState.changeLogChangeTypeEnum
  };
}

export default connect(mapStateToChangeLogs, {
  updateChangeLog,
  fetchChangeLog,
  clearRedirect,
  sendChangeLog
})(EditChangeLog);
