import React, { useCallback, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import Loader from "../../shared/loader";
import AnonymousPage from "../anonymous-pages/anonymous-page";
import BoxWraper from "../box-wrapper";
import {
  register,
  getCountries,
  getLanguages,
  getCurrencies,
  clearRedirect,
  clearForm,
  clearRegistered
} from "./ducks/actions";
import Form from "./form";

import getConfig from "../../app/config";
import invitations from "../../shared/invitations";

function Register(props) {
  const {
    register,
    getCountries,
    countries,
    defaultCountryId,
    getLanguages,
    languages,
    defaultLanguageId,
    getCurrencies,
    currencies,
    loadingRegister,
    loadingLanguages,
    loadingCountries,
    loadingCurrencies,
    errors,
    user,
    clearForm,
    registered,
    referralCode,
    clearRegistered
  } = props;

  const pricingPlan = props.match.params.pricingPlan ? +props.match.params.pricingPlan : 0;

  useEffect(() => {
    clearForm();
    getCountries();
    getLanguages();
    getCurrencies();
  }, []);

  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async (user) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('register');

    await onRegister(user, token);

  }, [executeRecaptcha]);

  


  if (registered) {
    clearRegistered();
    return <Redirect to={`/confirmation/${pricingPlan}`} />;
  }

  const onRegister = async (user, reCaptchaToken) => {
    const referralCode = invitations.getReferralCode();
    const userWithPricingPlan = { ...user, pricingPlan, referralCode, reCaptchaToken }
    register(userWithPricingPlan);
  }

  const config = getConfig();

  return (
    <AnonymousPage>
      <BoxWraper width={5}>
        {(loadingRegister || loadingLanguages || loadingCountries || loadingCurrencies) && <Loader />}
        {!(loadingRegister || loadingLanguages || loadingCountries || loadingCurrencies) && <>
          {!registered && <Form
            errors={errors}
            loading={false}
            initialValues={user}
            onSubmit={handleReCaptchaVerify}
            countries={countries}
            defaultCountryId={defaultCountryId}
            languages={languages}
            defaultLanguageId={defaultLanguageId}
            currencies={currencies}
            showReferrer={referralCode == null}
            {...props}
          />}
          {registered && <div>
            <p>Thank you for registering with Filmanize. You will shortly receive an email in which you will need to click to confirm your email address.</p>
            <p>During the beta phase, your account will need to be approved by one of the Filmanize team before use. We'll send you an email once your account has been approved, and then you'll be ready to go!</p>
            <p><Link to={'./login'}>Login</Link></p>
          </div>}
        </>}
      </BoxWraper>
    </AnonymousPage>
  );
}

function mapStateToProps(state) {
  const { accountState, commonState } = state;
  return {
    countries: accountState.registerState.countries,
    defaultCountryId: accountState.registerState.defaultCountryId,
    languages: accountState.registerState.languages,
    defaultLanguageId: accountState.registerState.defaultLanguageId,
    currencies: accountState.registerState.currencies,
    user: accountState.registerState.user,
    errors: accountState.registerState.errors,
    loadingRegister: accountState.registerState.loadingRegister,
    loadingLanguages: accountState.registerState.loadingLanguages,
    loadingCountries: accountState.registerState.loadingCountries,
    loadingCurrencies: accountState.registerState.loadingCurrencies,
    redirect: accountState.registerState.redirect,
    registered: accountState.registerState.registered,
    referralCode: accountState.registerState.referralCode,
    ref: accountState.registerState.ref
  };
}

export default connect(mapStateToProps, {
  clearForm,
  register,
  getCountries,
  getLanguages,
  getCurrencies,
  clearRedirect,
  clearRegistered
})(Register);
