import React from "react";
import { PrivateRoute } from "../../shared/private-route";
import AddCharacter from "./add";
import EditCharacter from "./edit";
import ListCharactersContainer from "./list-container";
import OverviewContainer from "./overview/container";
import CharacterMenu from "./menu";
import CharacterScenes from "./scenes/scenes";

const CharacterRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/characters"
        component={ListCharactersContainer}
      />
      <PrivateRoute path="/films/:filmId/characters/:characterId" component={CharacterMenu} />
      <PrivateRoute
        exact
        path="/films/:filmId/characters/add"
        component={AddCharacter}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/characters/overview"
        component={OverviewContainer}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/characters/:characterId"
        component={EditCharacter}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/characters/:characterId/edit"
        component={EditCharacter}
      />

      <PrivateRoute
        exact
        path="/films/:filmId/characters/:characterId/scenes"
        component={CharacterScenes}
      />
    </>
  );
};

export default CharacterRoutes;
