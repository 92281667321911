import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../../shared/loader";
import { fetchMakeupsInScene } from "./ducks/actions";
import List from "./list";

function ListMakeupsContainer(props) {
  const { filmId, sceneId } = props.match.params;
  const { fetchMakeupsInScene, loading, sceneMakeups, errors } = props;

  useEffect(() => {
    fetchMakeupsInScene(filmId, sceneId);
  }, []);

  function renderLoading() {
    return <Loader />;
  }

  function renderCharacters(sceneMakeups, filmId, sceneId) {
    return (
      <div className="blade-menu">
        <List sceneMakeups={sceneMakeups} filmId={filmId} sceneId={sceneId} />        
      </div>
    );
  }

  return loading
    ? renderLoading()
    : renderCharacters(sceneMakeups, filmId, sceneId);
}

function mapStateToMakeups(state) {
  return {
    sceneMakeups: state.scenesState.makeupState.makeups,
    loading: state.scenesState.makeupState.loading,
    errors: state.scenesState.makeupState.errors
  };
}

export default connect(mapStateToMakeups, {
  fetchMakeupsInScene
})(ListMakeupsContainer);
