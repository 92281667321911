import React from "react";
import { Prompt } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import FormErrors from "../../shared/form-errors";
import Errors from "./../../shared/errors";
import {
  renderSelectField,
  renderTextAreaField,
  renderTextField,
  renderLocationSelect,
  renderReactSelect,
  renderCurrencyField
} from "./../../shared/form-helpers";
import Images from "./../../shared/images";
import required, { maxDecimalValue } from "./../../shared/validation";

function SceneSettingForm(props) {
  const {
    handleSubmit,
    handleDelete,
    pristine,
    reset,
    submitting,
    genderEnum,
    locationsWithSubLocations,
    mode,
    errors,
    submitFailed,
    invalid,
    filmId
  } = props;
  const thisSceneSetting = props.initialValues || {};
  const readOnly = thisSceneSetting.readOnly;


  const locationOptions = locationsWithSubLocations?.map((e) => {
    return { label: e.name + (e.subLocation ? " - " + e.subLocation.name : ""), value: JSON.stringify({ id: e.id, subLocationId: e.subLocation?.id }) };
  });

  const locationSelectedOptions = thisSceneSetting.locationIds?.map((e) => {
    return locationOptions?.find((x) => x.value === e);
  });

  return (
    <>
      <Prompt when={!pristine} message="" />
      {mode === "add" && <h2 className="menu-label">Add Scene Setting</h2>}
      {mode === "edit" && <h2 className="menu-label">Edit Scene Setting</h2>}
      <div className="columns">
        <div className="column">
          {errors && <Errors errors={errors} />}
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Field
              name="name"
              label="Scene Setting Name"
              component={renderTextField}
              validate={[required]}
              required
              readOnly={readOnly}
            />

            <Field
              name="description"
              label="Description"
              component={renderTextAreaField}
              readOnly={readOnly}
            />

            <Field
              name="dailyBudget"
              label="Daily Budget"
              component={renderCurrencyField}
              validate={[maxDecimalValue]}
              readOnly={readOnly}
            />
            <Field
              name="location"
              label="Location"
              component={renderLocationSelect}
              options={locationsWithSubLocations}
              readOnly={readOnly}
            />

            {/* 
        <Field
          label="Locations"
          name="locations"
          component={renderReactSelect}
          options={locationOptions}
          selectedOptions={locationSelectedOptions}
          readOnly={readOnly}
        /> */}

            {/*
        <Field
          name="location"
          label="Location"
          component={renderSelectField}
          value={JSON.stringify({
            locationId: input.locationId,
            subLocationId: input.subLocationId
          })}
        >
          <option value="" />
          {(locationsWithSubLocations || []).map((item, i) => {
            let location = item.locationName;
            if (item.subLocationName) {
              location += " - " + item.subLocationName;
            }
            return (
              <option
                value={JSON.stringify({
                  locationId: item.locationId,
                  subLocationId: item.subLocationId
                })}
                key={i}
              >
                {location}
              </option>
            );
          })}
        </Field>
        */}


            {!readOnly && <div className="buttons mt-2">
              <button
                type="submit"
                className="button"
                disabled={pristine || submitting}>
                Save
              </button>
              <button
                type="button"
                className="button is-text"
                disabled={pristine || submitting}
                onClick={reset}>
                Reset Values
              </button>
              {mode === "edit" && (
                <button
                  type="button"
                  className="button is-text"
                  disabled={submitting || !props.initialValues}
                  onClick={() => handleDelete(thisSceneSetting)}>
                  Delete
                </button>
              )}
            </div>}
            <FormErrors submitFailed={submitFailed} invalid={invalid} />
          </form>
        </div>
        <div className="column">
          <Images change={props.change} initialValues={props.initialValues} readOnly={readOnly} filmId={filmId} />
        </div>
      </div>
    </>
  );
}

export default reduxForm({
  form: "editSceneSettingForm",
  enableReinitialize: true
})(SceneSettingForm);
