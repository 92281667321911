import React, { useEffect } from "react";
import { connect } from "react-redux";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import Loader from "./../../../shared/loader";
import {
    fetchActorNumbers,
    updateActorOrder,
    saveActorOrder
} from "./ducks/actions";
import Onboarding from '../../../shared/onboarding/onboarding';
import Order from "./order";

const OverviewContainer = (props) => {
    const filmId = props.match.params.filmId;
    const {
        fetchActorNumbers,
        onboardingSteps,
        actors,
        loading,
        errors,
        warnings,
        readOnly,
        updateActorOrder,
        saveActorOrder,
        pristine
    } = props;

    useEffect(() => {
        fetchActorNumbers(filmId);

        return function cleanUp() {
            //clearCharacterOverview();
        };
    }, [filmId]);

    const onSaveActorOrder = () => {        
        saveActorOrder(filmId, actors);
    };

    function renderOverview(actors) {
        return (
            <ScrollIntoView
                className="blade-content"
                path="/films/:filmId/actors/order"
                loading={loading}>
                <h2 className="menu-label">Order</h2>
                <Order
                    actors={actors}
                    filmId={filmId}
                    errors={errors}
                    warnings={warnings}
                    readOnly={readOnly}
                    onboardingSteps={onboardingSteps}
                    updateActorOrder={updateActorOrder}
                    saveActorOrder={onSaveActorOrder}
                    pristine={pristine} />
                <Onboarding onboardingSteps={onboardingSteps} section="Actor Order" />
            </ScrollIntoView>            
        );
    }

    return (
        <>
            {(loading || !actors) && <Loader />}
            {!loading && actors && renderOverview(actors)}
        </>
    );
};

function mapStateToProps(state) {
    const { errors, warnings, actors, loading, onboardingSteps, readOnly,
        pristine } =
        state.actorsState.orderState;
    return {
        errors,
        warnings,
        actors,
        loading,
        onboardingSteps,
        readOnly,
        pristine
    };
}

export default connect(mapStateToProps, {
    fetchActorNumbers,
    updateActorOrder,
    saveActorOrder
})(OverviewContainer);
