import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import {
  addCostume,
  clearCostume,
  clearRedirect,
  fetchAllCharacters,
  fetchAllBackgroundCharacters,
  uploadImagesAndSaveCostume,
  setName
} from "./ducks/actions";
import Form from "./form";

function AddCostume(props) {
  const filmId = props.filmId || props.match.params.filmId;
  const { addCostume,
    costume,
    errors,
    uploadImagesAndSaveCostume,
    setName,
    name,
    embedded,
    fetchAllCharacters,
    fetchAllBackgroundCharacters,
    allCharacters,
    allBackgroundCharacters,
    clearCostume,
    sceneId } = props;

  useEffect(() => {
    clearCostume();
    fetchAllCharacters(filmId);
    fetchAllBackgroundCharacters(filmId);
  }, []);

  useEffect(() => {
    if (name) {
      setName(name);
    }
  }, [name]);

  if (!embedded && props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/costumes/`;
      return <Redirect to={url} />;
    } else if (props.redirect.to === "scenes") {
      props.clearRedirect();
      const url = `/films/${filmId}/costumes/${props.redirect.id}/scenes/`;
      return <Redirect to={url} />;
    }
  }

  function onAddCostume(costume) {
    debugger;
    costume.characterIds = costume.characters?.map((x) => x.value || x);
    costume.backgroundCharacterIds = costume.backgroundCharacters?.map((x) => x.value || x);
    costume.sceneId = sceneId;
    const files = costume.pictures || [];
    if (files && files.length > 0) {
      uploadImagesAndSaveCostume(costume, files, true, filmId);
    } else {
      if (!costume.imageUrls) {
        costume.imageUrls = [];
      }
      addCostume(filmId, costume);
    }
  }

  return (
    <ScrollIntoView
      className="blade-content widest"
      path="/films/:filmId/costumes/add"
      loading={false}>
      <Form
        errors={errors}
        costumeId={null}
        initialValues={costume}
        loading={false}
        onSubmit={onAddCostume}
        mode="add"
        acquisitionMethodEnum={props.acquisitionMethodEnum}
        reduxFormValues={props.reduxFormValues}
        allCharacters={allCharacters}
        allBackgroundCharacters={allBackgroundCharacters}
      />
    </ScrollIntoView>
  );
}

function mapStateToCostumes(state) {
  const { costumesState, commonState } = state;
  return {
    costume: costumesState.costume,
    errors: costumesState.errors,
    redirect: costumesState.redirect,
    acquisitionMethodEnum: commonState.acquisitionMethod,
    allCharacters: costumesState.characters,
    allBackgroundCharacters: costumesState.backgroundCharacters
  };
}

export default connect(mapStateToCostumes, {
  addCostume,
  clearCostume,
  clearRedirect,
  fetchAllCharacters,
  fetchAllBackgroundCharacters,
  uploadImagesAndSaveCostume,
  setName
})(AddCostume);
