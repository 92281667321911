function getConfig() {
  let config = JSON.parse(
    (document.getElementById("configScript") as any).innerHTML
  );

  config.googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY
    ? [process.env.REACT_APP_GOOGLE_API_KEY]
    : config.googleApiKey;

  config.applicationInsightsKey = process.env.REACT_APP_APPLICATION_INSIGHTS_KEY
    ? [process.env.REACT_APP_APPLICATION_INSIGHTS_KEY]
    : config.applicationInsightsKey;

  config.apiUrl = process.env.REACT_APP_API_URL || config.apiUrl;

  config.reCaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY || config.reCaptchaSiteKey;
  config.stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || config.stripePublishableKey;

  return config;
}

export default getConfig;