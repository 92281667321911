import SceneCharacter from "./scene-character";
import CanAccessNavLink from "../../shared/can-access-nav-link";

const Scenes = ({ scenes, filmId, canAccessScenes, canAccessSceneSettings, canAccessCharacters }) => {
  return (
    <table className="table is-fullwidth">
      <thead>
        <tr>
          <th>Number</th>
          <th>Scene Setting</th>
          <th>Int/Ext</th>
          <th>Day/Night</th>
          <th>Characters</th>
        </tr>
      </thead>

      <tbody>
        {(scenes || []).map((scene, i) => (
          <tr key={i}>
            <td>
              <CanAccessNavLink to={`/films/${filmId}/breakdown/scenes/${scene.id}`} canAccess={canAccessScenes}>
                {scene.number}
              </CanAccessNavLink>
            </td>
            <td>
              <CanAccessNavLink
                to={`/films/${filmId}/scene-settings/${scene.sceneSetting.id}`} canAccess={canAccessSceneSettings}>
                {scene.sceneSetting.name}
              </CanAccessNavLink>
            </td>
            <td>{scene.interiorExteriorDescription}</td>
            <td>{scene.dayNightDescription}</td>
            <td>
              <div className="tags">
                {(scene.characters || []).map((character, c) => {
                  return (
                    <SceneCharacter
                      key={c}
                      {...character}
                      sceneId={scene.id}
                      filmId={filmId}
                      canAccessCharacters={canAccessCharacters}
                    />
                  );
                })}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Scenes;
