const defaultState = {
  backgroundCharacter: {},
  backgroundCharacters: [],
  loading: true,
  readOnly: false
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_BACKGROUND_BACKGROUND_CHARACTERS_IN_SCENE_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "FETCH_BACKGROUND_CHARACTERS_IN_SCENE_FULFILLED": {
      return {
        ...state,
        backgroundCharacters: action.payload.data.backgroundCharacters,
        readOnly : action.payload.data.readOnly,
        loading: false,
        errors: null
      };
    }

    case "CLEAR_BACKGROUND_CHARACTER_IN_SCENE": {
      return {
        ...state,
        backgroundCharacter: {},
        loading: false,
        errors: null
      };
    }

    case "FETCH_SINGLE_BACKGROUND_CHARACTER_IN_SCENE_PENDING": {
      return {
        ...state,
        backgroundCharacter: null,
        loadingCharacter: true
      };
    }

    case "FETCH_SINGLE_BACKGROUND_CHARACTER_IN_SCENE_FULFILLED": {
      return {
        ...state,
        backgroundCharacter: action.payload.data,
        loadingCharacter: false,
        errors: null
      };
    }

    case "UPDATE_SINGLE_BACKGROUND_CHARACTER_IN_SCENE_PENDING": {
      return {
        ...state,
        loadingCharacter: true,
        errors: null
      };
    }

    case "UPDATE_SINGLE_BACKGROUND_CHARACTER_IN_SCENE_FULFILLED": {
      const backgroundCharacter = {
        ...action.meta.backgroundCharacter,
        ...action.payload.data.backgroundCharacter
      };
      const backgroundCharacters: any = state.backgroundCharacters.map((c: any, index: number) => {
        if (c.id === backgroundCharacter.id) {
          return { ...c, ...backgroundCharacter };
        } else {
          return c;
        }
      });

      sortCharacters(backgroundCharacters);

      return {
        ...state,
        backgroundCharacter,
        backgroundCharacters,
        loadingCharacter: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "UPDATE_SINGLE_BACKGROUND_CHARACTER_IN_SCENE_REJECTED": {
      return {
        ...state,        
        errors: action.payload.response.data.errors,
        loadingCharacter: false
      };
    }

    case "ADD_SINGLE_BACKGROUND_CHARACTER_TO_SCENE_PENDING": {
      return {
        ...state,
        errors: null,
        loadingCharacter: true
      };
    }

    case "ADD_SINGLE_BACKGROUND_CHARACTER_TO_SCENE_FULFILLED": {
      const backgroundCharacter: any = {
        ...state.backgroundCharacter,
        ...action.payload.data.backgroundCharacter
      };

      let backgroundCharacters = [backgroundCharacter, ...state.backgroundCharacters];

      sortCharacters(backgroundCharacters);

      return {
        ...state,
        backgroundCharacter,
        backgroundCharacters,
        loadingCharacter: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "ADD_SINGLE_BACKGROUND_CHARACTER_TO_SCENE_REJECTED": {
      return {
        ...state,
        backgroundCharacter: action.meta.backgroundCharacter,
        errors: action.payload.response.data.errors,
        loadingCharacter: false
      };
    }

    case "DELETE_SINGLE_BACKGROUND_CHARACTER_IN_SCENE_PENDING": {
      return {
        ...state,
        loadingCharacter: true,
        errors: null
      };
    }

    case "DELETE_SINGLE_BACKGROUND_CHARACTER_IN_SCENE_FULFILLED": {
      const backgroundCharacterId = action.meta.backgroundCharacter.backgroundCharacterId;
      const backgroundCharacters: any = state.backgroundCharacters.filter(
        (c: any, index: number) => {
          return c.id !== backgroundCharacterId;
        }
      );

      return {
        ...state,
        backgroundCharacter: null,
        backgroundCharacters,
        loadingCharacter: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "DELETE_SINGLE_BACKGROUND_CHARACTER_IN_SCENE_REJECTED": {
      return {
        ...state,
        backgroundCharacter: action.meta.backgroundCharacter,
        errors: action.payload.response.data.errors,
        loadingCharacter: false
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        backgroundCharacter: null,
        redirect: null
      };
    }

    default:
      return state;
  }
};

function sortCharacters(backgroundCharacters) {
  backgroundCharacters.sort((a, b) => {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  });
}

export default reducer;
