import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../../shared/loader";
import { fetchSetDressingsInScene } from "./ducks/actions";
import List from "./list";

function ListSetDressingsContainer(props) {
  const { filmId, sceneId } = props.match.params;
  const { fetchSetDressingsInScene, loading, sceneSetDressings, errors } = props;

  useEffect(() => {
    fetchSetDressingsInScene(filmId, sceneId);
  }, []);

  function renderLoading() {
    return <Loader />;
  }

  function renderCharacters(sceneSetDressings, filmId, sceneId) {
    return (
      <div className="blade-menu">
        <List setDressings={sceneSetDressings} filmId={filmId} sceneId={sceneId} />        
      </div>
    );
  }

  return loading
    ? renderLoading()
    : renderCharacters(sceneSetDressings, filmId, sceneId);
}

function mapStateToSetDressings(state) {
  return {
    sceneSetDressings: state.scenesState.setDressingState.setDressings,
    loading: state.scenesState.setDressingState.loading,
    errors: state.scenesState.setDressingState.errors
  };
}

export default connect(mapStateToSetDressings, {
  fetchSetDressingsInScene
})(ListSetDressingsContainer);
