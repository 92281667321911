import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import { fetchBackgroundCharacters } from "./../../background-characters/ducks/actions";
import {
  addBackgroundCharacter,
  clearBackgroundCharacterInScene,
  clearRedirect,
} from "./ducks/actions";
import Form from "./form";

function AddCharacter(props) {
  const { filmId, sceneId } = props.match.params;
  const {
    fetchBackgroundCharacters,
    addBackgroundCharacter,
    clearBackgroundCharacterInScene,
    errors,
  } = props;

  useEffect(() => {
    fetchBackgroundCharacters(filmId);
    clearBackgroundCharacterInScene();
  }, []);

  if (props.redirect) {
    if (props.redirect.to == "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/scenes/${sceneId}/Background-characters`;
      return <Redirect to={url} />;
    }
  }

  function onAddBackgroundCharacter(character) {
    sanitiseDataTypes(character);
    addBackgroundCharacter(filmId, sceneId, character);
  }

  function sanitiseDataTypes(character) {    
   }

  return (
    <ScrollIntoView
      className="blade-content"
      path="/films/:filmId/scenes/:sceneId/background-characters/add"
      loading={false}>
      <Form
        mode="add"
        errors={errors}
        characterId={null}
        initialValues={props.backgroundCharacter}
        backgroundCharacters={props.backgroundCharacters}
        loading={false}
        onSubmit={onAddBackgroundCharacter}
        {...props}
      />
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  return {
    backgroundCharacters: state.backgroundCharactersState.rootState.backgroundCharacters,
    backgroundCharacter: state.scenesState.backgroundCharacterState.backgroundCharacter,
    errors: state.scenesState.backgroundCharacterState.errors,
    redirect: state.scenesState.backgroundCharacterState.redirect,
  };
}

export default connect(mapStateToProps, {
  addBackgroundCharacter,
  fetchBackgroundCharacters,
  clearBackgroundCharacterInScene,
  clearRedirect,
})(AddCharacter);
