import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../../shared/loader";
import { fetchPropsInScene } from "./ducks/actions";
import List from "./list";

function ListPropsContainer(props) {
  const { filmId, sceneId } = props.match.params;
  const { fetchPropsInScene, loading, sceneProps, errors } = props;

  useEffect(() => {
    fetchPropsInScene(filmId, sceneId);
  }, []);

  function renderLoading() {
    return <Loader />;
  }

  function renderCharacters(props, filmId, sceneId) {
    return (
      <div className="blade-menu">
        <List props={props} filmId={filmId} sceneId={sceneId} />        
      </div>
    );
  }

  return loading
    ? renderLoading()
    : renderCharacters(sceneProps, filmId, sceneId);
}

function mapStateToProps(state) {
  return {
    sceneProps: state.scenesState.propState.props,
    loading: state.scenesState.propState.loading,
    errors: state.scenesState.propState.errors
  };
}

export default connect(mapStateToProps, {
  fetchPropsInScene
})(ListPropsContainer);
