import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import Loader from "../../../shared/loader";
import Onboarding from '../../../shared/onboarding/onboarding';
import {
  assignScenesToCostume,
  clearRedirect,
  fetchCostume,
  fetchAllScenes,
  fetchAllSceneSettings,
  fetchScenesAssignedToCostume,
  setSceneAssignedCotume,
  findInScript,
  clearSceneSearchResults,
  fetchScriptDays,
  setLoadingSceneSettings
} from "./ducks/actions";

import {
  searchScenes,
  SEARCH_SCENES_FOR_COSTUMES
} from "../../scenes/search/ducks/actions";
import SearchScenesForm from "../../scenes/search/search-scenes-form";
import Form from "./scenes-form";
import ScriptSearch from "../../script/search/script-search";
import { OnboardingStep1ClassName, OnboardingStep2ClassName } from './ducks/reducer';

function CostumeScenes(props) {
  const costumeId = props.costumeId || props.match.params.costumeId;
  const filmId = props.filmId || props.match.params.filmId;
  const {
    fetchCostume,
    fetchAllScenes,
    fetchAllSceneSettings,
    fetchScenesAssignedToCostume,
    setSceneAssignedCotume,
    fetchScriptDays,
    findInScript,
    costume,
    assignScenesToCostume,
    loadingCostume,
    allScenes,
    loadingScenes,
    searchScenes,
    clearSceneSearchResults,
    errors,
    sceneId,
    embedded,
    onboardingSteps,
    loadingSceneSettings,
    setLoadingSceneSettings,
    findingInScript,
    searchingScenes
  } = props;

  const invalidIds = ["add", "scenes"];
  const readOnly = costume?.readOnly;
  useEffect(() => {
    fetchCostume(filmId, costumeId);
    if (!sceneId) {
      setLoadingSceneSettings();
      fetchScenesAssignedToCostume(filmId, costumeId);
    }
  }, [filmId, costumeId]);

  useEffect(() => {
    if (costume) {
      fetchAllSceneSettings(filmId, costume.characterIds, costume.backgroundCharacterIds);
      fetchAllScenes(filmId, costume.characterIds, costume.backgroundCharacterIds);
      fetchScriptDays(filmId, costume.characterIds, costume.backgroundCharacterIds);
    }
  }, [filmId, costumeId]);

  useEffect(() => {
    if (sceneId) {
      setSceneAssignedCotume(sceneId);
    }
  }, [sceneId]);

  if (!embedded && props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/costumes/`;
      return <Redirect to={url} />;
    }
  }

  if (invalidIds.indexOf(costumeId) > -1) {
    return null;
  }

  const onFindInScript = (e) => {
    e.preventDefault();
    findInScript(filmId, costume.name);
  };

  function renderLoading() {
    return <Loader />;
  }

  function renderForm() {
    return (
      <div className="blade-content">
        <h2 className="menu-label">Costume Scenes</h2>

        {!readOnly && <div className={`box mt-1 ${OnboardingStep1ClassName}`}>
          <SearchScenesForm
            errors={errors}
            mode="edit"
            filmId={filmId}
            costumeId={costumeId}
            loading={loadingCostume}
            onSubmit={onSearchScenes}
            findInScript={onFindInScript}
            allSceneSettings={props.allSceneSettings}
            scenes={allScenes}
            scriptDays={props.scriptDays}
            costumeName={costume.name}
            searchingScenes={searchingScenes}
            showScriptDays={true}
            showSceneSettings={true}
          />
        </div>}
        {!readOnly && <div className={`box mt-1 ${OnboardingStep2ClassName}`}>
          <ScriptSearch filmId={filmId} text={costume.name} typeToDispatch="PROP_SEARCH_SCRIPT" findingInScript={findingInScript} />
        </div>}
        {/* {loadingSceneSettings && <div>loadingSceneSettings</div>}
        {loadingCostume && <div>loadingCostume</div>}
        {loadingScenes && <div>loadingScenes</div>}  */}
        <Form
          errors={errors}
          mode="edit"
          filmId={filmId}
          costumeId={costumeId}
          initialValues={{ selectedSceneIds: props.selectedSceneIds }}
          loading={loadingCostume || loadingSceneSettings || loadingScenes}
          onSubmit={onUpdateCostumeScenes}
          reduxFormValues={props.reduxFormValues}
          allScenes={allScenes}
          searchedSceneIds={props.searchedScenes?.map((s) => s.id)}
          selectSearchedScenes={props.selectSearchedScenes}
          clearSceneSearchResults={clearSceneSearchResults}
          readOnly={readOnly}
        />
        <Onboarding onboardingSteps={onboardingSteps} section="Costume Scenes" />
      </div>
    );
  }

  function onSearchScenes(params) {
    searchScenes(
      SEARCH_SCENES_FOR_COSTUMES,
      filmId,
      params
    );
  }

  function onUpdateCostumeScenes(costumeScenes) {
    assignScenesToCostume(filmId, costumeId, costumeScenes.selectedSceneIds);
  }

  return allScenes?.length && costume ? renderForm() : renderLoading();
}

function mapStateToCostumes(state) {
  const { costumesState, form } = state;
  return {
    costume: costumesState.costume,
    allScenes: costumesState.scenes,
    loadingScenes: costumesState.loadingScenes,
    allSceneSettings: costumesState.sceneSettings,
    selectedSceneIds: costumesState.selectedSceneIds,
    loadingSceneSettings: costumesState.loadingSceneSettings,
    searchedScenes: costumesState.searchedScenes,
    selectSearchedScenes: costumesState.selectSearchedScenes,
    findingInScript: costumesState.findingInScript,
    searchingScenes: costumesState.searchingScenes,
    scriptDays: costumesState.scriptDays,
    errors: costumesState.errors,
    redirect: costumesState.redirect,
    onboardingSteps: costumesState.onboardingSteps,
    reduxFormValues: form?.costumeScenesForm?.values
  };
}

export default
  connect(mapStateToCostumes, {
    fetchCostume,
    fetchAllScenes,
    fetchAllSceneSettings,
    fetchScenesAssignedToCostume,
    setSceneAssignedCotume,
    fetchScriptDays,
    clearRedirect,
    assignScenesToCostume,
    searchScenes,
    findInScript,
    clearSceneSearchResults,
    setLoadingSceneSettings
  })(CostumeScenes);
