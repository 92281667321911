import React from "react";
import { Link, Prompt } from "react-router-dom";
import Moment from "react-moment";
import { HashLink } from 'react-router-hash-link';
import './day-out-of-days.scss';
import CanAccessLink from "../../../shared/can-access-link";

const DayOutOfDaysCrewTable = (props) => {
  const {
    crewMembers,
    days,
    hasDates,
    errors,
    filmId,
    showNonShootingDays,
    canAccessCrew,
    scrollWithOffset
  } = props;

  const filteredDays = showNonShootingDays ? days : days.filter((d: any) => !d.isOffDay);

  return (
    <div className="day-out-days-table-container">
      <table className="table day-out-days-table">
        <thead className="day-out-days-table-header-row">
          <tr>
            <th className="day-out-days-table-actor-column">Name</th>
            <th>Department</th>
            <th>Role</th>
            {(filteredDays || []).map((day: any, index: number) => (<th className="has-text-centered" key={index} >{day.number && <Link to={`/films/${filmId}/shooting-days/${day.id}/actors`}>Day {day.number}</Link>}{!day.number && <span>Off Day</span>}</th>))}
            {hasDates && <th className="has-text-centered" colSpan={2}>Total</th>}
            {!hasDates && <>
              <th className="has-text-centered">Total Work</th>
              <th className="has-text-centered">Total Hold</th>
            </>}
          </tr>
          {hasDates &&
            <tr>
              <th className="day-out-days-table-actor-column"></th>
              <th></th>
              <th></th>
              {(filteredDays || []).map((day: any, index: number) => (<th className="has-text-centered" key={index}><Moment format="DD/MM/YYYY">{day.date}</Moment></th>))}
              <th className="has-text-centered">Work</th>
              <th className="has-text-centered">Hold</th>
            </tr>
          }
        </thead>
        <tbody>
          {(crewMembers || []).map((crewMember, index) => {
            return (
              <tr key={index}>
                <td className="day-out-days-table-actor-column">
                  <CanAccessLink to={`/films/${filmId}/departments/${crewMember.crewDepartmentId}/roles/${crewMember.crewRoleId}/members/${crewMember.crewMemberId}`} canAccess={canAccessCrew}>{crewMember.name}</CanAccessLink>
                </td>
                <td className="day-out-days-table-deptartment-column">
                  <CanAccessLink to={`/films/${filmId}/departments/${crewMember.crewDepartmentId}/edit`} canAccess={canAccessCrew}>{crewMember.crewDepartmentName}</CanAccessLink>
                </td>
                <td className="day-out-days-table-role-column">
                  <CanAccessLink to={`/films/${filmId}/departments/${crewMember.crewDepartmentId}/roles/${crewMember.crewRoleId}/edit`} canAccess={canAccessCrew}>{crewMember.crewRoleName}</CanAccessLink>
                </td>
                {(crewMember.days.filter((d: any) => showNonShootingDays || d.number) || []).map((day: any, index: number) => (<td className="has-text-centered" key={index}>
                  <CanAccessLink to={`/films/${filmId}/shooting-days/${day.dayId}/crew#${crewMember.crewMemberId}_${crewMember.crewRoleId}`} canAccess={canAccessCrew && day.dayId && !day.hold} scrollWithOffset={el => scrollWithOffset(el)}>{day.start ? "S" : ""}{day.work ? "W" : ""}{day.hold ? "H" : ""}{day.finish ? "F" : ""}</CanAccessLink>
                </td>))}
                <td className="has-text-centered">{crewMember.totalWorkDays}</td>
                <td className="has-text-centered">{crewMember.totalHoldDays}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}


export default DayOutOfDaysCrewTable;
