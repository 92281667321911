import React from "react";
import { Link } from "react-router-dom";
import OffScreen from "../icons/off-screen";
import NoDialog from "../icons/no-dialog";
import { ItemTypes } from "./constants";
import { useDrag } from "react-dnd";

const ScriptItem = ({ index, item, label, readOnly, url, itemType, canDrag }) => {

  const canDragActor = (monitor) => {
    return !readOnly && canDrag;
  };

  const [{ isDragging }, drag] = useDrag({
    item: { type: itemType, item },
    canDrag: (monitor) => canDragActor(monitor),
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  });

  return (
    <li key={item.id} ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: (readOnly || !canDrag) ? "" : "grab"
      }}>
      {(!readOnly && canDrag) && <i className="material-icons dark">drag_indicator</i>}
      <Link to={`${url}${item.id}/edit`}>{item.name}</Link>
      {item.onlyOffScene && (
        <OffScreen className="small-icon breakdown-scene" />
      )}
      {label === "Characters" && !item.hasDialog && (
        <NoDialog className="small-icon breakdown-scene" />
      )}
    </li>
  );
};

export default ScriptItem;
