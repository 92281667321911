import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Eighth from "./eighth";
import "./breakdown.scss";
import ScriptItems from "./script-items";
import { Link } from "react-router-dom";
import CharactersIcon from '../../films/icons/characters';
import CostumesIcon from '../../films/icons/costumes';
import MakeupIcon from '../../films/icons/makeup';
import BackgroundCharactersIcon from '../../films/icons/background-characters';
import PropsIcon from '../../films/icons/props';
import SetSressingsIcon from '../../films/icons/set-dressings';
import VisualEffectsIcon from '../../films/icons/visual-effects';
import { ItemTypes } from "./constants";

const Scene = ({ scene,
  filmId,
  readOnly,
  onDropItem,
  changeCharacterToBackgroundCharacter,
  changeBackgroundCharacterToCharacter,
  changePropToSetDressing,
  changePropToCostume,
  changeSetDressingToProp,
  changeCostumeToProp
}) => {

  const onChangeCharacterToBackgroundCharacter = (item, destination) => {
    changeCharacterToBackgroundCharacter(filmId, item.item);
  };

  const onChangeBackgroundCharacterToCharacter = (item, destination) => {
    changeBackgroundCharacterToCharacter(filmId, item.item);
  };

  const onChangePropTo = (item, destination) => {
    if (destination === ItemTypes.SET_DRERSSING) {
      changePropToSetDressing(filmId, item.item);
    } else if (destination === ItemTypes.COSTUME) {
      changePropToCostume(filmId, item.item);
    }
  };

  const onChangePropFrom = (item, destination) => {
    if (item.type === ItemTypes.SET_DRERSSING) {
      changeSetDressingToProp(filmId, item.item);
    } else if (item.type === ItemTypes.COSTUME) {
      changeCostumeToProp(filmId, item.item);
    }
  };

  return (
    <div className="scene-breakdown">
      <div>
        <label>Scene:</label>{" "}
        <Link to={`/films/${filmId}/scenes/${scene.id}`}>{scene.number}</Link>
      </div>
      <div>
        <label>Scene Setting:</label>{" "}
        <Link to={`/films/${filmId}/scene-settings/${scene.sceneSettingId}`}>
          {scene.sceneSetting}
        </Link>
      </div>
      {scene.interiorExteriorDescription && (
        <div>
          <label>Int/Ext:</label> {scene.interiorExteriorDescription}
        </div>
      )}
      {scene.dayNightDescription && (
        <div>
          <label>Day/Night:</label> {scene.dayNightDescription}
        </div>
      )}
      {scene.pageStart && (
        <div>
          <label>Script Pages:</label> <Eighth page={scene.pageStart} /> -{" "}
          <Eighth page={scene.pageEnd} /> (<Eighth page={scene.pageLength} />)
        </div>
      )}
      {scene.location && (
        <div>
          <label>Location:</label> {scene.location}
        </div>
      )}
      <div className="break"></div>
      {scene.summary && (
        <>
          {" "}
          <div>
            <label>Summary:</label> {scene.summary}
          </div>
          <div className="break"></div>
        </>
      )}
      <hr />
      <DndProvider backend={HTML5Backend}>
        <div className="columns is-multiline">
          <ScriptItems
            label="Characters"
            icon={CharactersIcon}
            items={scene.characters}
            url={`/films/${filmId}/characters/`}
            readOnly={readOnly}
            allowedItemTypes={[ItemTypes.BACKGROUND_CHARACTER]}
            itemType={ItemTypes.CHARACTER}
            dropItem={onChangeBackgroundCharacterToCharacter}
            canDrag={true}
          />
          <ScriptItems
            label="Background Characters"
            icon={BackgroundCharactersIcon}
            items={scene.backgroundCharacters}
            url={`/films/${filmId}/background-characters/`}
            readOnly={readOnly}
            allowedItemTypes={[ItemTypes.CHARACTER]}
            itemType={ItemTypes.BACKGROUND_CHARACTER}
            dropItem={onChangeCharacterToBackgroundCharacter}
            canDrag={true}
          />

          <ScriptItems
            label="Props"
            icon={PropsIcon}
            items={scene.props}
            url={`/films/${filmId}/props/`}
            readOnly={readOnly}
            allowedItemTypes={[ItemTypes.COSTUME, ItemTypes.SET_DRERSSING]}
            itemType={ItemTypes.PROP}
            dropItem={onChangePropFrom}
            canDrag={true}
          />

          <ScriptItems
            label="Set Dressing"
            icon={SetSressingsIcon}
            items={scene.setDressings}
            url={`/films/${filmId}/set-dressings/`}
            readOnly={readOnly}
            allowedItemTypes={[ItemTypes.PROP]}
            itemType={ItemTypes.SET_DRERSSING}
            dropItem={onChangePropTo}
            canDrag={true}
          />

          <ScriptItems
            label="Costumes"
            icon={CostumesIcon}
            items={scene.costumes}
            url={`/films/${filmId}/costumes/`}
            readOnly={readOnly}
            allowedItemTypes={[ItemTypes.PROP]}
            itemType={ItemTypes.COSTUME}
            dropItem={onChangePropTo}
            canDrag={true}
          />

          <ScriptItems
            label="Makeup/hair"
            icon={MakeupIcon}
            items={scene.makeups}
            url={`/films/${filmId}/makeup/`}
            readOnly={readOnly}
            allowedItemTypes={[]}
            itemType={ItemTypes.MAKEUP}
            dropItem={onDropItem}
            canDrag={false}
          />

          <ScriptItems
            label="Visual Effects"
            icon={VisualEffectsIcon}
            items={scene.visualEffects}
            url={`/films/${filmId}/visual-effects/`}
            readOnly={readOnly}
            allowedItemTypes={[]}
            itemType={ItemTypes.VISUAL_EFFECT}
            dropItem={onDropItem}
            canDrag={false}
          />
        </div>
      </DndProvider>
    </div>
  );
};

export default Scene;
