import { combineReducers } from "redux";
import characterReducer from "./../characters/ducks/reducer";
import backgroundCharacterReducer from "./../background-characters/ducks/reducer";
import propReducer from "./../props/ducks/reducer";
import setDressingReducer from "./../set-dressings/ducks/reducer";
import costumeReducer from "./../costumes/ducks/reducer";
import makeupReducer from "./../makeups/ducks/reducer";
import equipmentReducer from "./../equipment/ducks/reducer";
import scriptDaysReducer from "./../script-days/ducks/reducer";
import shootingDaysReducer from "./../shooting-days/ducks/reducer";
import OnboardingType from "../../../shared/onboarding/onboarding-type";
import scenesOrderReducer from "./../order/ducks/reducer";
import { UPDATE_SCENES_ORDER } from "../order/ducks/actions";
import { SCENE_CLEAR_REDIRECT, SPLIT_SCENE } from "./actions";

interface ScenesState {
  scenes: any[];
  scene: any;
  loading: boolean;
  readOnly: boolean;
  onboardingSteps: OnboardingType[];
}

export const OnboardingStep1ClassName = 'scene-onboarding-step-1';
export const OnboardingStep2ClassName = 'scene-onboarding-step-2';
export const OnboardingStep3ClassName = 'scene-onboarding-step-3';
export const OnboardingStep4ClassName = 'scene-onboarding-step-4';
export const OnboardingStep5ClassName = 'scene-onboarding-step-5';

const defaultState: ScenesState = {
  scenes: [],
  scene: {},
  loading: true,
  readOnly: false,
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Number',
      content: `        
        This is the order of the scenes occur in the script.
      `
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Setting',
      content: `<p>This is the Scene Setting where this Scene is set.</p>
                <p>If this is changed, any Set Dressings that are assigned to this Scene will be removed from the Scene.</p>`
    },
    {
      target: '.' + OnboardingStep3ClassName,
      title: 'Page Length',
      content: `The number of pages this scene occupies in the script. This is used to determine how many days it will take to shoot to help with scheduling.`
    },
    {
      target: '.' + OnboardingStep4ClassName,
      title: 'Shoot Days',
      content: `<p>This is calculated from the Page Length, using the number of pages that can be shot in a day from your User Settings, accessible from the top right.</p>
                <p>It can be changed manually if the calculation is not correct. If you change the Page Length, then this manual setting will be overwritten.</p>
      `
    },
    {
      target: '.' + OnboardingStep5ClassName,
      title: 'Script Day',
      content: `<p>The script day is the day in the script during which the scene is
      set. All the scenes that happen on the same day in the script have
      the same script day. For example, if you have five scenes on one
      day, they would all have the same script day.</p>
      <p>This can be better visualised and edited in the dedicated option on the left.</p>`
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_SCENES_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "FETCH_SCENES_FULFILLED": {
      return {
        ...state,
        scenes: action.payload.data.scenes,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null
      };
    }

    case "FETCH_SCENES_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.data
      };
    }

    case "CLEAR_SCENE": {
      return {
        ...state,
        loading: false,
        scene: null,
        errors: null
      };
    }

    case "FETCH_NEXT_SCENE_NUMBER": {
      let nextNumber = 1;
      if (state.scenes && state.scenes.length > 0) {
        const maxNumber = Math.max.apply(
          null,
          state.scenes.map((scene: any) => {
            return scene.number;
          })
        );
        nextNumber = maxNumber + 1;
      }
      return {
        ...state,
        scene: { ...state.scene, number: nextNumber }
      };
    }



    case UPDATE_SCENES_ORDER + "_FULFILLED": {
      return {
        ...state,
        scenes: action.meta.scenes
      };
    }

    case "FETCH_SINGLE_SCENE_PENDING": {
      return {
        ...state,
        scene: null,
        loadingScene: true
      };
    }

    case "FETCH_SINGLE_SCENE_FULFILLED": {
      return {
        ...state,
        scene: action.payload.data,
        loadingScene: false,
        errors: null
      };
    }

    case "UPDATE_SINGLE_SCENE_PENDING": {
      return {
        ...state,
        scene: action.payload,
        loadingScene: true,
        errors: null
      };
    }

    case "UPDATE_SINGLE_SCENE_FULFILLED": {
      const scene = { ...action.meta.scene, ...action.payload.data.scene };
      const scenes: any = state.scenes.map((c: any, index: number) => {
        if (c.id === scene.id) {
          return { ...c, ...scene };
        } else {
          return c;
        }
      });
      sortScenes(scenes);
      return {
        ...state,
        scene,
        scenes,
        loadingScene: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "UPDATE_SINGLE_SCENE_REJECTED": {
      return {
        ...state,
        scene: action.meta.scene,
        errors: action.payload.response.data.errors,
        loadingScene: false
      };
    }

    case "ADD_SINGLE_SCENE_PENDING": {
      return {
        ...state,
        scene: action.payload,
        errors: null,
        loadingScene: true
      };
    }

    case "ADD_SINGLE_SCENE_FULFILLED": {
      const scene: any = action.payload.data.scene;

      let scenes = [...state.scenes, scene];
      sortScenes(scenes);
      return {
        ...state,
        scene,
        scenes,
        loadingScene: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "ADD_SINGLE_SCENE_REJECTED": {
      return {
        ...state,
        scene: action.meta.scene,
        errors: action.payload.response.data.errors,
        loadingScene: false
      };
    }

    case "DELETE_SINGLE_SCENE_PENDING": {
      return {
        ...state,
        scene: action.payload,
        loadingScene: true,
        errors: null
      };
    }

    case "DELETE_SINGLE_SCENE_FULFILLED": {
      const scene = action.meta.scene;
      const scenes: any = Array.from(state.scenes).filter((c: any, index: number) => {
        return c.id !== scene.id;
      });

      return {
        ...state,
        scene: null,
        scenes,
        loadingScene: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "DELETE_SINGLE_SCENE_REJECTED": {
      return {
        ...state,
        scene: action.meta.scene,
        errors: action.payload.response.data.errors,
        loadingScene: false
      };
    }

    case SPLIT_SCENE + "_PENDING": {
      return {
        ...state,
        scene: action.payload,
        loadingScene: true,
        errors: null
      };
    }

    case SPLIT_SCENE + "_FULFILLED": {
      const sceneId = action.meta.sceneId;
      const scene = action.payload.data.scene;
      const scenes: any = Array.from(state.scenes);
      const originalSceneIndex = scenes.findIndex(s => s.id === sceneId);
      scenes.splice(originalSceneIndex + 1, 0, scene);

      return {
        ...state,
        scene: null,
        scenes,
        loadingScene: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case SPLIT_SCENE + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loadingScene: false
      };
    }

    case SCENE_CLEAR_REDIRECT: {
      return {
        ...state,
        scene: null,
        redirect: null
      };
    }

    default:
      return state;
  }
};

function sortScenes(scenes) {
  // scenes.sort((a, b) => {
  //   if (a.number < b.number) return -1;
  //   if (a.number > b.number) return 1;
  //   return 0;
  // });
}

export default combineReducers({
  rootState: reducer,
  characterState: characterReducer,
  backgroundCharacterState: backgroundCharacterReducer,
  propState: propReducer,
  setDressingState: setDressingReducer,
  costumeState: costumeReducer,
  makeupState: makeupReducer,
  equipmentState: equipmentReducer,
  scriptDaysState: scriptDaysReducer,
  shootingDaysState: shootingDaysReducer,
  orderState: scenesOrderReducer
});
