import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../../shared/loader";
import { fetchCostumesInScene } from "./ducks/actions";
import List from "./list";

function ListCostumesContainer(props) {
  const { filmId, sceneId } = props.match.params;
  const { fetchCostumesInScene, loading, sceneCostumes, errors } = props;

  useEffect(() => {
    fetchCostumesInScene(filmId, sceneId);
  }, []);

  function renderLoading() {
    return <Loader />;
  }

  function renderCharacters(sceneCostumes, filmId, sceneId) {
    return (
      <div className="blade-menu">
        <List sceneCostumes={sceneCostumes} filmId={filmId} sceneId={sceneId} />        
      </div>
    );
  }

  return loading
    ? renderLoading()
    : renderCharacters(sceneCostumes, filmId, sceneId);
}

function mapStateToCostumes(state) {
  return {
    sceneCostumes: state.scenesState.costumeState.costumes,
    loading: state.scenesState.costumeState.loading,
    errors: state.scenesState.costumeState.errors
  };
}

export default connect(mapStateToCostumes, {
  fetchCostumesInScene
})(ListCostumesContainer);
