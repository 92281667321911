import { Link, Prompt } from "react-router-dom";
import { Field, reduxForm, FieldArray, formValueSelector } from "redux-form";
import { connect } from 'react-redux'
import Errors from "../../shared/errors";
import {  
  renderTextField,  
  renderSingleReactSelect,
  renderTextAreaField,
  renderCkEditorField
} from "../../shared/form-helpers";
import required from "../../shared/validation";
import FormErrors from "../../shared/form-errors";
import BoxWraper from "../../account/box-wrapper";

let CostumeForm = (props) => {
  const {
    handleSubmit,
    handleDelete,
    sendChangeLog,
    notWorthSendingChangeLog,
    pristine,
    reset,
    submitting,
    errors,
    changeLogChangeTypeEnum,    
    mode,    
    submitFailed,
    invalid,
    changeLogId
  } = props;
  const thisChangeLog = props.initialValues || {};
  const readOnly = thisChangeLog.readOnly;

  const changeLogChangeTypeOptions = (changeLogChangeTypeEnum || []).map((item) => { return { value: item.value, label: item.name } });

  const dateFormatter = (dateString) => {
    return dateString?.split("T")[0];
  };

  const renderChanges = ({ fields, meta: { error }, readOnly }) => (
    <div>     
      {fields.map((change, index) => (
        <div key={index} className="mt-1">
          
                <div>
                    {index + 1}
                  
                  {!readOnly && (
                    <a
                      onClick={() => fields.remove(index)}
                      style={{ cursor: "pointer" }}>
                      <i className="material-icons">delete</i>
                    </a>
                    )}
                  </div>
                  <div className="columns">
                <div className="column">

                    <Field
                      name={`${change}.changeType`}
                      label={`${index + 1} Change Type`}
                      component={renderSingleReactSelect}
                      options={changeLogChangeTypeOptions}        
                      readOnly={readOnly}
                      />

                    <Field
                        name={`${change}.headline`}
                        type="text"
                        component={renderTextField}
                        label={`Headline`}
                        readOnly={readOnly}
                      />

                    <Field
                        name={`${change}.details`}
                        type="text"
                        component={renderCkEditorField}
                        label={`Details`}
                        readOnly={readOnly}
                      />

                </div>
                </div>
        </div>
      ))}
       {!readOnly && (
        
          <button
            type="button"
            className="button is-small mt-1"
            onClick={() => fields.push()}>
            Add Change
          </button>
        
      )}
      {error && <li className="error">{error}</li>}
    </div>
  );

  return (
    <BoxWraper width={7}>
      <Prompt when={!pristine} message="" />
      {mode === "add" && <h2 className="menu-label">Add Change Log</h2>}
      {mode === "edit" && <h2 className="menu-label">Edit Change Log</h2>}
      <div className="columns">
        <div className="column">
          {errors && <Errors errors={errors} />}
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Field
              name="versionName"
              label="Version Name"
              component={renderTextField}
              validate={[required]}
              required
              readOnly={readOnly}
            />

          <Field
              name="summary"
              label="Summary"
              component={renderTextField}
              validate={[required]}
              required
              readOnly={readOnly}
            />
           
            <Field
              name="releaseDate"
              label="Release Date"
              type="date"
              format={dateFormatter}
              component={renderTextField}
              validate={[required]}
              required
              readOnly={readOnly}                      
            />

            <FieldArray
                  name={`changes`}
                  component={renderChanges}
                  readOnly={readOnly}
                />

            {!readOnly &&
            <>
              <div className="buttons mt-2">
                <button
                  type="submit"
                  className="button is-primary"
                  disabled={pristine || submitting}>
                  Save
                </button>
                {mode === "edit" && (
                  <>
                  <button
                    type="button"
                    className="button is-text"
                    disabled={submitting || !props.initialValues}
                    onClick={() => handleDelete(thisChangeLog)}>
                    Delete Change Log
                  </button>
                  <Link className="button is-text" to={`/change-log/${changeLogId}`}>View</Link>
                </>             
                )}
              </div>
              {mode === "edit" && !thisChangeLog.sent &&
                  <div>
                    <button
                      type="button"
                      className="button"
                      disabled={submitting || !props.initialValues}
                      onClick={() => sendChangeLog(thisChangeLog)}>
                      Send Change Log
                    </button>
                    <button
                      type="button"
                      className="button ml-1"
                      disabled={submitting || !props.initialValues}
                      onClick={() => notWorthSendingChangeLog(thisChangeLog)}>
                      Not worth sending
                    </button>
                  </div>                  
                }         
                  {thisChangeLog.sent && <div>Sent on {thisChangeLog.sentDate}</div>}
                  </>}
                  
                              <FormErrors submitFailed={submitFailed} invalid={invalid} />

          </form>
        </div>        
      </div>
    </BoxWraper>
  );
}

CostumeForm = reduxForm({
  form: "editCostumeForm",
  enableReinitialize: true
})(CostumeForm);

// Decorate with connect to read form values
const selector = formValueSelector('editCostumeForm'); // <-- same as form name
const CostumeFormConnect = connect(
  state => {
    // can select values individually
    const acquisitionMethodValue = selector(state, 'acquisitionMethod')

    return {
      acquisitionMethodValue
    }
  }
)(CostumeForm)

export default CostumeFormConnect
