const defaultState = {
  character: {},
  characters: [],
  loading: true,
  readOnly: false
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_CHARACTERS_IN_SCENE_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "FETCH_CHARACTERS_IN_SCENE_FULFILLED": {
      return {
        ...state,
        characters: action.payload.data.characters,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null
      };
    }

    case "FETCH_CHARACTERS_IN_SCENE_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.data
      };
    }

    case "CLEAR_CHARACTER_IN_SCENE": {
      return {
        ...state,
        character: { hasDialog: true },
        loading: false,
        errors: null
      };
    }

    case "FETCH_SINGLE_CHARACTER_IN_SCENE_PENDING": {
      return {
        ...state,
        character: null,
        loadingCharacter: true
      };
    }

    case "FETCH_SINGLE_CHARACTER_IN_SCENE_FULFILLED": {
      return {
        ...state,
        character: action.payload.data,
        loadingCharacter: false,
        errors: null
      };
    }

    case "FETCH_SINGLE_CHARACTER_IN_SCENE_REJECTED": {
      return {
        ...state,
        loadingCharacter: false,
        errors: action.payload.data
      };
    }

    case "UPDATE_SINGLE_CHARACTER_IN_SCENE_PENDING": {
      return {
        ...state,
        loadingCharacter: true,
        errors: null
      };
    }

    case "UPDATE_SINGLE_CHARACTER_IN_SCENE_FULFILLED": {
      /*
      const character = {
        ...action.meta.character,
        ...action.payload.data.character
      };
      const characters: any = state.characters.map((c: any, index: number) => {
        if (c.id === character.id) {
          return { ...c, ...character };
        } else {
          return c;
        }
      });

      sortCharacters(characters);
      */
      return {
        ...state,
        //  character,
        //        characters,
        loadingCharacter: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "UPDATE_SINGLE_CHARACTER_IN_SCENE_REJECTED": {
      return {
        ...state,
        character: action.meta.character,
        errors: action.payload.response.data.errors,
        loadingCharacter: false
      };
    }

    case "ADD_SINGLE_CHARACTER_TO_SCENE_PENDING": {
      return {
        ...state,
        errors: null,
        loadingCharacter: true
      };
    }

    case "ADD_SINGLE_CHARACTER_TO_SCENE_FULFILLED": {
      const character: any = {
        ...state.character,
        ...action.payload.data.character
      };

      let characters = [character, ...state.characters];

      sortCharacters(characters);

      return {
        ...state,
        character,
        characters,
        loadingCharacter: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "ADD_SINGLE_CHARACTER_TO_SCENE_REJECTED": {
      return {
        ...state,
        character: action.meta.character,
        errors: action.payload.response.data.errors,
        loadingCharacter: false
      };
    }

    case "DELETE_SINGLE_CHARACTER_IN_SCENE_PENDING": {
      return {
        ...state,
        loadingCharacter: true,
        errors: null
      };
    }

    case "DELETE_SINGLE_CHARACTER_IN_SCENE_FULFILLED": {
      const characterId = action.meta.character.characterId;
      const characters: any = state.characters.filter(
        (c: any, index: number) => {
          return c.id !== characterId;
        }
      );

      return {
        ...state,
        character: null,
        characters,
        loadingCharacter: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "DELETE_SINGLE_CHARACTER_IN_SCENE_REJECTED": {
      return {
        ...state,
        character: action.meta.character,
        errors: action.payload.response.data.errors,
        loadingCharacter: false
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        character: null,
        redirect: null
      };
    }

    default:
      return state;
  }
};

function sortCharacters(characters) {
  characters.sort((a, b) => {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  });
}

export default reducer;
