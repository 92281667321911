import getApiClient from "./../../../../shared/api";

export function fetchSetDressingsInScene(filmId, sceneId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SET_DRESSINGS_IN_SCENE",
      payload: getApiClient().get(`/film/${filmId}/Scene/${sceneId}/setDressings`),
    });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT",
    });
  };
}
