import React from "react";
import { Draggable } from 'react-beautiful-dnd';

function OrderItem(props) {
    const {
        actor,
        index,
        readOnly
    } = props;

    return (
        <Draggable draggableId={actor.id} index={index} isDragDisabled={readOnly}>
            {(provided: any) => (
                <tr className="track-order"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <td>
                        {!readOnly && <i className="material-icons dark">drag_indicator</i>}
                        {actor.number}. {actor.name}
                    </td>
                    <td>
                        {actor.characters?.join(", ")}
                    </td>
                </tr>
            )}
        </Draggable>
    );
}

export default OrderItem;
