import { useState } from "react";
import { Field, reduxForm } from "redux-form";
import {
  renderReactSelect,
  renderSingleReactSelect
} from "../../../shared/form-helpers";
import Errors from "../../../shared/errors";

function SearchScenesForm(props) {
  const {
    handleSubmit,
    errors,
    allSceneSettings,
    scenes,
    allCharacters,
    scriptDays,
    searchingScenes,
    showCharacters,
    showScriptDays,
    showSceneSettings
  } = props;

  const [selected, setSelected] = useState<boolean>(true);

  const sceneOptions = (scenes || []).map((item) => { return { value: item.sceneOrder, label: item.number } });

  return (
    <>
      {errors && <Errors errors={errors} />}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div>

          {showCharacters && <Field
            name="characterIds"
            label="Character"
            component={renderReactSelect}
            options={allCharacters?.map((c) => {
              return { label: c.name, value: c.id };
            })}
          />}

          {showSceneSettings &&
            <Field
              name="sceneSettingIds"
              label="Scene Setting"
              component={renderReactSelect}
              options={allSceneSettings?.map((c) => {
                return { label: c.name, value: c.id };
              })}
            />}

          {showScriptDays && <Field
            name="scriptDays"
            label="Script Days"
            component={renderReactSelect}
            options={scriptDays?.map((d) => {
              return { label: d, value: d };
            })}
          />}

          <div className="field-body">
            <Field
              name="sceneOrderFrom"
              label="Scene number from"
              component={renderSingleReactSelect}
              options={sceneOptions}
              includeEmpty={true}
            />

            <Field
              name="sceneOrderTo"
              label="Scene number to"
              component={renderSingleReactSelect}
              options={sceneOptions}
              includeEmpty={true}
            />
          </div>

          <div className="buttons">
            <button
              type="submit"
              className={`button is-secondary is-small ${searchingScenes && selected ? "is-loading" : ""}`}
              onClick={handleSubmit((values) => {
                setSelected(true);
                props.onSubmit({
                  ...values,
                  selectSearchedScenes: true
                })
              }
              )}>
              Select matches
            </button>
            <button
              className={`button is-secondary is-small is-text ${searchingScenes && !selected ? "is-loading" : ""}`}
              onClick={handleSubmit((values) => {
                setSelected(false);
                props.onSubmit({
                  ...values,
                  selectSearchedScenes: false
                })
              }
              )}>
              Unselect matches
            </button>
          </div>

        </div>
      </form>
    </>
  );
}

export default reduxForm({
  form: "costumeSearchScenesForm",
  enableReinitialize: true
})(SearchScenesForm);
