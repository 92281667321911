import Moment from "react-moment";
import { Link } from "react-router-dom";
import CkEditorContent from "../../../shared/controls/ckeditor-content";
import Errors from "../../../shared/errors";
import SmallLoader from "../../../shared/small-loader/small-loader";
import Comments from "./comments";
import './feature-request.scss';

function ViewFeatureRequest(props) {  
  const {    
    featureRequest,
    upVote,
    downVote,
    voteForLoading,
    errors,
    updateFeatureRequestComment,
    deleteFeatureRequestComment,
    setCommentEditMode
  } = props;

  const onUpVote = () => {
    if (featureRequest?.canUpVote) {
      upVote();
    }
  };

  return (
    <div>      
      <h2 className="menu-label">Feature Request</h2>        
      <Errors errors={errors} />
          {featureRequest && <div className="feature-request-container">
            <div className="feature-request-vote-container">
              <div className="feature-request-vote-thumbs">
                {voteForLoading && <SmallLoader />}
                {!voteForLoading && <>
                  {((featureRequest?.canUpVote || featureRequest?.canEdit) && !featureRequest?.canDownVote) && <>
                    <i onClick={onUpVote} className="material-icons feature-request-up-vote" style={{cursor: featureRequest?.canUpVote ? "pointer" : "arrow" }} title="Add vote">thumb_up</i>
                  </>}
                  {featureRequest?.canDownVote && <>
                    <i onClick={downVote} className="material-icons feature-request-down-vote" title="Remove vote">thumb_down</i>
                  </>}
                  <span className="feature-request-vote-count">{featureRequest?.totalVotes}</span>
                  </>
                }                
              </div>                  
              <div className="">
                <i className="material-icons" title="Comments">comment</i>
                <span className="feature-request-vote-count">{featureRequest?.comments?.length}</span>
              </div>    
              <div className="mt-1">
                {featureRequest?.canEdit && <div><Link to={`${featureRequest.id}/edit`} className="button mb-1">Edit</Link></div>}      
              </div>
            </div>
            <div className="feature-request-details">
              <div className="feature-request-title">{featureRequest?.title}</div>
              <div className="feature-request-section">Section: {featureRequest?.section}</div>              
              <div className="feature-request-text">
                <CkEditorContent content={featureRequest?.description} />
              </div>          
              <div className="feature-request-status-container">
                <div className="feature-request-status mr-1">Status: {featureRequest?.status}</div>
                <div className="feature-request-status">Priority: {featureRequest?.priority}</div>
              </div>
              <div className="feature-request-date-container">
                <div className="feature-request-user-name">{featureRequest?.userName}</div>
                <div className="feature-request-date">
                  Created: <Moment fromNow withTitle={true} titleFormat="Do MMMM YYYY [at] HH:mm">{featureRequest?.submitted}</Moment>
                  {featureRequest?.edited && <span className="ml-1"> Edited: <Moment fromNow withTitle={true} titleFormat="Do MMMM YYYY [at] HH:mm">{featureRequest?.edited}</Moment></span>}
                </div>
              </div>  
              <Comments comments={featureRequest?.comments} 
                        featureRequestId={featureRequest?.id} 
                        updateFeatureRequestComment={updateFeatureRequestComment} 
                        deleteFeatureRequestComment={deleteFeatureRequestComment}
                        setEditMode={setCommentEditMode} />  
              <div className="mt-1">
                <Link to={'/feature-request'}>Back to search</Link>
              </div>
            </div>
          </div>}          
    </div>
  );
}

export default ViewFeatureRequest;