import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

function SceneMenu(props) {
  const { filmId, sceneId } = props.match.params;
  const {
    scene
  } = props;

  const invalidIds = ["add", "script-days", "order"];

  if (invalidIds.indexOf(sceneId) > -1) {
    return null;
  }

  return (
    <div className="blade-menu menu">
      <p className="menu-label">Scene</p>
      <ul className="menu-list">
        <li>
          <NavLink
            to={`/films/${filmId}/scenes/${sceneId}/edit`}
            activeClassName="is-active">
            Edit Scene
          </NavLink>
        </li>
        {(scene && scene.canAccessShots) &&
          <li>
            <NavLink
              to={`/films/${filmId}/scenes/${sceneId}/shots`}
              activeClassName="is-active">
              Shots
            </NavLink>
          </li>
        }
        {(scene && scene.canAccessCharacters) &&
          <li>
            <NavLink
              to={`/films/${filmId}/scenes/${sceneId}/characters`}
              activeClassName="is-active">
              Characters
            </NavLink>
          </li>
        }
        {(scene && scene.canAccessBackgroundCharacters) &&
          <li>
            <NavLink
              to={`/films/${filmId}/scenes/${sceneId}/background-characters`}
              activeClassName="is-active">
              Background Characters
            </NavLink>
          </li>
        }
        {(scene && scene.canAccessShootingDays) &&
          <li>
            <NavLink
              to={`/films/${filmId}/scenes/${sceneId}/shooting-days`}
              activeClassName="is-active">
              Shooting Days
            </NavLink>
          </li>
        }
        {(scene && scene.hasCostumes && scene.canAccessCostumes) &&
          <li>
            <NavLink
              to={`/films/${filmId}/scenes/${sceneId}/costumes`}
              activeClassName="is-active">
              Costumes
            </NavLink>
          </li>
        }
        {(scene && scene.hasMakeups && scene.canAccessMakeups) &&
          <li>
            <NavLink
              to={`/films/${filmId}/scenes/${sceneId}/makeup`}
              activeClassName="is-active">
              Makeup
            </NavLink>
          </li>
        }
        {(scene && scene.hasProps && scene.canAccessProps) &&
          <li>
            <NavLink
              to={`/films/${filmId}/scenes/${sceneId}/props`}
              activeClassName="is-active">
              Props
            </NavLink>
          </li>
        }
        {(scene && scene.hasSetDressings && scene.canAccessSetDressings) &&
          <li>
            <NavLink
              to={`/films/${filmId}/scenes/${sceneId}/set-dressings`}
              activeClassName="is-active">
              Set Dressings
            </NavLink>
          </li>
        }

        {(scene && scene.hasEquipment && scene.canAccessEquipment) &&
          <li>
            <NavLink
              to={`/films/${filmId}/scenes/${sceneId}/equipment`}
              activeClassName="is-active">
              Equipment
            </NavLink>
          </li>
        }


      </ul>
    </div>
  );
}

function mapStateToProps(state) {
  const rootState = state.scenesState.rootState;
  return {
    scene: rootState.scene
  };
}

export default connect(mapStateToProps, {
})(SceneMenu);
