import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import { fetchCharacters } from "./../../characters/ducks/actions";
import {
  addCharacter,
  clearCharacterInScene,
  clearRedirect,
} from "./ducks/actions";
import Form from "./form";

function AddCharacter(props) {
  const { filmId, sceneId } = props.match.params;
  const {
    fetchCharacters,
    addCharacter,
    clearCharacterInScene,
    errors,
  } = props;

  useEffect(() => {
    fetchCharacters(filmId);
    clearCharacterInScene();
  }, []);

  if (props.redirect) {
    if (props.redirect.to == "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/scenes/${sceneId}/characters`;
      return <Redirect to={url} />;
    }
  }

  function onAddCharacter(character) {
    sanitiseDataTypes(character);
    addCharacter(filmId, sceneId, character);
  }

  function sanitiseDataTypes(character) {
   }

  return (
    <ScrollIntoView
      className="blade-content"
      path="/films/:filmId/scenes/:sceneId/characters/add"
      loading={false}>
      <Form
        mode="add"
        errors={errors}
        characterId={null}
        initialValues={props.character}
        characters={props.characters}
        loading={false}
        onSubmit={onAddCharacter}
        {...props}
      />
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  return {
    characters: state.charactersState.rootState.characters,
    character: state.scenesState.characterState.character,
    errors: state.scenesState.characterState.errors,
    redirect: state.scenesState.characterState.redirect,
  };
}

export default connect(mapStateToProps, {
  addCharacter,
  fetchCharacters,
  clearCharacterInScene,
  clearRedirect,
})(AddCharacter);
