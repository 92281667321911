import React, { useState } from "react";
import { Prompt } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from 'react-redux'
import Images from "../../shared/images";
import Errors from "./../../shared/errors";
import {
  renderCurrencyField,
  renderTextAreaField,
  renderTextField,
  renderUrlField
} from "./../../shared/form-helpers";
import required, { maxDecimalValue } from "../../shared/validation";
import FormErrors from "../../shared/form-errors";

let PropForm = (props) => {
  const {
    handleSubmit,
    handleDelete,
    onChangePropToSetDressing,
    onChangePropToCostume,
    pristine,
    reset,
    submitting,
    errors,
    acquisitionMethodEnum,
    mode,
    acquisitionMethodValue,
    submitFailed,
    invalid,
    filmId
  } = props;

  const thisVisualEffect = props.initialValues || {};
  const readOnly = thisVisualEffect.readOnly;

  const [editUrl, setEditUrl] = useState(false);

  const acquisitionMethodOptions = (acquisitionMethodEnum || []).map((item) => { return { value: item.value, label: item.name } });

  return (
    <>
      <Prompt when={!pristine} message="" />
      {mode === "add" && <h2 className="menu-label">Add Visual Effect</h2>}
      {mode === "edit" && <h2 className="menu-label">Edit Visual Effect</h2>}
      <div className="columns">
        <div className="column">
          {errors && <Errors errors={errors} />}
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Field
              name="name"
              label="Name"
              component={renderTextField}
              validate={[required]}
              required
              readOnly={readOnly}
            />

            <Field
              name="description"
              label="Description"
              component={renderTextAreaField}
              readOnly={readOnly}></Field>

            <Field
              name="link"
              label="Link"
              type="url"
              component={renderUrlField}
              readOnly={readOnly}
              setEditUrl={setEditUrl}
              editUrl={editUrl}
            />

            <Field
              name="budget"
              label="Budget"
              component={renderCurrencyField}
              validate={[maxDecimalValue]}
              readOnly={readOnly}></Field>


            {!readOnly && <div className="buttons mt-2">
              <button
                type="submit"
                className="button is-primary"
                disabled={(pristine || submitting) && !thisVisualEffect.enableSave}>
                Save
              </button>
              {mode === "edit" && (
                <>
                  <div>
                    <button
                      type="button"
                      className="button is-text"
                      disabled={submitting || !props.initialValues}
                      onClick={() => handleDelete(thisVisualEffect)}>
                      Delete Visual Effect
                    </button>
                  </div>
                </>
              )}
            </div>}
            <FormErrors submitFailed={submitFailed} invalid={invalid} />
          </form>
        </div>

        <div className="column">
          <Images change={props.change} initialValues={props.initialValues} readOnly={readOnly} filmId={filmId} />
        </div>
      </div>
    </>
  );
}

PropForm = reduxForm({
  form: "editPropForm",
  enableReinitialize: true
})(PropForm);

// Decorate with connect to read form values
const selector = formValueSelector('editPropForm'); // <-- same as form name
const PropFormConnect = connect(
  state => {
    // can select values individually
    const acquisitionMethodValue = selector(state, 'acquisitionMethod')

    return {
      acquisitionMethodValue
    }
  }
)(PropForm)

export default PropFormConnect
