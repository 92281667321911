import React from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
import FormErrors from "../../shared/form-errors";
import formatBytes from "../../shared/formatBytes";
import Errors from "../../shared/errors";
import { format } from 'date-fns';
import { Link } from "react-router-dom";

function UsersForm(props) {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    errors,
    submitFailed,
    invalid,
    users,
    refresh
  } = props;

  return (
    <>
      <h2 className="menu-label">Users</h2>
      {errors && <Errors errors={errors} />}
      <button className="button mb-1" onClick={refresh}>Refresh</button>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <FieldArray name="users" component={usersListForm} />
        <div>
          Total Users: {users.length}
        </div>
        <div>
          Total Users With Films: {users.filter(u => u.totalFilms > 0).length}
        </div>
        <div>
          Total Users More than one login: {users.filter(u => u.firstLogin && u.firstLogin != u.lastLogin).length}
        </div>
        <div className="buttons mt-1">
          <button
            type="submit"
            className={
              submitting ? "button is-primary is-loading" : "button is-primary"
            }
            disabled={pristine || submitting}>
            Approve
          </button>
          <button
            type="button"
            className="button is-secondary"
            disabled={pristine || submitting}
            onClick={reset}>
            Reset Values
          </button>
        </div>
        <FormErrors submitFailed={submitFailed} invalid={invalid} />
      </form>
    </>
  );
}

function usersListForm({ fields, meta: { error } }) {
  return (
    <div>
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>Email</th>
            <th>Name</th>
            <th>Referrer</th>
            <th>Country</th>
            <th>Pricing Plan</th>
            <th>First Login</th>
            <th>Last Login</th>
            <th>Total Films</th>
            <th>Onboardings</th>
            <th>Bytes Used</th>
            <th>Newsletter</th>
          </tr>
        </thead>
        <tbody>
          {(fields || []).map((user, idx, records) => {
            return (
              <tr key={idx}>
                <td>{records.get(idx).emailAddress}</td>
                <td>{records.get(idx).name}</td>
                <td>{records.get(idx).referrer}</td>
                <td>{records.get(idx).country}</td>
                <td>{records.get(idx).pricingPlan}</td>
                <td>{records.get(idx).firstLogin && format(new Date(records.get(idx).firstLogin), "dd/MM/yyyy HH:mm")}</td>
                <td>{records.get(idx).lastLogin && format(new Date(records.get(idx).lastLogin), "dd/MM/yyyy HH:mm")}</td>
                <td>{records.get(idx).totalFilms}</td>
                <td>{records.get(idx).completedUserOnboardingCount}</td>
                <td>{formatBytes(records.get(idx).bytesUsed)}</td>
                <td>{records.get(idx).emailNewsletter ? "Yes" : "No"}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {error && <div className="error">{error}</div>}
    </div>
  );
}

export default reduxForm({
  form: "registerForm",
  enableReinitialize: true
})(UsersForm);
