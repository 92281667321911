import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Download from "../../../shared/download";
import Errors from "../../../shared/errors";
import FilmanizeCheckbox from "../../../shared/filmanize-checkbox";
import FilmanizeRadio from "../../../shared/filmanize-radio";
import Loader from "./../../../shared/loader";
import InvlidShootingDayNotification from "./../invalid-shooting-day-notification";
import {
  fetchDayOutOfDays,
  updateShowOffDays,
  updateActorOrder,
  updateCrewOrder,
  updateLocationsOrder,
  downloadDayOutOfDays,
  pdfDownloaded,
  clearPdf,
  checkShootingDays,
  setError,
  DOOD_ACTOR_ORDER_NAME,
  DOOD_ACTOR_ORDER_NUMBER,
  DOOD_CREW_ORDER_NAME,
  DOOD_CREW_ORDER_ROLE,
  DOOD_CREW_ORDER_DEPARTMENT,
  DOOD_LOCATIONS_ORDER_NAME,
  DOOD_LOCATIONS_ORDER_SHOOTING_DAY
} from "./ducks/actions";
import ActorsTable from "./actors-table";
import CrewTable from "./crew-table";
import LocationsTable from "./locations-table";

function ListShootingDayActorsContainer(props) {
  const { filmId, shootingdayId } = props.match.params;
  const {
    fetchDayOutOfDays,
    updateShowOffDays,
    downloadDayOutOfDays,
    pdfDownloaded,
    clearPdf,
    shootingDaySettingsId,
    loading,
    actors,
    backgroundActors,
    crewMembers,
    locations,
    days,
    hasDates,
    hasOffDays,
    showOffDays,
    actorOrder,
    crewOrder,
    locationOrder,
    updateActorOrder,
    updateCrewOrder,
    updateLocationsOrder,
    errors,
    progress,
    download,
    readOnly,
    canAccessActors,
    canAccessBackgroundActors,
    canAccessCrew,
    checkShootingDays,
    shootingDayErrors,
    saving,
    intitialIncludeActors,
    intitialIncludeBackgroundActors,
    intitialIncludeCrew,
    intitialIncludePhoneNumbers,
    intitialIncludeLocations,
    setError
  } = props;

  const [showNonShootingDays, setShowNonWorkingDays] = useState<boolean>(showOffDays);
  const [actorOrderBy, setActorsOrderBy] = useState<string>(actorOrder);
  const [crewOrderBy, setCrewOrderBy] = useState<string>(crewOrder);
  const [locationsOrderBy, setLocationsOrderBy] = useState<string>(locationOrder);
  const [fixIssues, setFixIssues] = useState<boolean>(false);
  const [showIssues, setShowFixIssues] = useState<boolean>(false);
  const [includeActors, setIncludeActors] = useState<boolean>(true);
  const [includeBackgroundActors, setIncludeBackgroundActors] = useState<boolean>(true);
  const [includeCrew, setIncludeCrew] = useState<boolean>(true);
  const [includePhoneNumbers, setIncludePhoneNumbers] = useState<boolean>(true);
  const [includeLocations, setIncludeLocations] = useState<boolean>(true);

  useEffect(() => {
    fetchDayOutOfDays(filmId, shootingdayId);
    clearPdf();
  }, []);

  useEffect(() => {
    setShowNonWorkingDays(showOffDays);
  }, [showOffDays]);

  useEffect(() => {
    setActorsOrderBy(actorOrder);
  }, [actorOrder]);

  useEffect(() => {
    setCrewOrderBy(crewOrder);
  }, [crewOrder]);

  useEffect(() => {
    setLocationsOrderBy(locationOrder);
  }, [locationOrder]);

  useEffect(() => {
    setIncludeActors(intitialIncludeActors);
  }, [intitialIncludeActors]);

  useEffect(() => {
    setIncludeBackgroundActors(intitialIncludeBackgroundActors);
  }, [intitialIncludeBackgroundActors]);

  useEffect(() => {
    setIncludeCrew(intitialIncludeCrew);
  }, [intitialIncludeCrew]);

  useEffect(() => {
    setIncludePhoneNumbers(intitialIncludePhoneNumbers);
  }, [intitialIncludePhoneNumbers]);

  useEffect(() => {
    setIncludeLocations(intitialIncludeLocations);
  }, [intitialIncludeLocations]);

  function renderLoading() {
    return <Loader />;
  }

  const onShowNonShootingDaysChange = (value) => {
    setShowNonWorkingDays(value);
    updateShowOffDays(filmId, shootingDaySettingsId, value);
    clearPdf();
  };

  const onOrderActorByChange = (order: string) => {
    setActorsOrderBy(order);
    updateActorOrder(filmId, shootingDaySettingsId, order);
    clearPdf();
  };

  const onOrderCrewByChange = (order: string) => {
    setCrewOrderBy(order);
    updateCrewOrder(filmId, shootingDaySettingsId, order);
    clearPdf();
  };

  const onOrderLocationsByChange = (order: string) => {
    setLocationsOrderBy(order);
    updateLocationsOrder(filmId, shootingDaySettingsId, order);
    clearPdf();
  };

  if (actorOrderBy === DOOD_ACTOR_ORDER_NAME) {
    actors.sort((a, b) => {
      return a.actorName.toLowerCase().localeCompare(b.actorName.toLowerCase());
    });
  } else if (actorOrderBy === DOOD_ACTOR_ORDER_NUMBER) {
    actors.sort((a, b) => {
      return a.actorNumber - b.actorNumber;
    });
  }

  if (crewOrderBy === DOOD_CREW_ORDER_NAME) {
    crewMembers.sort((a, b) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });
  } else if (crewOrderBy === DOOD_CREW_ORDER_ROLE) {
    crewMembers.sort((a, b) => {
      return a.crewRoleName.toLowerCase().localeCompare(b.crewRoleName.toLowerCase());
    });
  } else if (crewOrderBy === DOOD_CREW_ORDER_DEPARTMENT) {
    crewMembers.sort((a, b) => {
      return a.crewDepartmentName.toLowerCase().localeCompare(b.crewDepartmentName.toLowerCase());
    });
  }

  if (locationsOrderBy === DOOD_LOCATIONS_ORDER_NAME) {
    locations.sort((a, b) => {
      return a.locationName.toLowerCase().localeCompare(b.locationName.toLowerCase());
    });
  } else if (locationsOrderBy === DOOD_LOCATIONS_ORDER_SHOOTING_DAY) {
    locations.sort((a, b) => {
      const aStart = a.days.findIndex(d => d.start);
      const bStart = b.days.findIndex(d => d.start);
      if (aStart > bStart) {
        return 1;
      } else if (aStart < bStart) {
        return -1;
      }

      return a.shootingOrder - b.shootingOrder;
    });
  }

  console.log("locations", locations);

  const onDownloadFile = () => {
    if (!includeActors && !includeBackgroundActors && !includeCrew && !includePhoneNumbers && !includeLocations) {
      setError("Must have at least one include");
    } else {
      downloadDayOutOfDays(filmId, includeActors, includeBackgroundActors, includeCrew, includePhoneNumbers, includeLocations);
    }
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  }

  const onCheckShootingDays = () => {
    checkShootingDays(filmId, fixIssues);
    setShowFixIssues(true);
  }

  const onIncludeActorsChange = (value) => {
    setIncludeActors(value);
    clearPdf();
  }

  const onIncludeBackgroundActorsChange = (value) => {
    setIncludeBackgroundActors(value);
    clearPdf();
  }

  const onIncludeCrewChange = (value) => {
    setIncludeCrew(value);
    clearPdf();
  }

  const onIncludePhoneNumbersChange = (value) => {
    setIncludePhoneNumbers(value);
    clearPdf();
  }

  const onIncludeLocationsChange = (value) => {
    setIncludeLocations(value);
    clearPdf();
  }

  function renderShootingDays() {
    return (
      <div className="blade-content wider">
        <p className="menu-label">Day out of Days</p>
        <Errors errors={errors} />
        {saving && <Loader />}
        {!actors?.length && !crewMembers?.length && (
          <div className="notification">No actors ore crew assigned yet</div>
        )}
        {(!!actors?.length || !!crewMembers?.length) && (
          <>
            {hasOffDays && <div className="mb-1">
              <FilmanizeCheckbox label="Show off days" checked={showNonShootingDays} onChange={onShowNonShootingDaysChange} />
            </div>}
            <label className="label">Actors</label>
            <div className="day-out-days-order-by">
              <FilmanizeRadio text="Order by Name" value={DOOD_ACTOR_ORDER_NAME} checked={actorOrderBy === DOOD_ACTOR_ORDER_NAME} onChange={onOrderActorByChange} />
              <FilmanizeRadio text="Order by Number" value={DOOD_ACTOR_ORDER_NUMBER} checked={actorOrderBy === DOOD_ACTOR_ORDER_NUMBER} onChange={onOrderActorByChange} />
            </div>

            <div>
              <ActorsTable
                actors={actors}
                showNumber={true}
                sectionLink="actors"
                days={days}
                filmId={filmId}
                hasOffDays={hasOffDays}
                hasDates={hasDates}
                showNonShootingDays={showNonShootingDays}
                canAccessActors={canAccessActors}
                scrollWithOffset={scrollWithOffset} />
            </div>

            {backgroundActors?.length > 0 && <> <label className="label mt-1">Background Actors</label>
              <div>
                <ActorsTable
                  actors={backgroundActors}
                  showNumber={false}
                  sectionLink="background-actors"
                  days={days}
                  filmId={filmId}
                  hasOffDays={hasOffDays}
                  hasDates={hasDates}
                  showNonShootingDays={showNonShootingDays}
                  canAccessActors={canAccessBackgroundActors}
                  scrollWithOffset={scrollWithOffset} />
              </div>
            </>}

            <label className="label mt-1">Crew</label>
            <div className="day-out-days-order-by">
              <FilmanizeRadio text="Order by Name" value={DOOD_CREW_ORDER_NAME} checked={crewOrderBy === DOOD_CREW_ORDER_NAME} onChange={onOrderCrewByChange} />
              <FilmanizeRadio text="Order by Department" value={DOOD_CREW_ORDER_DEPARTMENT} checked={crewOrderBy === DOOD_CREW_ORDER_DEPARTMENT} onChange={onOrderCrewByChange} />
              <FilmanizeRadio text="Order by Role" value={DOOD_CREW_ORDER_ROLE} checked={crewOrderBy === DOOD_CREW_ORDER_ROLE} onChange={onOrderCrewByChange} />
            </div>
            <div><CrewTable crewMembers={crewMembers} days={days} filmId={filmId} hasOffDays={hasOffDays} hasDates={hasDates} showNonShootingDays={showNonShootingDays} canAccessCrew={canAccessCrew} scrollWithOffset={scrollWithOffset} /></div>

            {locations?.length > 0 && <> <label className="label mt-1">Locations</label>
              <div className="day-out-days-order-by">
                <FilmanizeRadio text="Order by Name" value={DOOD_LOCATIONS_ORDER_NAME} checked={locationsOrderBy === DOOD_LOCATIONS_ORDER_NAME} onChange={onOrderLocationsByChange} />
                <FilmanizeRadio text="Order by Shooting Order" value={DOOD_LOCATIONS_ORDER_SHOOTING_DAY} checked={locationsOrderBy === DOOD_LOCATIONS_ORDER_SHOOTING_DAY} onChange={onOrderLocationsByChange} />
              </div>
              <div>
                <LocationsTable
                  locations={locations}
                  showNumber={false}
                  sectionLink="locations"
                  days={days}
                  filmId={filmId}
                  hasOffDays={hasOffDays}
                  hasDates={hasDates}
                  showNonShootingDays={showNonShootingDays}
                  canAccessActors={canAccessBackgroundActors}
                  scrollWithOffset={scrollWithOffset} />
              </div>
            </>}

            <label className="label mt-2">Download</label>
            <div className="buttons mt-1">
              <span className="mr-1"><FilmanizeCheckbox label="Include Actors" onChange={onIncludeActorsChange} checked={includeActors} /></span>
              {backgroundActors?.length > 0 && <span className="mr-1"><FilmanizeCheckbox label="Include Background Actors" onChange={onIncludeBackgroundActorsChange} checked={includeBackgroundActors} /></span>}
              <span className="mr-1"><FilmanizeCheckbox label="Include Crew" onChange={onIncludeCrewChange} checked={includeCrew} /></span>
              <span className="mr-1"><FilmanizeCheckbox label="Include Phone Numbers" onChange={onIncludePhoneNumbersChange} checked={includePhoneNumbers} /></span>
              <span className="mr-1"><FilmanizeCheckbox label="Include Locations" onChange={onIncludeLocationsChange} checked={includeLocations} /></span>
            </div>

            <div className="buttons mt-1">
              <Download
                className={`${saving ? "is-loading" : ""}`}
                progress={progress}
                clearPdf={pdfDownloaded}
                download={download}
                downloadFile={onDownloadFile} />
            </div>

            <div className="buttons mt-2">
              <button className={`button ${saving ? "is-loading" : ""}`} onClick={onCheckShootingDays}>Check Shooting Days</button>
              <FilmanizeCheckbox label="Fix Issues" onChange={setFixIssues} checked={fixIssues} />
            </div>
            {showIssues && <div>
              {!saving && shootingDayErrors?.length === 0 && <div>No issues found</div>}
              {shootingDayErrors.map((error, index) => (
                <div key={index}>{error}</div>
              ))}
            </div>}
          </>
        )}
      </div>
    );
  }

  return loading ? renderLoading() : renderShootingDays();
}

function mapStateToProps(state) {
  const { dayOutOfDaysState } = state.shootingdaysState;
  return {
    actors: state.shootingdaysState.dayOutOfDaysState.actors,
    backgroundActors: state.shootingdaysState.dayOutOfDaysState.backgroundActors,
    crewMembers: state.shootingdaysState.dayOutOfDaysState.crewMembers,
    locations: state.shootingdaysState.dayOutOfDaysState.locations,
    days: state.shootingdaysState.dayOutOfDaysState.days,
    hasOffDays: state.shootingdaysState.dayOutOfDaysState.hasOffDays,
    showOffDays: state.shootingdaysState.dayOutOfDaysState.showOffDays,
    shootingDaySettingsId: state.shootingdaysState.dayOutOfDaysState.shootingDaySettingsId,
    hasDates: state.shootingdaysState.dayOutOfDaysState.hasDates,
    actorOrder: state.shootingdaysState.dayOutOfDaysState.actorOrder,
    crewOrder: state.shootingdaysState.dayOutOfDaysState.crewOrder,
    locationOrder: state.shootingdaysState.dayOutOfDaysState.locationOrder,
    readOnly: state.shootingdaysState.dayOutOfDaysState.readOnly,
    loading: state.shootingdaysState.dayOutOfDaysState.loading,
    errors: state.shootingdaysState.dayOutOfDaysState.errors,
    download: state.shootingdaysState.dayOutOfDaysState.download,
    progress: state.shootingdaysState.dayOutOfDaysState.progress,
    canAccessActors: state.shootingdaysState.dayOutOfDaysState.canAccessActors,
    canAccessBackgroundActors: state.shootingdaysState.dayOutOfDaysState.canAccessBackgroundActors,
    canAccessCrew: state.shootingdaysState.dayOutOfDaysState.canAccessCrew,
    canAccessLocations: state.shootingdaysState.dayOutOfDaysState.canAccessLocations,
    shootingDayErrors: state.shootingdaysState.dayOutOfDaysState.shootingDayErrors,
    saving: state.shootingdaysState.dayOutOfDaysState.saving,
    intitialIncludeActors: dayOutOfDaysState.includeActors,
    intitialIncludeBackgroundActors: dayOutOfDaysState.includeBackgroundActors,
    intitialIncludeCrew: dayOutOfDaysState.includeCrew,
    intitialIncludePhoneNumbers: dayOutOfDaysState.includePhoneNumbers,
    intitialIncludeLocations: dayOutOfDaysState.includeLocations
  };
}

export default connect(mapStateToProps, {
  fetchDayOutOfDays,
  updateShowOffDays,
  updateActorOrder,
  updateCrewOrder,
  updateLocationsOrder,
  downloadDayOutOfDays,
  pdfDownloaded,
  clearPdf,
  checkShootingDays,
  setError
})(ListShootingDayActorsContainer);
