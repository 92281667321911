import React from "react";
import { Route } from "react-router-dom";
import { PrivateRoute } from "../../../shared/private-route";
import ListMakeupsContainer from "./list-container";

const MakeupRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/scenes/:sceneId/makeup"
        component={ListMakeupsContainer}
      />
    </>
  );
};

export default MakeupRoutes;
