import {
  clearNotifications,
  showError,
  showInfo
} from "../../../notifications/ducks/actions";
import getApiClient from "../../../shared/api";

export const FETCH_ADMIN_CHANGE_LOG = "FETCH_ADMIN_CHANGE_LOG";
export const FETCH_ADMIN_CHANGE_LOGS = "FETCH_ADMIN_CHANGE_LOGS";

export function fetchChangeLogs() {
  return (dispatch) => {
    dispatch({
      type: FETCH_ADMIN_CHANGE_LOGS,
      payload: getApiClient().get(`/changeLog/admin`)
    });
  };
}

export function fetchChangeLog(id) {
  return (dispatch) => {
    dispatch({
      type: FETCH_ADMIN_CHANGE_LOG,
      payload: getApiClient().get(`/changeLog/${id}/admin`)
    });
  };
}

export function clearChangeLog(apiVersion) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_CHANGE_LOG",
      meta: { apiVersion }
    });
  };
}

export function addChangeLog(changeLog) {
  return (dispatch) => {
    dispatch({
      type: "ADD_CHANGE_LOG",
      payload: getApiClient().post(`/changeLog/`, changeLog),
      meta: { changeLog }
    })
      .then((response) => {
        dispatch(showInfo(`Added change log ${changeLog.headline}`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding change log`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function updateChangeLog(changeLogId, changeLog) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_CHANGE_LOG",
      payload: getApiClient().put(`/changeLog/${changeLogId}`, changeLog),
      meta: { changeLog }
    })
      .then((response) => {
        dispatch(showInfo(`Updated change log ${changeLog.versionName}`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating change log`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function sendChangeLog(changeLogId, changeLog, actualSend) {
  return (dispatch) => {
    dispatch({
      type: "SEND_CHANGE_LOG",
      payload: getApiClient().patch(`/changeLog/${changeLogId}/send`, { actualSend: actualSend}),      
    })
      .then((response) => {
        dispatch(showInfo(`${actualSend ? "Sent" : "Updated"} change log ${changeLog.versionName}`));
      })
      .catch((response) => {
        dispatch(showError(`Error sedning change log`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT_CHANGE_LOG"
    });
  };
}
