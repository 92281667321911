import getApiClient from "./../../../../shared/api";
import { showInfo, showError, clearNotifications } from "./../../../../notifications/ducks/actions";

export function fetchPropsInScene(filmId, sceneId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_PROPS_IN_SCENE",
      payload: getApiClient().get(`/film/${filmId}/Scene/${sceneId}/props`),
    });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT",
    });
  };
}
