import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import FilmanizeApp from "./app/index";
import UserConfirmation from "./app/user-confirmation";
import "./index.scss";
import auth from "./shared/auth";
import store from "./store";
import TestComponent from "./films/test";
import Marketing from "./marketing/index";
import ConfirmationPage from "./account/register/confirmation";
import LoginPage from "./account/login/container";
import RegisterPage from "./account/register/container";
import PaymentPage from "./account/payment/container";
import PaymentComplete from "./account/payment/complete";
import ConfirmEmailPage from "./account/confirm-email/container";
import ForgotPasswordPage from "./account/forgot-password/container";
import ResetPasswordPage from "./account/reset-password/container";
import ContactRoute from "./static/contact/stand-alone";
import CookieConsent from "./shared/cookie-consent";
import ConfirmCallSheet from "./films/shooting-days/confirm/container";
import DietaryRequirements from "./films/scheduling/dietary-requirements/container";
import PotentialDates from "./films/scheduling/potential-dates/potential-dates-container";
import getConfig from "./app/config";
import './langauge/i18n';

const config = getConfig();
(window as any).apiUrl = config.apiUrl;
(window as any).googleApiKey = config.googleApiKey;

const stripePromise = loadStripe(config.stripePublishableKey);

const user = auth.getUser();
if (user) {
  store.dispatch({ type: "USER_LOADED", payload: user });
}

/*
store.subscribe(() => {
  setAuthState(store.getState())
});

function setAuthState(state) {
  try {
    localStorage.setItem('state.auth.tokens', JSON.stringify((state.auth || {}).tokens));    
  } catch (err) { return undefined; }
}

function getAuthState() {
  try {
    const authTokens = localStorage.getItem('state.auth.tokens');
    const tokens = authTokens || JSON.parse(authTokens!) || undefined;
    const authUser = localStorage.getItem('state.auth.user');
    const user = authUser || JSON.parse(authUser!) || undefined;

    return { auth: { tokens, user } }
  } catch (err) { return undefined; }
}
*/

const AnonymousPages = () => {

  return (
    <BrowserRouter
      getUserConfirmation={(message, callback) =>
        UserConfirmation(message, callback)
      }>
      <Provider store={store}>
        <GoogleReCaptchaProvider
          reCaptchaKey={config.reCaptchaSiteKey}
          useRecaptchaNet
          container={{
            parameters: {
              theme: 'dark', // optional, default undefined
            }
          }}
          scriptProps={{ async: true, defer: true, appendTo: 'body' }}>
          <Elements stripe={stripePromise}>
            <Switch>
              <Route exact path="/referral/:referralCode" component={Marketing} />
              <Route exact path="/confirm-call-sheet/:languageCode/:token" component={ConfirmCallSheet} />
              <Route exact path="/dietary-requirements/:languageCode/:token" component={DietaryRequirements} />
              <Route exact path="/potential-dates/:languageCode/:token" component={PotentialDates} />
              <Route exact path="/defaultsite" component={Marketing} />
              <Route exact path="/register" component={RegisterPage} />
              <Route exact path="/payment" component={PaymentPage} />
              <Route exact path="/payment/complete/:pricingPlan" component={PaymentComplete} />
              <Route exact path="/register/:pricingPlan" component={RegisterPage} />
              <Route exact path="/confirmation/:pricingPlan" component={ConfirmationPage} />
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/confirm-email" component={ConfirmEmailPage} />
              <Route exact path="/forgot-password" component={ForgotPasswordPage} />
              <Route exact path="/reset-password" component={ResetPasswordPage} />
              <Route exact path="/contact" component={ContactRoute} />
              <FilmanizeApp applicationInsightsKey={config.applicationInsightsKey} />
            </Switch>
          </Elements>
        </GoogleReCaptchaProvider>
      </Provider>
    </BrowserRouter>)
}

export default AnonymousPages;