import React, { useState } from "react";
import { Prompt } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from 'react-redux'
import FormErrors from "./../../../../../shared/form-errors";
import Errors from "./../../../../../shared/errors";
import {
  renderCheckboxGroup,
  renderMilkTypesCheckboxGroup,
  renderSingleReactSelect,
  renderTextAreaField,
  renderTextField,
  renderUrlField
} from "./../../../../../shared/form-helpers";
import Images from "./../../../../../shared/images";
import required from "./../../../../../shared/validation";

let CrewMemberForm = (props) => {
  const {
    handleSubmit,
    handleDelete,
    pristine,
    reset,
    submitting,
    dietaryRequirementEnum,
    errors,
    mode,
    filmId,
    submitFailed,
    invalid,
    genderEnum,
    languageHasGender,
    drinks,
    milkTypes,
    selectedDrinks,
    selectedDietaryRequirements
  } = props;
  const thisMember = props.initialValues || {};
  const readOnly = thisMember.readOnly;

  const [editMandyUrl, setEditMandyUrl] = useState(false);
  const [editStarNowUrl, setEditStarNowUrl] = useState(false);

  const phoneNumberRequired = props.phoneNumberRequired || thisMember.phoneNumberRequired;
  const genderOptions = (genderEnum || []).map((item) => { return { value: item.value, label: item.name } });
  const drinkOptions = (drinks || []).map((item) => { return { value: item.id, name: item.name } });
  const milkTypeOptions = (milkTypes || []).map((item) => { return { value: item.id, name: item.name, vegan: item.vegan } });

  let showMilk = false;
  if (selectedDrinks) {
    selectedDrinks.forEach(drinkId => {
      const drink = drinks.find(d => d.id === drinkId);
      if (drink) {
        if (drink.canHaveMilk) {
          showMilk = true;
        }
      }
    });
  }

  const showDairyMilk = !selectedDietaryRequirements?.some(r => r === 2 || r === 8);

  return (
    <>
      <Prompt when={!pristine} message="" />
      {mode === "add" && <h2 className="menu-label">Add Crew Member</h2>}
      {mode === "edit" && <h2 className="menu-label">Edit Crew Member</h2>}
      {errors && <Errors errors={errors} />}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div className="columns">
          <div className="column">
            <Field
              name="firstName"
              label="First Name"
              component={renderTextField}
              validate={[required]}
              required
              readOnly={readOnly}
            />
          </div>
          <div className="column">
            <Field
              name="lastName"
              label="Last Name"
              component={renderTextField}
              validate={[required]}
              required
              readOnly={readOnly}
            />
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <Field
              name="emailAddress"
              label="Email"
              component={renderTextField}
              validate={[required]}
              required
              readOnly={readOnly}
            />
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <Field name="phoneNumber"
              label="Phone"
              component={renderTextField}
              readOnly={readOnly}
              required={phoneNumberRequired}
              validate={phoneNumberRequired && [required]} />
          </div>
          <div className="column">
            <Field
              name="gender"
              label="Gender"
              component={renderSingleReactSelect}
              isMulti={false}
              options={genderOptions}
              readOnly={readOnly}
              includeEmpty={!languageHasGender}
              required={languageHasGender}
              validate={languageHasGender && [required]}
            />
          </div>
        </div>

        <Field
          name="mandyUrl"
          label="Mandy profile"
          placeholder="https://www.mandy.com/"
          component={renderUrlField}
          editUrl={editMandyUrl}
          setEditUrl={setEditMandyUrl}
          readOnly={readOnly}
        />

        <Field
          name="starNowUrl"
          label="Starnow profile"
          placeholder="https://www.starnow.com/"
          component={renderUrlField}
          editUrl={editStarNowUrl}
          setEditUrl={setEditStarNowUrl}
          readOnly={readOnly}
        />
        {mode === "edit" &&
          <Field
            label="Roles"
            name="roleIds"
            component={renderCheckboxGroup}
            options={thisMember.roles}
            textProperty="role"
            valueProperty="id"
            readOnly={readOnly}
          />
        }
        <Field
          label="Dietary requirements"
          name="dietaryRequirementsArray"
          component={renderCheckboxGroup}
          options={dietaryRequirementEnum}
          value={thisMember.dietaryRequirement}
          readOnly={readOnly}
        />

        <Field
          name="dietaryRequirementDetails"
          label="Dietary Requirement Details"
          component={renderTextAreaField}
          readOnly={readOnly}
        />

        <Field
          label="Drinks"
          name="drinkIds"
          component={renderCheckboxGroup}
          options={drinkOptions}
          value={thisMember.drinks}
          readOnly={readOnly}
        />
        {showMilk &&
          <Field
            label="Milk Types"
            name="milkTypeIds"
            component={renderMilkTypesCheckboxGroup}
            showDairy={showDairyMilk}
            options={milkTypeOptions}
            value={thisMember.miltTypes}
            readOnly={readOnly}
          />
        }

        <Images change={props.change} initialValues={props.initialValues} readOnly={readOnly} filmId={filmId} />
        {!readOnly && <div className="buttons mt-1">
          <button
            type="submit"
            className="button is-primary"
            disabled={pristine || submitting}>
            Save
          </button>

          {mode === "edit" && (
            <button
              type="button"
              className="button is-text"
              disabled={submitting || !props.initialValues}
              onClick={() => handleDelete(thisMember)}>
              Delete
            </button>
          )}
        </div>}
        <FormErrors submitFailed={submitFailed} invalid={invalid} />
      </form>
    </>
  );
}

CrewMemberForm = reduxForm({
  form: "editCrewMemberForm",
  enableReinitialize: true
})(CrewMemberForm);

// Decorate with connect to read form values
const selector = formValueSelector('editCrewMemberForm'); // <-- same as form name
const EditCrewMemberFormConnect = connect(
  state => {
    // can select values individually
    const selectedDrinks = selector(state, 'drinkIds')
    const selectedDietaryRequirements = selector(state, 'dietaryRequirementsArray')

    return {
      selectedDrinks,
      selectedDietaryRequirements
    }
  }
)(CrewMemberForm)

export default EditCrewMemberFormConnect;