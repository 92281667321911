import React, { useEffect } from "react";
import { Prompt } from "react-router-dom";
import { Field, FieldArray, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import FormErrors from "../../shared/form-errors";
import HelpText from "../../shared/help-text";
import { Address } from "../../shared/Map/google-address-parser";
import LocationSearchInput from "../../shared/Map/location-search-input";
import Map from "../../shared/Map/Map";
import Errors from "./../../shared/errors";
import {
  renderCheckbox,
  renderRadioButton,
  renderSelectField,
  renderSingleReactSelect,
  renderTextAreaField,
  renderTextField
} from "./../../shared/form-helpers";
import Images from "./../../shared/images";
import required from "./../../shared/validation";
import AddressComponent from "./address";
import CarPark from "./car-park";
import Radio from "../../shared/filmanize-radio";
import Onboarding from '../../shared/onboarding/onboarding';
import LocationAvailability from "./location-availability";

let LocationForm = (props) => {
  const {
    handleSubmit,
    handleDelete,
    pristine,
    reset,
    submitting,
    getFormValues,
    interiorExteriorEnum,
    mode,
    errors,
    onFetchHospitals,
    onSetSelectedHospital,
    selectedHospital,
    onFindParking,
    onSetSelectedCarPark,
    onUpdateLatLng,
    resetZoom,
    selectedCarPark,
    fetchingHospitalList,
    fetchingCarParkList,
    searched,
    setSearched,
    submitFailed,
    invalid,
    filmId,
    onboardingSteps,
    countries,
    defaultCountryId,
    noHospitalValue,
    availableDates,
    daysOfWeek,
    updateEvent,
    addDays,
    deleteDay,
    deleteDays,
    changeDayOfWeek,
    showAvailability,
    hash,
    whatThreeWordsLookup
  } = props;
  const thisLocation = props.initialValues || {};
  const readOnly = thisLocation.readOnly;

  const interiorExteriorOptions = (interiorExteriorEnum || []).map((item) => {
    return { value: item.value, label: item.name };
  });

  useEffect(() => {
    if (thisLocation.parkingAddress && !pristine) {
      const parking = thisLocation.parkingAddress;
      props.change("parkingAddress.latitude", parking.latitude);
      props.change("parkingAddress.longitude", parking.longitude);
      props.change("parkingAddress.postcode", parking.postCode);
      props.change("parkingAddress.country", parking.country);
      props.change("parkingAddress.addressLines", parking.addressLines);
      props.change("parkingAddress.placeId", parking.placeId);
    }
  }, [thisLocation.parkingAddress]);

  useEffect(() => {
    if (availableDates?.length > 0) {
      props.change("availableDates", availableDates);
    }
  }, [availableDates]);

  useEffect(() => {
    if (daysOfWeek?.length > 0) {
      props.change("daysOfWeek", daysOfWeek);
    }
  }, [daysOfWeek]);

  function onUpdatePosition(coordinates) {
    updateLatLng(coordinates.lat, coordinates.lng);
  }

  function updateLatLng(lat, lng) {
    onUpdateLatLng(lat, lng);
    props.change("address.latitude", lat);
    props.change("address.longitude", lng);
  }

  function onUpdateAddress(address: Address) {
    setSearched(true);
    props.change("address.country", address.country);
    props.change("address.postCode", address.postCode);
    if (address.addressLines) {
      if (address.addressLines.length > 0) {
        props.change("name", address.addressLines[0]);
      }

      props.change("address.addressLines", address.addressLines?.join("\n"));
    }
    props.change("address.latitude", address.latitude);
    props.change("address.longitude", address.longitude);
    onUpdateLatLng(address.latitude, address.longitude);
    onFetchHospitals({
      address: { latitude: address.latitude, longitude: address.longitude, countryCode: address.countryCode }
    });
  }

  const [tabIndex, setTabIndex] = React.useState(hash == "#availability" ? 5 : 0);

  const tabs = ["Address", "Images", "Parking", "Hospital *", "Sub Locations"];

  if (showAvailability) {
    tabs.push("Availability");
  }

  function onChangeTab(tabIdx: number) {
    setTabIndex(tabIdx);
  }

  function onSelectedHospitalChanged(value) {
    const hospitalId = value;
    setSelectedHospitalUsingId(hospitalId);
  }

  function onSetSelectedHospitalChanged(hospital) {
    setSelectedHospitalUsingId(hospital?.organisationId);
  }

  function setSelectedHospitalUsingId(hospitalId: number) {
    const hospital = thisLocation.hospitals.find(
      (h) => h.organisationId === hospitalId
    );

    if (hospital) {
      const hospitalAddressLines: string[] = [];
      hospitalAddressLines.push(hospital.organisationName);
      hospitalAddressLines.push(hospital.address1);
      if (hospital.address2) {
        hospitalAddressLines.push(hospital.address2);
      }
      if (hospital.address3) {
        hospitalAddressLines.push(hospital.address3);
      }
      hospitalAddressLines.push(hospital.city);
      hospitalAddressLines.push(hospital.county);

      props.change("hospitalAddress", hospital);

      props.change("hospitalAddress.addressLines", hospitalAddressLines);
      props.change("hospitalAddress.postCode", hospital.postCode);
      props.change("hospitalAddress.country", hospital.country);
      props.change("hospitalAddress.latitude", hospital.latitude);
      props.change("hospitalAddress.longitude", hospital.longitude);
      props.change("hospitalAddress.placeId", hospitalId);
      onSetSelectedHospital(hospital);
    }
  }

  function onSelectedCarParkChanged(value) {
    onSetSelectedCarPark(value);
  }

  function onSetSelectedCarParkChanged(carPark) {
    onSetSelectedCarPark(carPark?.place_id);
  }

  function onResetZoom() {
    resetZoom();
  }

  const hospitalMarkers: any[] = thisLocation.hospitals
    ? thisLocation.hospitals.map((hospital) => {
      return {
        organisationId: hospital.organisationId,
        position: { lat: hospital.latitude, lng: hospital.longitude },
        selected: hospital.selected
      };
    })
    : [];

  const parkingMarkers: any[] = props.carParks
    ? props.carParks.map((carPark) => {
      return {
        place_id: carPark.place_id,
        position: {
          lat: carPark.position.latitude,
          lng: carPark.position.longitude
        },
        selected: carPark.selected
      };
    })
    : [];

  const renderSubLocations = ({ fields, meta: { error }, readOnly }) => (
    <ul>
      {!readOnly && (
        <li>
          <button
            type="button"
            className="button is-small"
            onClick={() => fields.push()}>
            Add Sub Location
          </button>
        </li>
      )}
      {fields.map((subLocation, index) => (
        <li key={index} className="mt-1">
          <nav className="level">
            <div className="level-left">
              <div className="level-item">
                <Field
                  name={`${subLocation}.name`}
                  type="text"
                  component={renderTextField}
                  label={`Sub Location #${index + 1}`}
                  readOnly={readOnly}
                />
              </div>
              <div className="level-item">
                {!readOnly && (
                  <a
                    onClick={() => fields.remove(index)}
                    style={{ cursor: "pointer" }}>
                    <i className="material-icons">delete</i>
                  </a>
                )}
              </div>
            </div>
          </nav>
        </li>
      ))}
      {error && <li className="error">{error}</li>}
    </ul>
  );

  const onHospitalClick = (location: any) => {
    onSetSelectedHospitalChanged(location);
  };

  const onParkingClick = (location: any) => {
    onSetSelectedCarParkChanged(location);
  };

  return (
    <>
      <Prompt when={!pristine} message="" />
      {mode === "add" && <h2 className="menu-label">Add Location</h2>}
      {mode === "edit" && <h2 className="menu-label">Edit Location</h2>}
      {errors && <Errors errors={errors} />}
      <form autoComplete="off" onSubmit={handleSubmit}>
        {(searched || mode === "edit") && (
          <Map
            //loadingElement={<div style={{ height: `100%` }} />}
            //containerElement={<div style={{ height: `400px` }} />}
            //mapElement={<div style={{ height: `100%` }} />}
            location={{
              lat: thisLocation?.address.latitude ?? 0,
              lng: thisLocation?.address.longitude ?? 0
            }}

            // updateMaker={onUpdatePosition}
            hospitalMarkers={hospitalMarkers}
            // setSelectedHospital={onSetSelectedHospitalChanged}
            selectedHospital={selectedHospital}
            parkingMarkers={parkingMarkers}
            // setSelectedCarPark={onSetSelectedCarParkChanged}
            selectedCarPark={props.selectedCarPark}
            // zoomMap={props.zoomMap}
            // resetZoom={onResetZoom}
            // readOnly={readOnly}
            onHospitalClick={onHospitalClick}
            onParkingClick={onParkingClick}
          ></Map>
        )}

        {(!readOnly && !searched && mode !== "edit") && (
          <div className="mt-1 mb-1">
            <LocationSearchInput
              onUpdatePosition={onUpdatePosition}
              onUpdateAddress={onUpdateAddress}
              countries={countries}
              defaultCountryId={defaultCountryId}
              readOnly={readOnly}
              whatThreeWordsLookup={whatThreeWordsLookup} />
          </div>
        )}

        {(searched || mode === "edit") && (
          <>
            <div className="tabs mt-1">
              <ul>
                {tabs.map((tab, idx) => {
                  let className = tabIndex === idx ? "is-active" : undefined;
                  className += " " + onboardingSteps[idx].target.substring(1)
                  return (
                    <li className={className} key={idx}>
                      <a onClick={() => onChangeTab(idx)}>{tab}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
            {tabIndex === 0 && (
              <div>
                {!readOnly && (
                  <div className="mt-1 mb-1">
                    <LocationSearchInput
                      onUpdatePosition={onUpdatePosition}
                      onUpdateAddress={onUpdateAddress}
                      countries={countries}
                      defaultCountryId={defaultCountryId}
                      readOnly={readOnly}
                      whatThreeWordsLookup={whatThreeWordsLookup}
                      label="Search to change" />
                  </div>
                )}
                <Field
                  name="name"
                  label="Location Name"
                  component={renderTextField}
                  validate={[required]}
                  required
                  readOnly={readOnly}
                />
                <Field
                  name="address.addressLines"
                  label="Address"
                  component={renderTextAreaField}
                  validate={[required]}
                  required
                  readOnly={readOnly}
                />
                <div className="field-body">
                  <Field
                    name="address.postCode"
                    label="Postcode"
                    component={renderTextField}
                    validate={[required]}
                    required
                    readOnly={readOnly}
                  />
                  <Field
                    name="address.country"
                    label="Country"
                    component={renderTextField}
                    readOnly={readOnly}
                  />
                  <Field
                    name="address.latitude"
                    label="Lat"
                    component={renderTextField}
                    readOnly={readOnly}
                  />
                  <Field
                    name="address.longitude"
                    label="Lng"
                    component={renderTextField}
                    readOnly={readOnly}
                  />
                </div>

                <Field
                  name="interiorExterior"
                  label="Interior/Exterior"
                  component={renderSingleReactSelect}
                  options={interiorExteriorOptions}
                  value={thisLocation.interiorExterior}
                  readOnly={readOnly}></Field>
                <a target="_blank" href={`https://www.google.com/maps/dir/?api=1&destination=${thisLocation.address.latitude},${thisLocation.address.longitude}`} className="button">Directions</a>
              </div>
            )}
            {tabIndex === 1 && (
              <div>
                <Images
                  change={props.change}
                  initialValues={props.initialValues}
                  readOnly={readOnly}
                  filmId={filmId}
                />
              </div>
            )}
            {tabIndex === 2 && (
              <div>
                <Field
                  name="parkingDetails"
                  label="Parking Details"
                  component={renderTextAreaField}
                  readOnly={readOnly}
                />
                <AddressComponent
                  address={thisLocation.parkingAddress}
                  readOnly={readOnly}></AddressComponent>
                {thisLocation.parkingAddress && <div className="mt-1"><a target="_blank" href={`https://www.google.com/maps/dir/?api=1&destination=${thisLocation.parkingAddress.latitude},${thisLocation.parkingAddress.longitude}`} className="button">Directions</a></div>}
                {props.noCarParks && (
                  <div>No car parks found within a mile</div>
                )}
                {!readOnly && !props.noCarParks && (
                  <button
                    type="button"
                    className={`button mt-1 is-small ${fetchingCarParkList ? "is-loading" : ""
                      }`}
                    onClick={() => onFindParking(thisLocation)}>
                    Find Parking
                  </button>
                )}
                {props.showCarParkList
                  ? (props.carParks || []).map((carPark) => {
                    return (
                      <CarPark
                        key={carPark.place_id}
                        carPark={carPark}
                        selectedCarPark={props.selectedCarPark}
                        onSelectedCarParkChanged={onSelectedCarParkChanged}
                        readOnly={readOnly}
                      />
                    );
                  })
                  : null}
              </div>
            )}
            {tabIndex === 3 && (
              <>
                <Field
                  name="noHospital"
                  label="No Hospital"
                  component={renderCheckbox} />
                {!noHospitalValue && <div className="mt-1">
                  <AddressComponent
                    address={thisLocation.hospitalAddress}
                    readOnly={readOnly}></AddressComponent>

                  {thisLocation.parkingAddress && <div className="mt-1"><a target="_blank" href={`https://www.google.com/maps/dir/?api=1&destination=${thisLocation.hospitalAddress.latitude},${thisLocation.hospitalAddress.longitude}`} className="button">Directions</a></div>}
                  {!readOnly && (
                    <button
                      type="button"
                      className={`button mt-1 is-small mb-1 ${fetchingHospitalList ? "is-loading" : ""
                        }`}
                      onClick={() => onFetchHospitals(thisLocation)}>
                      Find Hospitals
                    </button>
                  )}
                  <br />

                  <FieldArray name="hospitals" component={renderHospitals} />
                </div>}
              </>
            )}

            {tabIndex === 4 && (
              <>
                <FieldArray
                  name={`subLocations`}
                  component={renderSubLocations}
                  readOnly={readOnly}
                />
              </>
            )}

            {tabIndex === 5 && <LocationAvailability availableDates={availableDates}
              daysOfWeek={daysOfWeek}
              updateEvent={updateEvent}
              addDays={addDays}
              deleteDay={deleteDay}
              deleteDays={deleteDays}
              changeDayOfWeek={changeDayOfWeek} />}

            {!readOnly && (
              <div className="buttons mt-2">
                <button
                  type="submit"
                  className="button"
                  disabled={pristine || submitting}>
                  Save
                </button>

                {mode === "edit" && (
                  <button
                    type="button"
                    className="button is-text"
                    disabled={submitting || !props.initialValues}
                    onClick={() => handleDelete(thisLocation)}>
                    Delete
                  </button>
                )}
              </div>
            )}
            <FormErrors submitFailed={submitFailed} invalid={invalid} />
            <Onboarding onboardingSteps={onboardingSteps} section="Shooting Location" />
          </>
        )}
      </form>
    </>
  );

  function renderHospitals({ fields, meta: { error } }) {
    return (
      <>
        {(fields || []).map((field, idx, records) => {
          const hospital = records.get(idx);

          if (!hospital.organisationName) {
            return null;
          }

          return (
            <Radio
              key={idx}
              id={hospital.organisationId}
              value={hospital.organisationId}
              checked={hospital.selected}
              onChange={onSelectedHospitalChanged}
              text={
                <span>
                  <h2>{hospital.organisationName}</h2>
                  <p>{hospital.address1}</p>
                  {hospital.address2 && <p>{hospital.address2}</p>}
                  {hospital.address3 && <p>{hospital.address3}</p>}
                  <p>{hospital.city}</p>
                  <p>{hospital.county}</p>
                  <p>{hospital.postcode}</p>
                </span>
              }
            />
          );
        })}
      </>
    );
  }
};

// LocationForm = reduxForm({
//   form: "locationForm",
//   enableReinitialize: true,
//   keepDirtyOnReinitialize: true
// })(LocationForm);

// export default LocationForm;

LocationForm = reduxForm({
  form: "locationForm",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(LocationForm);

// Decorate with connect to read form values
const selector = formValueSelector('locationForm'); // <-- same as form name
const LocationFormConnect = connect(
  state => {
    // can select values individually
    const noHospitalValue = selector(state, 'noHospital')

    return {
      noHospitalValue
    }
  }
)(LocationForm)

export default LocationFormConnect;
