import React from "react";
import { Prompt } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import Errors from "./../../../shared/errors";
import {
  renderCheckboxGroup,
  renderReactSelect,
  renderSelectField,
  renderSingleReactSelect,
  renderTextAreaField,
  renderTextField,
  renderTimeField
} from "./../../../shared/form-helpers";
import Images from "./../../../shared/images";
import required, { maxDecimalValue } from "./../../../shared/validation";

function ShotForm(props) {
  const {
    handleSubmit,
    handleDelete,
    pristine,
    reset,
    submitting,
    shotSizeEnum,
    cameraMovementEnum,
    focalLengths,
    frameRates,
    characters,
    backgroundCharacters,
    mode,
    errors,
    filmId
  } = props;
  const thisShot = props.initialValues || {};
  const readOnly = thisShot.readOnly;
  const cameraMovementOptions = cameraMovementEnum?.map((m) => {
    return { label: m.name, value: m.value };
  });

  const cameraMovementSelectedOptions = thisShot.movements?.map((m) => {
    return cameraMovementOptions?.find((x) => x.value === m.id);
  });

  const shotSizeOptions = (shotSizeEnum || []).map((item) => { return { value: item.value, label: item.name } });
  const focalLengthOptions = (focalLengths || []).map((item) => { return { value: item.length, label: item.length } });
  const frameRateOptions = (frameRates || []).map((item) => { return { value: item.framesPerSecond, label: item.framesPerSecond } });

  return (
    <>
      <Prompt when={!pristine} message="" />
      {mode === "add" && <h2 className="menu-label">Add Shot</h2>}
      {mode === "edit" && <h2 className="menu-label">Edit Shot</h2>}
      {errors && <Errors errors={errors} />}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div className="columns">
          <div className="column">
            <Field
              name="number"
              label="Shot Number"
              type="number"
              component={renderTextField}
              validate={[required, maxDecimalValue]}
              required
              readOnly={readOnly}
            />
          </div>
          <div className="column">
            <Field
              name="shotSize"
              label="Shot size"
              component={renderSingleReactSelect}
              options={shotSizeOptions}
              readOnly={readOnly}>
            </Field>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <Field
              name="lensFocalLength"
              label="Lens Focal Length"
              component={renderSingleReactSelect}
              options={focalLengthOptions}
              readOnly={readOnly}>
            </Field>
          </div>
          <div className="column">
            <Field
              name="frameRate"
              label="Frame Rate"
              component={renderSingleReactSelect}
              options={frameRateOptions}
              readOnly={readOnly}>
            </Field>
          </div>
        </div>

        <Field
          label="Camera movements"
          name="movements"
          component={renderReactSelect}
          options={cameraMovementOptions}
          selectedOptions={cameraMovementSelectedOptions}
          readOnly={readOnly}
        />

        <div className="columns">
          <div className="column">
            <Field
              name="estimatedSetupDuration"
              label="Setup Duration"
              component={renderTimeField}
              canAddDay={false}
              readOnly={readOnly}>
            </Field>
          </div>
          <div className="column">
            <Field
              name="estimatedShootingDuration"
              label="Shooting Duration"
              component={renderTimeField}
              canAddDay={false}
              readOnly={readOnly}>
            </Field>
          </div>
        </div>

        <Field
          label="Characters"
          name="characterIds"
          component={renderCheckboxGroup}
          options={characters}
          value={thisShot.characterIds}
          valueProperty="id"
          readOnly={readOnly}
        />

        {backgroundCharacters && backgroundCharacters.length > 0 && <Field
          label="Background Characters"
          name="backgroundCharacterIds"
          component={renderCheckboxGroup}
          options={backgroundCharacters}
          value={thisShot.backgroundCharacterIds}
          valueProperty="id"
          readOnly={readOnly}
        />}

        <Field name="notes" label="Notes" component={renderTextAreaField} readOnly={readOnly} />

        <Images change={props.change} initialValues={props.initialValues} readOnly={readOnly} filmId={filmId} />

        {!readOnly && <div className="buttons mt-2">
          <button
            type="submit"
            className="button"
            disabled={(pristine || submitting) && mode != "add"}>
            Save
          </button>
          {/* <button type="button" className="button is-text" disabled={pristine || submitting} onClick={reset}>
            Reset Values
          </button> */}
          {mode === "edit" && (
            <button
              type="button"
              className="button is-text"
              disabled={submitting || !props.initialValues}
              onClick={() => handleDelete(thisShot)}>
              Delete Shot
            </button>
          )}
        </div>}
      </form>
    </>
  );
}

export default reduxForm({
  form: "editShotForm",
  enableReinitialize: true
})(ShotForm);
