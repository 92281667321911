import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import Loader from "./../../../shared/loader";
import confirm from "../../../shared/modal/confirm";
import { fetchBackgroundCharacters } from "./../../background-characters/ducks/actions";
import {
  clearRedirect,
  deleteBackgroundCharacter,
  fetchBackgroundCharacter,
  updateBackgroundCharacter,
} from "./ducks/actions";
import Form from "./form";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";

function CharactersContainer(props) {
  const { filmId, sceneId, backgroundCharacterId } = props.match.params;
  const {
    fetchBackgroundCharacter,
    fetchBackgroundCharacters,
    updateBackgroundCharacter,
    deleteBackgroundCharacter,
    loadingCharacter,
    backgroundCharacter,
    errors,
  } = props;

  useEffect(() => {
    if (backgroundCharacterId !== "add") {
      fetchBackgroundCharacter(filmId, sceneId, backgroundCharacterId);
      fetchBackgroundCharacters(filmId);
    }
  }, [filmId, backgroundCharacterId]);

  if (props.redirect) {
    if (props.redirect.to == "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/scenes/${sceneId}/background-characters`;
      return <Redirect to={url} />;
    }
  }

  if (backgroundCharacterId == "add") {
    return null;
  }

  if (props.redirect) {
    const url = `/films/${filmId}/scenes/${sceneId}/background-characters`;
    return <Redirect to={url} />;
  }

  function renderLoading() {
    return <Loader />;
  }

  function renderBackgroundCharacter(backgroundCharacter) {
    return (
      <ScrollIntoView
        className="blade-content"
        path="/films/:filmId/scenes/:sceneId/background-characters/:backgroundCharacterId/edit"
        loading={loadingCharacter}>
        <Form
          errors={errors}
          mode="edit"
          filmId={filmId}
          characterId={backgroundCharacter.id}
          initialValues={backgroundCharacter}
          characters={props.characters}
          loading={loadingCharacter}
          onSubmit={onUpdateCharacter}
          handleDelete={onDeleteCharacter}
          {...props}
        />
      </ScrollIntoView>
    );
  }

  function onUpdateCharacter(character) {
    sanitiseDataTypes(character);
    updateBackgroundCharacter(filmId, sceneId, character);
  }

  function onDeleteCharacter(character) {
    confirm(() => deleteBackgroundCharacter(filmId, sceneId, character), character?.backgroundCharacterName, `Are you sure you want to remove ${character?.backgroundCharacterName} from the scene?`, "Remove");
  }

  function sanitiseDataTypes(backgroundCharacter) {

  }

  return backgroundCharacter ? renderBackgroundCharacter(backgroundCharacter) : renderLoading();
}

function mapStateToProps(state) {
  return {
    backgroundCharacters: state.backgroundCharactersState.rootState.backgroundCharacters,
    backgroundCharacter: state.scenesState.backgroundCharacterState.backgroundCharacter,
    errors: state.scenesState.backgroundCharacterState.errors,
    redirect: state.scenesState.backgroundCharacterState.redirect,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchBackgroundCharacter,
    updateBackgroundCharacter,
    deleteBackgroundCharacter,
    fetchBackgroundCharacters,
    clearRedirect,
  })(CharactersContainer),
);
