import React, { useEffect, useCallback, useState } from "react";
import _ from 'lodash';
import Scrollspy from 'react-scrollspy'
import ContentEditable from 'react-contenteditable'
import ContextMenuItem, { IPosition, IContextMenuItem } from './context-menu-item';
import {
  fetchScript,
  resetClearForm,
  setSelectedItem,
  editItem,
  clearReloadScript,
  editElement,
  updateScript,
  setEditMode,
  setSplitMode,
  downloadScript,
  pdfDownloaded,
  clearPdf,
  resetPdf,
  showSplitScene,
  splitScriptScene,
  unSplitScriptScene,
  showMoveSplitScene,
  finishMovingSplitScene
} from "./ducks/actions";
import { connect } from "react-redux";
import Loader from "./../../shared/loader";
import AddCharacter from '../characters/add';
import EditCharacter from '../characters/edit';
import CharacterScenes from "../characters/scenes/scenes";
import AddBackgroundCharacter from '../background-characters/add';
import EditBackgroundCharacter from '../background-characters/edit';
import BackgroundCharacterScenes from "../background-characters/scenes/scenes";
import AddProp from '../accessories/props/add';
import EditProp from '../accessories/props/edit';
import PropScenes from '../accessories/props/scenes';
import AddSetDressing from '../accessories/setDressings/add';
import EditSetDressing from '../accessories/setDressings/edit';
import SetDressingScenes from '../accessories/setDressings/scenes';
import AddVisualEffect from '../visual-effects/add';
import EditVisualEffect from '../visual-effects/edit';
import VisualEffectScenes from '../visual-effects/scenes';
import AddCostume from '../accessories/costumes/add';
import EditCostume from '../accessories/costumes/edit';
import CostumeScenes from '../accessories/costumes/scenes';
import AddMakeup from '../accessories/makeup/add';
import EditMakeup from '../accessories/makeup/edit';
import MakeupScenes from '../accessories/makeup/scenes';
import EditScene from '../scenes/edit';
import './script.scss';
import CostumesIcon from '../icons/costumes';
import MakeupIcon from '../icons/makeup';
import PropsIcon from '../icons/props';
import SetDressingsIcon from '../icons/set-dressings';
import CharactersIcon from '../icons/characters';
import BackgroundCharactersIcon from '../icons/background-characters';
import VisualEffectIcons from '../icons/visual-effects';

import {
  OnboardingStep1ClassName,
  OnboardingStep2ClassName,
  OnboardingStep3ClassName,
  OnboardingStep4ClassName,
  OnboardingStep5ClassName,
  OnboardingStep6ClassName,
  OnboardingStep7ClassName
} from "./ducks/reducer";
import Onboarding from '../../shared/onboarding/onboarding';
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";
import FilmanizeCheckbox from "../../shared/filmanize-checkbox";
import Download from "../../shared/download";
import DownloadScript from "./download-script/download-script";
import UploadScript from "./upload/container";
import SmartBreakdown from "./smart-breakdown/container";
import Errors from "../../shared/errors";

interface AppProps { }

const Script: React.FC<AppProps> = (props: any) => {
  const {
    fetchScript,
    clearReloadScript,
    resetClearForm,
    setSelectedItem,
    loading,
    scenes,
    script,
    editedScript,
    readOnly,
    selectedItem,
    selectScenes,
    characterNames,
    propNames,
    backgroundCharacterNames,
    costumeNames,
    makeupNames,
    setDressingNames,
    propId,
    setDressingId,
    costumeId,
    makeupId,
    characterId,
    backgroundCharacterId,
    visualEffectId,
    editItem,
    itemId,
    scriptOnboardingSteps,
    reloadScript,
    editElement,
    updateScript,
    editMode,
    splitMode,
    setEditMode,
    setSplitMode,
    downloadScript,
    clearPdf,
    download,
    progress,
    resetPdf,
    canUploadNewVersion,
    showSplitScene,
    splitScriptScene,
    unSplitScriptScene,
    showMoveSplitScene,
    movingScene,
    finishMovingSplitScene,
    errors
  } = props;

  const [contextMenuActive, setContextMenuActive] = useState<boolean>(false),
    [position, setPosition] = useState({ x: 0, y: 0 }),
    [sceneSettingId, setSceneSettingId] = React.useState(null),
    [sceneId, setSceneId] = useState(null);

  const [menuRadius, setMenuRadius] = useState<number>(100),
    [itemRadius, setItemRadius] = useState<number>(25);

  const [contextMenuItems, setContextMenuItems] = useState<any>([]);
  const [showDownload, setShowDownload] = useState<boolean>(false);
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [showSmartBreakdown, setShowSmartBreakdown] = useState<boolean>(false);

  const filmId = props.match.params.filmId;

  const Character = "Character",
    BackgroundCharacter = "Background Character",
    Prop = "Prop",
    SetDressing = "Set Dressing",
    Costume = "Costume",
    Makeup = "Makeup",
    Scene = "Scene",
    VisualEffect = "Visual Effect";

  useEffect(() => {
    setContextMenuItems([
      { name: Character, icon: <CharactersIcon className="menu-icon" /> },
      { name: BackgroundCharacter, icon: <BackgroundCharactersIcon className="menu-icon" /> },
      { name: Prop, icon: <PropsIcon className="menu-icon" /> },
      { name: SetDressing, icon: <SetDressingsIcon className="menu-icon" /> },
      { name: Costume, icon: <CostumesIcon className="menu-icon" /> },
      { name: Makeup, icon: <MakeupIcon className="menu-icon" /> },
      { name: VisualEffect, icon: <VisualEffectIcons className="menu-icon" /> }
    ]);
    resetClearForm();
  }, []);

  useEffect(() => {
    if (!selectedItem) {
      fetchScript(filmId, true);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (reloadScript) {
      fetchScript(filmId, false);
      clearReloadScript();
      setShowUpload(false);
    }
  }, [reloadScript]);

  useEffect(() => {
    if (editMode) {
      setContextMenuActive(false);
    }
  }, [editMode]);

  useEffect(() => {
    if (splitMode) {
      setContextMenuActive(false);
    }
  }, [splitMode]);

  const onSetActive = (active) => {
    //console.log("onSetActive", active);
    setContextMenuActive(active);
  }

  // program to reverse a string

  function reverseString(str) {

    // return a new array of strings
    const arrayStrings = str.split("");

    // reverse the new created array elements
    const reverseArray = arrayStrings.reverse();

    // join all elements of the array into a string
    const joinArray = reverseArray.join("");

    // return the reversed string
    return joinArray;
  }

  const handleMouseDown = useCallback(() => {
    if (readOnly) {
      return;
    }

    const handleMouseUp = (e: any): void => {
      e.preventDefault();

      if (editMode || splitMode) {
        return;
      }

      const selection = (window as any).getSelection();

      if (!selection.baseNode) {
        return;
      }
      var range = document.createRange();

      //find next non word
      const allText = selection.baseNode.data;
      const lastCharacterOfSelection = allText.substr(selection.extentOffset - 1, 1);
      const restOfText = allText.substr(selection.extentOffset);

      var findEndOfWordRegEx = new RegExp(`[ :;,.?!'"]`, 'g');
      const previousText = allText.substr(0, selection.baseOffset);
      const reversedPreviousText = reverseString(previousText);
      let previousSpace = reversedPreviousText.search(findEndOfWordRegEx);
      if (previousSpace === -1) {
        previousSpace = selection.baseOffset;
      }
      range.setStart(selection.baseNode, selection.baseOffset - previousSpace);

      let nextSpace = 0;
      if (lastCharacterOfSelection === " ") {
        nextSpace = -1;
      } else {
        //myRegex1.lastIndex = selection.extentOffset;
        nextSpace = restOfText.search(findEndOfWordRegEx);
        if (nextSpace === -1) {
          // must be only one word
          nextSpace = allText.length - selection.extentOffset;
        }
      }

      range.setEnd(selection.baseNode, selection.extentOffset + nextSpace);

      selection.removeAllRanges();
      selection.addRange(range);

      let parentNode = selection.baseNode?.parentNode;
      const text = selection.toString().trim();

      if (parentNode?.nodeName === "MARK" || parentNode?.className == "scene-heading") {

        return;
      }

      const justText = selection.anchorNode?.nodeValue?.trim() === selection.focusNode?.nodeValue?.trim()
      if (!text || !justText) {
        onSetActive(false);
        setSelectedItem(null);
        return;
      }

      // Added some barriers for the menu position so it doesnt extend off the screen
      const x: number = Math.min(Math.max(menuRadius + 10, e.pageX - itemRadius), window.innerWidth + window.pageXOffset - (menuRadius * 1.5) - 10),
        y: number = Math.min(Math.max(menuRadius + 10, e.pageY - itemRadius), window.innerHeight + window.pageYOffset - (menuRadius * 1.5) - 10);

      setPosition({ x, y });
      onSetActive(true);
    }

    if (editMode || splitMode) {
      document.removeEventListener(
        "mouseup",
        _.debounce(handleMouseUp, 200)
      );
    } else {
      document.addEventListener(
        "mouseup",
        _.debounce(handleMouseUp, 200),
        { once: true }
      );
    }

  }, [editMode, splitMode]);

  let scriptElement;

  const titleCase = (string) => {
    var sentence = string.toLowerCase().split(" ");
    for (var i = 0; i < sentence.length; i++) {
      if (sentence[i] && sentence[i][0]) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
      }
    }

    return sentence.join(" ");
  }



  useEffect(() => {

    const handleScroll = (e: any): void => {
      onSetActive(false);
      const selection = (window as any).getSelection();
      selection.empty();
    }

    console.log("addEventListener");
    //scriptElement.addEventListener("mouseup", _.debounce(handleMouseUp, 200));
    //scriptElement.addEventListener("mousedown", handleMouseDown);
    scriptElement.addEventListener('scroll', handleScroll, true);

    return () => {
      console.log("removeEventListener");
      //scriptElement?.removeEventListener("mouseup", handleMouseUp);
      //scriptElement?.removeEventListener("mousedown", handleMouseDown);
      scriptElement?.removeEventListener("scroll", handleScroll);
    }

  }, []);

  const menuItemClick = (item) => {
    const selection = (window as any).getSelection();
    let currentElement = selection.baseNode;
    while (currentElement?.nodeName != "SECTION") {
      currentElement = currentElement.parentElement;
    }

    const section = currentElement;
    const sceneId = section.id;
    var sceneTextIn = scenes.find(scene => scene.id == sceneId);
    const sceneSettingId = sceneTextIn?.sceneSettingId;

    setSceneSettingId(sceneSettingId);
    setSceneId(sceneTextIn?.id);
    setSelectedItem(item);
    onSetActive(false);
    setShowDownload(false);
    setShowUpload(false);
    setShowSmartBreakdown(false);
  }

  const handleOnContextMenu = (e: any): void => {
    e.preventDefault();
    const items: any[] = scriptElement.getElementsByClassName("context-menu-item") as unknown as any[];

    if (items && items.length > 0) {
      let count: number = 0;

      for (let i = 0; i < items.length; i++) {
        if (items[i].contains(e.target)) {
          count++;
        }
      }

      if (count === 0) {
        onSetActive(false);
      }
    }
    else {
      onSetActive(false);
    }
  }

  const getContextMenuItems = (): JSX.Element[] => {
    const getOffset = (index: number): IPosition => {
      const step: number = (2 * Math.PI) / contextMenuItems.length,
        angle: number = index * step;

      const x: number = Math.round(menuRadius + (menuRadius * Math.cos(angle)) - itemRadius - (menuRadius - itemRadius)),
        y: number = Math.round(menuRadius + (menuRadius * Math.sin(angle)) - itemRadius - (menuRadius - itemRadius));

      return { x, y };
    }

    return contextMenuItems.map((item: IContextMenuItem, index: number) => {
      return (
        <ContextMenuItem
          key={item.name}
          index={index}
          name={item.name}
          icon={item.icon}
          active={contextMenuActive}
          itemClick={menuItemClick}
          position={position}
          offset={getOffset(index)}
        />
      )
    })
  }

  const updateText = (text) => {
    if (text.indexOf('onclick="elementClick"') > -1) {
      text = text.replaceAll('onclick="elementClick"', ''); //'onClick="' + elementClick + '"'
    }

    return text;
  };

  const sceneClicked = (sceneId) => {
    setShowDownload(false);
    setShowUpload(false);
    setShowSmartBreakdown(false);
    editItem({ item: "Edit" + Scene }, sceneId);
  };

  const joinHandler = (scriptSceneId) => {
    unSplitScriptScene(filmId, scriptSceneId)
  };

  const moveHandler = (scriptSceneId, sceneNumber) => {
    showMoveSplitScene(scriptSceneId, sceneNumber);
  };

  const clickHandler = (e, elementIndex, sceneId, newSceneNumber, notLastElement, canSplit) => {
    if (splitMode) {
      if (notLastElement && canSplit) {
        // if moving, then move scene heading
        if (movingScene) {
          finishMovingSplitScene(filmId, sceneId, elementIndex, movingScene.scriptSceneId);
        } else {
          splitScriptScene(filmId, sceneId, elementIndex, newSceneNumber);
        }
      }
    } else {
      const mark = e.target.closest("MARK");
      if (mark) {
        onSetActive(false);
        setShowDownload(false);
        setShowUpload(false);
        setShowSmartBreakdown(false);
        let itemName;
        if (mark.className.startsWith("character")) {
          itemName = "Edit" + Character;
        } else if (mark.className.startsWith("backgroundCharacter")) {
          itemName = "Edit" + BackgroundCharacter;
        } else if (mark.className.startsWith("prop")) {
          itemName = "Edit" + Prop;
        } else if (mark.className.startsWith("setDressing")) {
          itemName = "Edit" + SetDressing;
        } else if (mark.className.startsWith("costume")) {
          itemName = "Edit" + Costume;
        } else if (mark.className.startsWith("makeup")) {
          itemName = "Edit" + Makeup;
        } else if (mark.className.startsWith("visualEffect")) {
          itemName = "Edit" + VisualEffect;
        }

        if (itemName) {
          editItem({ item: itemName }, mark.id);
        }
      }
    }
  }

  const refs = scenes.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});

  const elementRefs = script.reduce((acc, scene) => {
    acc[scene.id] = scene.elements.reduce((acc, element, index) => {
      acc[index] = React.createRef();
      return acc;
    }, []);

    return acc;
  }, {});

  const linkRefs = scenes.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});

  const scrollToSection = (id) => {
    if (refs[id]?.current) {
      scriptElement.scrollTo(0, refs[id].current.offsetTop - 170);
    }
  };

  let nameElement;
  const scrollSpyOnEvent = (e) => {
    if (!e) {
      return;
    }

    console.log(e.id);
    nameElement?.scrollTo(0, linkRefs[e.id].current.offsetTop - 130);
  }

  const onElementChange = (event, scene, elementIndex) => {
    editElement(event.target.value, scene.id, elementIndex);
  };

  const onSaveScript = () => {
    let localScript = Array.from(editedScript);
    localScript.forEach((scriptScene: any) => {
      const sceneId = scriptScene.id;
      const refElements = elementRefs[sceneId];
      scriptScene.elements.forEach((element, index) => {
        const newText = refElements[index].current.innerHTML;
        if (newText !== element.text) {
          element.text = newText;
          element.updated = true;
        }
      });
    });

    updateScript(filmId, localScript);
    resetPdf();
  };

  const onChangeEditMode = () => {
    setEditMode(!editMode);
  };

  const onChangeSplitMode = () => {
    setSplitMode(!splitMode);
  };

  const onDownloadFile = (includeTitle, showMarkup, includeDialog, includeAction, includeCharacter, includeParenthetical, includeTransition, includeGeneral, allScenes, selectedSceneIds) => {
    downloadScript(filmId, includeTitle, showMarkup, includeDialog, includeAction, includeCharacter, includeParenthetical, includeTransition, includeGeneral, allScenes, selectedSceneIds);
  };

  const onShowDownload = () => {
    setSelectedItem(null);
    setShowUpload(false);
    setShowDownload(true);
    setShowSmartBreakdown(false);
  };

  const onHideDownload = () => {
    setShowDownload(false);
  };

  const onShowUpload = () => {
    setSelectedItem(null);
    setShowDownload(false);
    setShowUpload(true);
    setShowSmartBreakdown(false);
  };

  const onShowSmartBreakdown = () => {
    setSelectedItem(null);
    setShowDownload(false);
    setShowUpload(false);
    setShowSmartBreakdown(true);
  };

  const onHideUpload = () => {
    setShowUpload(false);
  };

  const onHideSmartBreakdown = () => {
    setShowSmartBreakdown(false);
  };

  const onShowSplitScene = (sceneId, elementIndex, show) => {
    showSplitScene(sceneId, elementIndex, show)
  };

  const sceneIds = scenes.map(scene => { return `${scene.id}` });

  const [marksHidden, setMarksHidden] = useState<boolean>(false);
  const showMarks = () => {
    setMarksHidden(!marksHidden);
  };

  return (
    <>
      {/* onContextMenu={handleOnContextMenu} */}
      <ScrollIntoView
        className={`blade-menu menu ${OnboardingStep1ClassName}`}
        path="/films/:filmId/script"
        loading={loading}>
        <p className="menu-label">Script</p>
        <ul className="menu-list section-nav download">
          <li>
            <a className={`menu-list ${OnboardingStep3ClassName}`} onClick={onShowDownload}>Download</a>
          </li>
          {canUploadNewVersion && !readOnly &&
            <li>
              <a className={`menu-list ${OnboardingStep4ClassName}`} onClick={onShowUpload}>Upload</a>
            </li>
          }
          {!readOnly && <li>
            <a className={`menu-list ${OnboardingStep5ClassName}`} onClick={onShowSmartBreakdown}>Smart Breakdown</a>
          </li>}
        </ul>
        <p className="menu-label">Scenes</p>
        <ul className="menu-list section-nav" ref={elem => nameElement = elem}>
          <Scrollspy items={sceneIds}
            currentClassName='current'
            componentTag='ol'
            rootEl='.script'
            onUpdate={scrollSpyOnEvent}
          >
            {(scenes || []).map((scene, idx) => {
              return (
                <li key={scene.id}>
                  <a className="menu-list" ref={linkRefs[scene.id]} onClick={() => scrollToSection(scene.id)} title={scene.sceneSettingName}>{scene.number}. {scene.sceneSettingName}</a>
                </li>
              )
            })}

          </Scrollspy>
        </ul>
        {/* <button className="button" onClick={showMarks}>Show</button> */}
      </ScrollIntoView>
      {loading && <Loader />}
      <div className="blade-content script-width" id="app">
        {getContextMenuItems()}
        <Errors errors={errors} />
        {!readOnly && <div className="script-edit-mode">
          <FilmanizeCheckbox label="Split Scene Mode" className={`${OnboardingStep6ClassName}`} onChange={onChangeSplitMode} checked={splitMode} />
          <FilmanizeCheckbox label="Edit Mode" className={`ml-1 ${OnboardingStep7ClassName}`} onChange={onChangeEditMode} checked={editMode} />
          <button className="button ml-1" onClick={onSaveScript} disabled={!editMode}>Save Script</button>
        </div>}
        <div className="legend">
          <mark className="characterHighlight">Character</mark>
          <mark className="backgroundCharacterHighlight" style={{ whiteSpace: "nowrap" }}>Background Character</mark>
          <mark className="propHighlight">Prop</mark>
          <mark className="setDressingHighlight" style={{ whiteSpace: "nowrap" }}>Set Dressing</mark>
          <mark className="costumeHighlight">Costume</mark>
          <mark className="makeupHighlight">Makeup</mark>
          <br />
          <mark className="visualEffectHighlight" style={{ whiteSpace: "nowrap" }}>Visual Effect</mark>
        </div>
        <div
          className={`selectable-text-area script ${OnboardingStep2ClassName} ${marksHidden ? "hide-marks" : ""}`}
          ref={(elem) => (scriptElement = elem)}
          onMouseDown={handleMouseDown}>
          {(script || []).map((scene, idx) => {

            return (
              <section id={scene.sceneId} key={idx} ref={refs[scene.sceneId]}>
                {!scene.moving && <div className="scene-heading-container">
                  <p key={idx} className="scene-heading" onClick={() => sceneClicked(scene.sceneId)}>{scene.number} {scene.name} </p>{splitMode && scene.splitFromScriptSceneId && <div className="join-button-container">
                    <button className="button small join-button mr-1" onClick={() => moveHandler(scene.id, scene.number)}>Move</button>
                    <button className="button small join-button" onClick={() => joinHandler(scene.id)}>Join</button>
                  </div>}
                </div>}
                {(scene.elements || []).map((element, elementIndex) => {
                  const canSplit = elementIndex + 1 < scene.elements.length
                    && scene.elements[elementIndex + 1].elementType.toLowerCase() != "scene-heading"
                    && element.showSplitScene
                    //&& (element.elementType.toLowerCase() == "dialogue" || element.elementType.toLowerCase() == "action" || element.elementType.toLowerCase() == "general" || element.elementType.toLowerCase() == "parenthetical")
                    && element.elementType.toLowerCase() != "character"
                    && (!movingScene || (scene.id == movingScene.scriptSceneId || scene.id == movingScene.previousScriptSceneId));
                  let elementStyle = "";
                  if (element.elementStyle?.length > 0) {
                    element.elementStyle?.forEach(style => {
                      if (style != "None") {
                        elementStyle += "element-style-" + style.toLowerCase() + " ";
                      }
                    });

                  }
                  return (
                    <>
                      {!editMode && <p key={elementIndex} style={{ cursor: splitMode ? canSplit ? "pointer" : "default" : "text" }}
                        className={`${element.elementType.toLowerCase()} ${elementStyle}`}
                        onMouseEnter={() => onShowSplitScene(scene.id, elementIndex, true)}
                        onMouseLeave={() => onShowSplitScene(scene.id, elementIndex, false)}
                        onClick={(e) => clickHandler(e, elementIndex, scene.id, scene.splitSceneNumber, elementIndex + 1 < scene.elements.length, canSplit)}
                        dangerouslySetInnerHTML={{ __html: updateText(element.text) }}></p>}
                      {editMode && <ContentEditable key={elementIndex} innerRef={elementRefs[scene.id][elementIndex]} className={element.elementType.toLowerCase()} html={element.text} tagName="p" onChange={() => { }} />}
                      {/* onChange={(text) => onElementChange(text, scene, elementIndex) } */}

                      {splitMode && canSplit &&
                        <div className="split-scene-container-container">
                          <div className="split-scene-container">
                            <span className="split-scene">{movingScene ? movingScene.sceneNumber : scene.splitSceneNumber}</span>
                          </div>
                        </div>
                      }
                    </>
                  )
                })}
              </section>
            )
          })}

        </div>

        {/* <div className="mt-1">
          <Download 
                progress={progress}
                clearPdf={clearPdf}
                download={download}
                downloadFile={onDownloadFile} />
        </div> */}
      </div>
      <div className="blade-content" style={{ marginLeft: "1rem", paddingLeft: 0 }}>
        {showDownload && <DownloadScript progress={progress}
          clearPdf={clearPdf}
          download={download}
          downloadFile={onDownloadFile}
          onClose={onHideDownload}
          filmId={filmId}
          resetPdf={resetPdf}
          allScenes={scenes.map((s: any) => { return { id: s.id, number: s.number, name: s.number + ". " + s.sceneSettingName, sceneOrder: s.sceneOrder } })} />}

        {showUpload && <UploadScript onClose={onHideUpload} filmId={filmId} />}
        {showSmartBreakdown && <SmartBreakdown onClose={onHideSmartBreakdown} filmId={filmId} />}
        {(selectedItem?.item === Character && !selectScenes) && <AddCharacter filmId={filmId} name={titleCase(selectedItem.text)} embedded={true} sceneId={sceneId} />}
        {(selectedItem?.item === "Edit" + Character && !selectScenes) && <EditCharacter filmId={filmId} characterId={itemId} embedded={true} />}
        {(selectedItem?.item === Character) && (selectScenes == Character) && <CharacterScenes filmId={filmId} characterId={characterId} sceneId={sceneId} embedded={true} />}

        {(selectedItem?.item === BackgroundCharacter && !selectScenes) && <AddBackgroundCharacter filmId={filmId} name={titleCase(selectedItem.text)} embedded={true} sceneId={sceneId} />}
        {(selectedItem?.item === "Edit" + BackgroundCharacter && !selectScenes) && <EditBackgroundCharacter filmId={filmId} backgroundCharacterId={itemId} embedded={true} />}
        {(selectedItem?.item === BackgroundCharacter) && (selectScenes == BackgroundCharacter) && <BackgroundCharacterScenes filmId={filmId} backgroundCharacterId={backgroundCharacterId} sceneId={sceneId} embedded={true} />}

        {(selectedItem?.item === Prop && !selectScenes) && <AddProp filmId={filmId} name={titleCase(selectedItem.text)} embedded={true} sceneId={sceneId} />}
        {(selectedItem?.item === "Edit" + Prop && !selectScenes) && <EditProp filmId={filmId} propId={itemId} embedded={true} />}
        {(selectedItem?.item === Prop) && (selectScenes == Prop) && <PropScenes filmId={filmId} propId={propId} sceneId={sceneId} embedded={true} />}

        {(selectedItem?.item === SetDressing && !selectScenes) && <AddSetDressing filmId={filmId} name={titleCase(selectedItem.text)} sceneId={sceneId} sceneSettingId={sceneSettingId} embedded={true} />}
        {(selectedItem?.item === "Edit" + SetDressing && !selectScenes) && <EditSetDressing filmId={filmId} setDressingId={itemId} sceneSettingId={sceneSettingId} embedded={true} />}
        {(selectedItem?.item === SetDressing) && (selectScenes == SetDressing) && <SetDressingScenes filmId={filmId} setDressingId={setDressingId} sceneId={sceneId} embedded={true} />}

        {(selectedItem?.item === Costume && !selectScenes) && <AddCostume filmId={filmId} name={titleCase(selectedItem.text)} sceneId={sceneId} embedded={true} />}
        {(selectedItem?.item === "Edit" + Costume && !selectScenes) && <EditCostume filmId={filmId} costumeId={itemId} sceneId={sceneId} embedded={true} />}
        {(selectedItem?.item === Costume) && (selectScenes == Costume) && <CostumeScenes filmId={filmId} costumeId={costumeId} sceneId={sceneId} embedded={true} />}

        {(selectedItem?.item === Makeup && !selectScenes) && <AddMakeup filmId={filmId} name={titleCase(selectedItem.text)} sceneId={sceneId} embedded={true} />}
        {(selectedItem?.item === "Edit" + Makeup && !selectScenes) && <EditMakeup filmId={filmId} makeupId={itemId} sceneId={sceneId} embedded={true} />}
        {(selectedItem?.item === Makeup) && (selectScenes == Makeup) && <MakeupScenes filmId={filmId} makeupId={makeupId} sceneId={sceneId} embedded={true} />}

        {(selectedItem?.item === VisualEffect && !selectScenes) && <AddVisualEffect filmId={filmId} name={titleCase(selectedItem.text)} sceneId={sceneId} embedded={true} />}
        {(selectedItem?.item === "Edit" + VisualEffect && !selectScenes) && <EditVisualEffect filmId={filmId} setDressingId={itemId} visualEffectId={itemId} embedded={true} />}
        {(selectedItem?.item === VisualEffect) && (selectScenes == VisualEffect) && <VisualEffectScenes filmId={filmId} visualEffectId={visualEffectId} sceneId={sceneId} embedded={true} />}

        {(selectedItem?.item === "Edit" + Scene && !selectScenes) && <EditScene filmId={filmId} sceneId={itemId} embedded={true} />}
      </div>

      <Onboarding onboardingSteps={scriptOnboardingSteps} section="Script" />

    </>
  )
}


function mapStateToProps(state) {
  const scriptState = state.scriptState.rootState;
  return {
    scenes: scriptState.scenes,
    script: scriptState.script,
    editedScript: scriptState.editedScript,
    readOnly: scriptState.readOnly,
    characterNames: scriptState.characterNames,
    propNames: scriptState.propNames,
    backgroundCharacterNames: scriptState.backgroundCharacterNames,
    costumeNames: scriptState.costumeNames,
    makeupNames: scriptState.makeupNames,
    setDressingNames: scriptState.setDressingNames,
    errors: scriptState.errors,
    loading: scriptState.loading,
    selectedItem: scriptState.selectedItem,
    selectScenes: scriptState.selectScenes,
    propId: scriptState.propId,
    setDressingId: scriptState.setDressingId,
    costumeId: scriptState.costumeId,
    makeupId: scriptState.makeupId,
    characterId: scriptState.characterId,
    backgroundCharacterId: scriptState.backgroundCharacterId,
    visualEffectId: scriptState.visualEffectId,
    sceneId: scriptState.sceneId,
    itemId: scriptState.itemId,
    mouseDown: scriptState.mouseDown,
    scriptOnboardingSteps: scriptState.scriptOnboardingSteps,
    reloadScript: scriptState.reloadScript,
    editMode: scriptState.editMode,
    splitMode: scriptState.splitMode,
    movingScene: scriptState.movingScene,
    download: scriptState.download,
    progress: scriptState.progress,
    canUploadNewVersion: scriptState.canUploadNewVersion
  };
}

export default connect(mapStateToProps, {
  fetchScript,
  resetClearForm,
  setSelectedItem,
  editItem,
  clearReloadScript,
  editElement,
  updateScript,
  setEditMode,
  setSplitMode,
  downloadScript,
  pdfDownloaded,
  clearPdf,
  resetPdf,
  showSplitScene,
  splitScriptScene,
  unSplitScriptScene,
  showMoveSplitScene,
  finishMovingSplitScene
})(Script);